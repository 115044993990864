import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IUpdateSpace } from './contracts/update-space';

export class UpdateSpaceUseCase implements IUpdateSpace {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IUpdateSpace.Result>) {}

  async execute(params: IUpdateSpace.Params): Promise<IUpdateSpace.Result | undefined> {
    const { spaceId, name } = params;
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${spaceId}`,
      method: 'put',
      body: {
        name,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError();
      default:
        throw new UnexpectedError();
    }
  }
}
