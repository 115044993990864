import React from 'react';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

import Icon from '@chakra-ui/icon';

type Props = {
  passwordVisible: boolean;
  onClick(): void;
};

const RightIconPasswordInput: React.FC<Props> = ({ onClick, passwordVisible }: Props) => {
  return (
    <Icon
      as={!passwordVisible ? IoIosEye : IoIosEyeOff}
      w={6}
      h={6}
      color="whiteAlpha.900"
      onClick={onClick}
      cursor="pointer"
    />
  );
};

export { RightIconPasswordInput };
