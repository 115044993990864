import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ISendInvite } from './contracts/send-invite';

export class SendInviteUseCase implements ISendInvite {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ISendInvite.Result>) {}

  async execute(params: ISendInvite.Params): Promise<ISendInvite.Result | undefined> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.ok:
        return;
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError();
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
