import { ValidationBuilder as Builder } from '@/shared/builders/validation-builder';
import { ValidationComposite } from '@/shared/validation/composites/validation';

export const makeCreateTaskValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field('name').required().build(),
    ...Builder.field('samplingTypeId').required().build(),
    ...Builder.field('expirationDate').required().afterToday().build(),
    ...Builder.field('labelPoint').required().build(),
    ...Builder.field('users').required().arrayNotEmpty().build(),
    ...Builder.field('depths').required().arrayNotEmpty().build(),
  ]);
