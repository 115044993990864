import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TagBox } from 'devextreme-react';
import DataGrid, {
  Column,
  Paging,
  Selection,
  SearchPanel,
  Pager,
  HeaderFilter,
  FilterRow,
  FilterPanel,
} from 'devextreme-react/data-grid';

import { RootState } from '@/main/config/redux';
import { Arcgis } from '@/shared/adapters/map-visualization/contracts/arcgis';

type TypeAnalysis = {
  id: string;
  [key: string]: string | string[];
};

type Props = {
  label: string;
  depths: string[];
  arcgisAdapter: Arcgis;
  onChageTypeAnalysis: (typeAnalysis: TypeAnalysis[]) => void;
};

const ITEMS = [
  {
    label: 'ROTINA',
  },
  {
    label: 'MICRO',
  },
  {
    label: 'GRANULOMETRIA',
  },
];

type Cell = {
  key: string;
  column: {
    dataField: string;
  };
  displayValue: string[];
};

const TypeAnalysisTable: React.FC<Props> = ({ depths, label, arcgisAdapter, onChageTypeAnalysis }: Props) => {
  const { point } = useSelector((state: RootState) => state.mapView);

  const [typeAnalysis, setAnalysisType] = useState<TypeAnalysis[]>([]);

  const onValueChanged = useCallback((newValue: string[], key: string, column: string) => {
    setAnalysisType((prevState) =>
      prevState?.map((prev) => (prev.id === key ? { ...prev, [column]: newValue } : prev)),
    );
  }, []);

  const cellRender = useCallback(
    (cell: Cell) => {
      const {
        key,
        column: { dataField },
      } = cell;

      return (
        <TagBox
          key={key + dataField}
          multiline={false}
          width={200}
          valueExpr="label"
          displayExpr="label"
          placeholder=""
          defaultValue={cell.displayValue}
          items={ITEMS}
          onValueChanged={(e) => onValueChanged(e.value, key, dataField)}
        />
      );
    },
    [onValueChanged],
  );

  useEffect(() => {
    const initialState: TypeAnalysis[] = [];

    point?.features.forEach((feature) => {
      if (feature.properties) {
        const data: TypeAnalysis = {
          id: String(feature.id),
          [label]: String(feature.properties[label]),
        };

        depths.forEach((depth) => {
          data[depth] = ['ROTINA'];
        });

        initialState.push(data);
      }
    });

    setAnalysisType(initialState);
  }, [depths, label, point]);

  useEffect(() => {
    if (typeAnalysis?.length > 0) {
      onChageTypeAnalysis(typeAnalysis);
    }
  }, [onChageTypeAnalysis, typeAnalysis]);

  return (
    <DataGrid
      dataSource={typeAnalysis}
      showBorders={true}
      width={600}
      keyExpr={'id'}
      showRowLines
      showColumnLines
      columnAutoWidth
      hoverStateEnabled={true}
      onSelectionChanged={(e) => {
        //@ts-ignore
        arcgisAdapter.highlightFeatureByAttribute('PONTO', e.selectedRowsData[0]['PONTO']);
      }}
    >
      <SearchPanel visible={true} width={300} placeholder="Pesquisar..." />
      <Selection mode="single" />
      <Paging defaultPageSize={5} />
      <Pager
        visible={true}
        allowedPageSizes={[5, 10, 20, 'all']}
        displayMode="full"
        showPageSizeSelector
        showInfo
        showNavigationButtons
      />
      <HeaderFilter visible />
      <Column dataField={label} dataType="string">
        <HeaderFilter visible />
      </Column>
      {depths.map((depth) => (
        <Column key={depth} dataField={depth} dataType="string" cellRender={cellRender}>
          <HeaderFilter visible={false} />
        </Column>
      ))}
    </DataGrid>
  );
};

export { TypeAnalysisTable };
