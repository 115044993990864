import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { SelectBox } from '../atoms/select-box';
import { GroupContainer } from './group-container';

type Props = {
  onChange: (value: string) => void;
  textError?: string;
};

type Item = {
  id: string;
  label: string;
};

const PointLabel: React.FC<Props> = ({ onChange, textError }: Props) => {
  const { point } = useSelector((state: RootState) => state.mapView);

  const items = useMemo(() => {
    const arrItems: Item[] = [];

    if (point) {
      const features = point?.features;

      if (features.length > 0) {
        //@ts-ignore
        const keys = Object.keys(features[0].properties);

        return keys.map((key) => ({
          id: key,
          label: key,
        }));
      }
    }
    return arrItems;
  }, [point]);

  return (
    <Flex flexDir="column" mb={1} alignItems="flex-start">
      <FormLabel>Label do ponto</FormLabel>
      <SelectBox onValueChanged={onChange} items={items} />
      <TextError>{textError}</TextError>
    </Flex>
  );
};

export { PointLabel };
