import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/react';

import TagBox from 'devextreme-react/tag-box';

import { RootState } from '@/main/config/redux';
import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { makeLoadUserPermissionUseCase } from '@/modules/project/factories/make-load-user-permission-use-case';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { GroupContainer } from './group-container';

type Props = {
  onChange: (userIds: string[]) => void;
  textError?: string;
};

type ItemProps = { id: string; label: string };

const UserList: React.FC<Props> = ({ onChange, textError }: Props) => {
  const {
    task: { createTaskIsOpenModal },
  } = useSelector((state: RootState) => state);

  const loadUserPermissionUseCase = useMemo(() => makeLoadUserPermissionUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [items, setItems] = useState<ItemProps[]>([]);
  const [currentProject, setCurrentProject] = useState<ManagementTreeModel | null>();

  const fetchUsers = useCallback(
    async (projectId: string) => {
      const data = await loadUserPermissionUseCase.execute({ projectId });
      if (data && data.length) {
        const users = data.filter(
          (user) => user.permissions.some((permission) => permission.name === 'Coletar') === true,
        );
        setItems(users.map((user) => ({ id: user.id, label: user.name })));
      } else {
        setItems([]);
      }
    },
    [loadUserPermissionUseCase],
  );

  useEffect(() => {
    if (createTaskIsOpenModal) {
      const currentNode = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;
      setCurrentProject(currentNode);
    }
  }, [createTaskIsOpenModal, localStorageAdapter]);

  useEffect(() => {
    if (currentProject?.nodeType === 'project') {
      fetchUsers(currentProject.id);
    }
  }, [fetchUsers, currentProject]);

  return (
    <GroupContainer>
      <Flex flexDir="column" mb={1} alignItems="flex-start">
        <FormLabel>Colaboradores</FormLabel>
        <TagBox
          width={300}
          multiline={false}
          valueExpr="id"
          displayExpr="label"
          placeholder=""
          items={items}
          onValueChanged={(e) => onChange(e.value)}
          showSelectionControls={true}
          selectAllMode="allPages"
          selectAllText="Selecionar tudo"
        />
        <TextError>{textError}</TextError>
      </Flex>
    </GroupContainer>
  );
};

export { UserList };
