import React, { KeyboardEventHandler } from 'react';

import { FormControl } from '@/shared/presentation/components/atoms/form-control';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { InputText } from '@/shared/presentation/components/atoms/input-text';
import { TextError } from '@/shared/presentation/components/atoms/text-error';
import { InputPassword } from '@/shared/presentation/components/molecules/input-password';

type Props = {
  id: string;
  label: string;
  type: 'text' | 'password';
  textError?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  isDisabled?: boolean;
  onKeyPress?: KeyboardEventHandler | undefined;
};

const InputForm: React.FC<Props> = ({
  id,
  label,
  type,
  textError = '',
  onChange,
  defaultValue = '',
  isDisabled = false,
  onKeyPress,
}: Props) => {
  return (
    <FormControl id={id} isInvalid={!!textError}>
      <FormLabel>{label}</FormLabel>
      {type == 'text' ? (
        <InputText onChange={onChange} defaultValue={defaultValue} isDisabled={isDisabled} onKeyPress={onKeyPress} />
      ) : (
        <InputPassword
          onChange={onChange}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          onKeyPress={onKeyPress}
        />
      )}
      <TextError>{textError}</TextError>
    </FormControl>
  );
};

export { InputForm };
