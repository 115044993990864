import { SpaceModel } from '../models/space';

export enum ActionTypes {
  SET_SPACES = '@space/SET_SPACES',
  SET_CURRENT_SPACE = '@space/SET_CURRENT_SPACE',

  ADD_SPACE_OPEN_MODAL = '@space/ADD_SPACE_OPEN_MODAL',
  ADD_SPACE_CLOSE_MODAL = '@space/ADD_SPACE_CLOSE_MODAL',

  ADD_NEW_SPACE = '@space/ADD_NEW_SPACE',

  REMOVE_SPACE_OPEN_DIALOG = '@space/REMOVE_SPACE_OPEN_DIALOG',
  REMOVE_SPACE_CLOSE_DIALOG = '@space/REMOVE_SPACE_CLOSE_DIALOG',

  UPDATE_SPACE_OPEN_MODAL = '@space/UPDATE_SPACE_OPEN_MODAL',
  UPDATE_SPACE_CLOSE_MODAL = '@space/UPDATE_SPACE_CLOSE_MODAL',
}

export type SpaceState = {
  spaces: SpaceModel[];
  currentSpace: SpaceModel;

  removeSpaceIsOpenDialog: boolean;
  updateSpaceIsOpenModal: boolean;
  addSpaceIsOpenModal: boolean;
};
