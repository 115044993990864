import React from 'react';
import { MdEdit } from 'react-icons/md';

import { Button, Icon } from '@chakra-ui/react';

type Props = {
  onClick: () => void;
};

const EditButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Button
      variant="solid"
      size="sm"
      bgColor="blue.500"
      _hover={{
        bgColor: 'blue.700',
      }}
      onClick={onClick}
    >
      <Icon as={MdEdit} color="whiteAlpha.900" w="18px" h="18px" />
    </Button>
  );
};

export { EditButton };
