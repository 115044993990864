import React from 'react';
import { MdDelete } from 'react-icons/md';

import { Button, Icon } from '@chakra-ui/react';

type Props = {
  onClick: () => void;
};

const DeleteButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Button
      variant="solid"
      size="sm"
      bgColor="red.500"
      _hover={{
        bgColor: 'red.700',
      }}
      ml={2}
      onClick={onClick}
    >
      <Icon as={MdDelete} color="whiteAlpha.900" w="18px" h="18px" />
    </Button>
  );
};

export { DeleteButton };
