import { FieldValidation } from '../validation/contracts/field-validation';
import { AfterTodayValidation } from '../validation/validators/after-today';
import { ArrayNotEmptyValidation } from '../validation/validators/array-not-empy';
import { CompareFieldsValidation } from '../validation/validators/compare-fields';
import { RequiredFieldValidation } from '../validation/validators/required-field';

export class ValidationBuilder {
  private constructor(private readonly fieldName: string, private readonly validations: FieldValidation[]) {}

  static field(fieldName: string): ValidationBuilder {
    return new ValidationBuilder(fieldName, []);
  }

  required(): ValidationBuilder {
    this.validations.push(new RequiredFieldValidation(this.fieldName));
    return this;
  }

  sameAs(fieldToCompare: string): ValidationBuilder {
    this.validations.push(new CompareFieldsValidation(this.fieldName, fieldToCompare));
    return this;
  }

  build(): FieldValidation[] {
    return this.validations;
  }

  afterToday(): ValidationBuilder {
    this.validations.push(new AfterTodayValidation(this.fieldName));
    return this;
  }

  arrayNotEmpty(): ValidationBuilder {
    this.validations.push(new ArrayNotEmptyValidation(this.fieldName));
    return this;
  }
}
