import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IEditMotorcycle } from './contracts/edit-motorcycle';

export class EditMotorcycleUseCase implements IEditMotorcycle {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IEditMotorcycle.Result>) {}

  async execute(params: IEditMotorcycle.Params): Promise<IEditMotorcycle.Result | undefined> {
    const { id, name } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body: { name },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse?.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
