import { useEffect, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { Arcgis } from '@/shared/adapters/map-visualization/contracts/arcgis';

type Props = {
  arcgisAdapter: Arcgis;
};

const GeojsonPointLayer: React.FC<Props> = ({ arcgisAdapter }: Props) => {
  const { point } = useSelector((state: RootState) => state.mapView);

  const loadModulesArcgis = useCallback(
    async (feature) => {
      const popupTemplate = arcgisAdapter.makePopupTemplate(Object.keys(feature.features[0].properties));

      const renderer = {
        type: 'unique-value',
        field: 'status',
        defaultSymbol: { type: 'simple-fill' },
        uniqueValueInfos: [
          {
            value: 'RELEASED',
            symbol: {
              type: 'simple-marker',
              color: 'white',
              outline: {
                color: 'white',
              },
            },
          },
          {
            value: 'SAMPLED',
            symbol: {
              type: 'simple-marker',
              color: 'yellow',
              outline: {
                color: 'white',
              },
            },
          },
          {
            value: 'REFUSED',
            symbol: {
              type: 'simple-marker',
              color: 'red',
              outline: {
                color: 'white',
              },
            },
          },
        ],
      } as __esri.RendererProperties;

      await arcgisAdapter.displayGeoJSONOnMap({
        geojson: feature,
        id: 'point',
        indexLayer: 1,
        renderer,
        popupTemplate,
      });
    },
    [arcgisAdapter],
  );

  useEffect(() => {
    if (point) {
      loadModulesArcgis(point);
    }
  }, [loadModulesArcgis, point]);

  return null;
};

const Component = memo(GeojsonPointLayer);
export { Component as GeojsonPointLayer };
