import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScaleFade, TabPanel, Box, useToast } from '@chakra-ui/react';
import { Tabs, TabPanels } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { makeCreateTaskUseCase } from '@/modules/task/factories/make-create-task-use-case';
import { createTaskCloseModal } from '@/modules/task/state/actions';
import { Arcgis } from '@/shared/adapters/map-visualization/contracts/arcgis';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { Header } from '../molecules/header';
import { Tab0 } from './tab-0';
import { Tab1 } from './tab-1';

type Props = {
  arcgisAdapter: Arcgis;
};

type TaskProps = {
  name: string;
  samplingTypeId: string;
  requestEquipment: boolean;
  requestTruck: boolean;
  requestMotorcycle: boolean;
  maxDistance: number;
  labelPoint: string;
  labelPolygon: string;
  priority: string;
  expirationDate: Date;
  users: string[];
};

const INITIAL_STATE = {
  expirationDate: new Date(),
  labelPoint: '',
  labelPolygon: '',
  maxDistance: 10,
  name: '',
  priority: '',
  requestEquipment: true,
  requestMotorcycle: true,
  requestTruck: true,
  samplingTypeId: '',
  users: [],
};

const CreateTask: React.FC<Props> = ({ arcgisAdapter }: Props) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const {
    task: { createTaskIsOpenModal },
    mapView: { point, polygon },
  } = useSelector((state: RootState) => state);

  const createTaskUseCase = useMemo(() => makeCreateTaskUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [task, setTask] = useState<TaskProps>(INITIAL_STATE);
  const [depths, setDepths] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (typeAnalysis) => {
      setSaveLoading(true);
      const currentProject = localStorageAdapter.getItem('currentProject');
      try {
        await createTaskUseCase.execute({
          pointId: point?.id || '',
          polygonId: polygon?.id || '',
          projectId: currentProject.id,
          task,
          depths,
          typeAnalysis,
        });
        dispatch(createTaskCloseModal());
        toast({
          title: 'A tarefa foi adicionada em uma fila e logo sera liberada para seus colaboradores',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error: any) {
        toast({
          title: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setSaveLoading(false);
      }
    },
    [task, depths, createTaskUseCase, polygon, point, localStorageAdapter, toast, dispatch],
  );

  useEffect(() => {
    if (createTaskIsOpenModal) {
      setTask(INITIAL_STATE);
      setTabIndex(0);
    }
  }, [createTaskIsOpenModal]);

  useEffect(() => {
    if (task.labelPoint) {
      const labelClass = {
        symbol: {
          type: 'text',
          color: 'white',
          haloColor: 'blue',
          haloSize: 1,
          font: {
            family: 'Ubuntu Mono',
            size: 14,
            weight: 'bold',
          },
        },
        labelPlacement: 'above-right',
        labelExpressionInfo: {
          expression: `$feature.${task.labelPoint}`,
        },
      } as __esri.LabelClassProperties;

      arcgisAdapter.addLabelClass({ layerId: 'point', ...labelClass });
    }
  }, [task.labelPoint, arcgisAdapter]);

  return createTaskIsOpenModal ? (
    <ScaleFade initialScale={0.9} in={createTaskIsOpenModal}>
      <Box
        position="absolute"
        borderRadius="md"
        bg="gray.800"
        top={0}
        right={0}
        mt={55}
        mr={4}
        pt={2}
        alignItems="flex-start"
        flexDir="column"
        maxH="85%"
        overflowX="auto"
        __css={{
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '8px',
          },
        }}
      >
        <Header />
        <Tabs index={tabIndex}>
          <TabPanels>
            <TabPanel>
              <Tab0
                onChangeTab={(index) => setTabIndex(index)}
                onChage={(formData, depths) => {
                  setTask((prevState) => ({ ...prevState, ...formData }));
                  setDepths(depths);
                }}
              />
            </TabPanel>

            <TabPanel>
              <Tab1
                arcgisAdapter={arcgisAdapter}
                depths={depths}
                label={task.labelPoint}
                onChangeTab={(index) => setTabIndex(index)}
                onSubmit={onSubmit}
                loading={saveLoading}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ScaleFade>
  ) : null;
};

export { CreateTask };
