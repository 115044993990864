import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { makeCreateSpaceUseCase } from '@/modules/space/factories/make-create-space-use-case';
import { addNewSpace, addSpaceCloseModal } from '@/modules/space/state/actions';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type InputProps = {
  name: string;
};

const CreateSpaceModal: React.FC = () => {
  const dispatch = useDispatch();

  const { addSpaceIsOpenModal } = useSelector((state: RootState) => state.space);

  const [formData, setFormData] = useState<InputProps>({ name: '' });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const createSpaceUseCase = makeCreateSpaceUseCase();
      const newSpace = await createSpaceUseCase.execute(formData);
      if (newSpace) {
        dispatch(addNewSpace(newSpace));
      }
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setFormData({ name: '' });
      setIsLoading(false);
    }
  }, [formData, dispatch]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(addSpaceCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (addSpaceIsOpenModal) {
      setFormData({ name: '' });
      setIsLoading(false);
      setTextError('');
    }
  }, [addSpaceIsOpenModal]);

  return (
    <Modal
      title="Cadastro de novo espaço"
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addSpaceIsOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-customer"
        label="Novo espaço"
        onChange={(e) => setFormData({ name: e.target.value })}
        textError={textError}
        type="text"
      />
    </Modal>
  );
};

export { CreateSpaceModal };
