import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IGetSamplingTypes } from './contracts/get-sampling-types';

export class GetSamplingTypesUseCase implements IGetSamplingTypes {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IGetSamplingTypes.Result>) {}

  async execute(): Promise<IGetSamplingTypes.Result | undefined> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
