import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { Container } from '@/modules/equipment-vehicle/presentantion/components/atoms/container';
import { ContenteSpinner } from '@/modules/equipment-vehicle/presentantion/components/atoms/content-spinner';
import { DividerHeader } from '@/modules/equipment-vehicle/presentantion/components/atoms/divider-header';
import { WrapperTable } from '@/modules/equipment-vehicle/presentantion/components/atoms/wrapper-table';
import { EmptyContentText } from '@/modules/equipment-vehicle/presentantion/components/molecules/empty-content-text';
import { HeaderTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/header-table';
import { RowTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/row-table';
import { makeDeleteMotorcycleUseCase } from '@/modules/motorcycle/factories/make-delete-motorcycle-use-case';
import { makeLoadMotorcyclesUseCase } from '@/modules/motorcycle/factories/make-load-motorcycles-use-case';
import { MotorcycleModel } from '@/modules/motorcycle/models/motorcycle';
import { addEditMotorcycleOpenModal, removeMotorcycle, setMotorcycles } from '@/modules/motorcycle/state/actions';
import { Permission } from '@/modules/space/models/space-permission';
import { AlertDialog } from '@/shared/presentation/components/atoms/alert-dialog';
import { Can } from '@/shared/presentation/components/atoms/can';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

import { CreateEditMotorcycleModal } from '../molecules/create-edit-motorcycle-modal';

const Motorcycle: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    space: { currentSpace },
    motorcycle: { motorcycles },
  } = useSelector((state: RootState) => state);

  const loadMotorcyclesUseCase = useMemo(() => makeLoadMotorcyclesUseCase(), []);
  const deleteMotorcycletUseCase = useMemo(() => makeDeleteMotorcycleUseCase(), []);

  const [currentMotorcycle, setCurrentMotorcycle] = useState<MotorcycleModel>({
    id: '',
    name: '',
    slug: '',
    spaceId: '',
  });
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const fetchMotorcycles = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await loadMotorcyclesUseCase.execute();

      if (data && data.length) {
        dispatch(setMotorcycles(data));
      } else {
        dispatch(setMotorcycles([]));
      }
    } catch (error) {
      toast({
        title: 'Falha ao carregar quadriciclos',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [loadMotorcyclesUseCase, toast, dispatch]);

  const onClickAdd = useCallback(() => {
    setCurrentMotorcycle({
      id: '',
      name: '',
      slug: '',
      spaceId: '',
    });
    dispatch(addEditMotorcycleOpenModal());
  }, [dispatch]);

  const onConfirmDelete = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await deleteMotorcycletUseCase.execute({ id: currentMotorcycle.id });
      dispatch(removeMotorcycle(currentMotorcycle.id));
      setAlertDialogIsOpen(false);
    } catch (error) {
      toast({
        title: 'Falha ao remover veiculo',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }, [deleteMotorcycletUseCase, dispatch, currentMotorcycle.id, toast]);

  const onClickDelete = useCallback((motorcycle: MotorcycleModel) => {
    setCurrentMotorcycle(motorcycle);
    setAlertDialogIsOpen(true);
  }, []);

  const onClickEdit = useCallback(
    (motorcycle: MotorcycleModel) => {
      setCurrentMotorcycle(motorcycle);
      dispatch(addEditMotorcycleOpenModal());
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentSpace?.id) {
      fetchMotorcycles();
    }
  }, [fetchMotorcycles, currentSpace.id]);

  return (
    <Container>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <CreateEditMotorcycleModal currentMotorcycle={currentMotorcycle} />
        <AlertDialog
          cancelButtonText="Não"
          confirmButtonText="Sim"
          header="Atenção"
          message="Deseja realmente excluir este veiculo?"
          isLoading={isLoadingDelete}
          isOpen={alertDialogIsOpen}
          onCancel={() => {
            setAlertDialogIsOpen(false);
          }}
          onConfirm={onConfirmDelete}
        />
      </Can>
      <HeaderTable title="Quadriciclos" onClickAdd={onClickAdd} />
      <DividerHeader />
      <WrapperTable>
        {isLoading && (
          <ContenteSpinner>
            <Spinner />
          </ContenteSpinner>
        )}

        {!isLoading && motorcycles.length > 0
          ? motorcycles.map((motorcycle) => (
              <RowTable
                key={motorcycle.id}
                text={motorcycle.name}
                onClickDelete={() => onClickDelete(motorcycle)}
                onClickEdit={() => onClickEdit(motorcycle)}
              />
            ))
          : null}

        {!isLoading && motorcycles.length === 0 ? (
          <EmptyContentText text="Não existe quadriciclos cadastrados" />
        ) : null}
      </WrapperTable>
    </Container>
  );
};

export { Motorcycle };
