import React from 'react';

import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

import { FormLabel } from '@/shared/presentation/components/atoms/form-label';

type Props = {
  onChange: (value: number) => void;
  defaultValue: number;
};

const DepthInput: React.FC<Props> = ({ onChange, defaultValue }: Props) => {
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <FormLabel>Profundidades</FormLabel>
      <NumberInput
        defaultValue={defaultValue}
        min={1}
        focusBorderColor="green.500"
        color="whiteAlpha.900"
        w={90}
        onChange={(_: string, valueAsNumber: number) => onChange(valueAsNumber)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
};

export { DepthInput };
