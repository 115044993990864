import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ICheckPasswordResetIsValid } from './contracts/check-password-reset-is-valid';

export class CheckPasswordResetIsValidUseCase implements ICheckPasswordResetIsValid {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ICheckPasswordResetIsValid.Result>,
  ) {}

  async execute(params: ICheckPasswordResetIsValid.Params): Promise<ICheckPasswordResetIsValid.Result | undefined> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.resetPasswordId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
