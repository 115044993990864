import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

type Props = {
  text: string;
};

const EmptyContentText: React.FC<Props> = ({ text }: Props) => {
  return (
    <Flex w="100%" alignItems="center" justifyContent="center">
      <Text color="whiteAlpha.900" fontSize={18}>
        {text}
      </Text>
    </Flex>
  );
};

export { EmptyContentText };
