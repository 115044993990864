import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { useToast } from '@chakra-ui/react';
import { Flex, Stack } from '@chakra-ui/react';

import { ICheckPasswordResetIsValid } from '@/modules/reset-password/use-cases/contracts/check-password-reset-is-valid';
import { IResetPassword } from '@/modules/reset-password/use-cases/contracts/reset-password';
import { FormHeading } from '@/shared/presentation/components/atoms/form-heading';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';
import { Validation } from '@/shared/validation/contracts/validation';

import { Footer } from '../molecules/footer';

type Props = {
  checkPasswordResetUseCase: ICheckPasswordResetIsValid;
  resetPasswordUseCase: IResetPassword;
  validation: Validation;
};

type ParamsProps = {
  resetPasswordId: string;
};

type Inputs = {
  password: string;
  passwordConfirmation: string;
};

type Validate = {
  passwordValidate: string;
  passwordConfirmationValidate: string;
  isFormInvalid: boolean;
};

export const ResetPassword: React.FC<Props> = ({
  checkPasswordResetUseCase,
  resetPasswordUseCase,
  validation,
}: Props) => {
  const history = useHistory();
  const toast = useToast();

  const { resetPasswordId } = useParams<ParamsProps>();
  const [userId, setUserId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mainError, setMainError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordConfirmationError, setpasswordConfirmationError] = useState<string>('');

  const [formData, setFormData] = useState<Inputs>({
    password: '',
    passwordConfirmation: '',
  });

  const validateForm = useCallback((): Validate => {
    const passwordValidate = validation.validate('password', formData);
    const passwordConfirmationValidate = validation.validate('passwordConfirmation', formData);

    let isFormInvalid = true;

    if (!passwordValidate && !passwordConfirmationValidate) {
      isFormInvalid = false;
    }

    return {
      isFormInvalid,
      passwordValidate,
      passwordConfirmationValidate,
    };
  }, [formData, validation]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const { isFormInvalid, passwordValidate, passwordConfirmationValidate } = validateForm();

    try {
      if (isLoading || isFormInvalid) {
        setPasswordError(passwordValidate);
        setpasswordConfirmationError(passwordConfirmationValidate);
        return;
      }
      setIsLoading(true);
      await resetPasswordUseCase.execute({
        resetPasswordId,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
        userId,
      });

      toast({
        title: 'Senha redefinida com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.replace('/sign-in');
    } catch (error: any) {
      setMainError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [resetPasswordId, resetPasswordUseCase, userId, history, isLoading, formData, validateForm, toast]);

  useEffect(() => {
    async function checkPasswordResetUseCaseIsValid() {
      try {
        const response = await checkPasswordResetUseCase.execute({
          resetPasswordId,
        });

        if (response) {
          setUserId(response.userId);
        }
      } catch (error: any) {
        history.replace('/sign-in');
      }
    }
    checkPasswordResetUseCaseIsValid();
  }, [checkPasswordResetUseCase, resetPasswordId, history]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} backgroundColor="gray.900" m={0} p={0} flexDir="column">
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        backgroundColor="gray.800"
        borderRadius="8px"
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
        flexDir="column"
      >
        <FormHeading fontSize="xl">Preencha sua nova senha</FormHeading>

        <InputForm
          textError={passwordError}
          id="password"
          label="Nova senha"
          type="password"
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              password: e.target.value,
            }))
          }
        />

        <InputForm
          textError={passwordConfirmationError}
          id="passwordConfirmation"
          label="Repita sua nova senha"
          type="password"
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              passwordConfirmation: e.target.value,
            }))
          }
        />

        <Footer isLoading={isLoading} mainError={mainError} handleSubmit={handleSubmit} />
      </Stack>
    </Flex>
  );
};
