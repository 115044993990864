import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

const WrapperTable: React.FC<BoxProps> = ({ children }: BoxProps) => {
  return (
    <Box
      w="100%"
      overflowX="auto"
      __css={{
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '8px',
        },
      }}
    >
      {children}
    </Box>
  );
};

export { WrapperTable };
