import React, { useCallback, useEffect, useState } from 'react';

import { InputForm } from '@/shared/presentation/components/molecules/input-form';

import { GroupContainer } from './group-container';
import { InputExpireDate } from './input-expire-date';
import { Priority } from './priority';
import { SamplingType } from './sampling-type';

type Error = {
  [key: string]: string;
};

type Props = {
  onChange: (key: string, value: any) => void;
  error: Error;
};

const DefinitionTask: React.FC<Props> = ({ onChange, error }: Props) => {
  return (
    <GroupContainer title="Definições da tarefa">
      <InputForm
        id="name"
        type="text"
        label="Nome da tarefa"
        onChange={(e) => onChange('name', e.target.value)}
        textError={error['name']}
      />
      <Priority onValueChanged={(e) => onChange('priority', e)} />
      <InputExpireDate onValueChange={(date) => onChange('expirationDate', date)} textError={error['expirationDate']} />
      <SamplingType onChange={(e) => onChange('samplingTypeId', e)} textError={error['samplingTypeId']} />
    </GroupContainer>
  );
};

export { DefinitionTask };
