import produce from 'immer';
import { Reducer } from 'redux';

import { EquipmentState, ActionTypes } from './types';

const INITIAL_STATE: EquipmentState = {
  equipments: [],

  addEditEquipmentIsOpenModal: false,
};

const equipmentVehicle: Reducer<EquipmentState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_EQUIPMENTS: {
        draft.equipments = action.payload.equipments;
        break;
      }
      case ActionTypes.ADD_EDIT_EQUIPMENT_OPEN_MODAL: {
        draft.addEditEquipmentIsOpenModal = true;
        break;
      }
      case ActionTypes.ADD_EDIT_EQUIPMENT_CLOSE_MODAL: {
        draft.addEditEquipmentIsOpenModal = false;
        break;
      }
      case ActionTypes.SET_NEW_EQUIPMENT: {
        draft.equipments.push(action.payload.equipment);
        break;
      }
      case ActionTypes.REMOVE_EQUIPMENT: {
        draft.equipments = draft.equipments.filter((equipment) => equipment.id !== action.payload.id);
        break;
      }
      case ActionTypes.UPDATE_EQUIPMENT: {
        draft.equipments = draft.equipments.map((equipment) =>
          equipment.id === action.payload.equipment.id ? { ...action.payload.equipment } : equipment,
        );
        break;
      }
      default:
    }
  });
};

export default equipmentVehicle;
