import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/layout';

import { LeftBar } from '../organisms/left-bar';
import { NavBar } from '../organisms/nav-bar';

type Props = FlexProps;

const Main: React.FC<Props> = ({ children }: Props) => {
  return (
    <Flex flex="1" minH="100vh" flexDir="column" m={0} p={0} backgroundColor="gray.900">
      <NavBar />

      <Flex flex="1" flexDir="row">
        <LeftBar />

        <Flex h="100%" w="100%">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Main };
