import './css/tree-view.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from '@chakra-ui/react';

import DXTreeView from 'devextreme-react/tree-view';

import { RootState } from '@/main/config/redux';
import { makeLoadNodesUseCase } from '@/modules/management-tree/factories/make-load-nodes-use-case';
import { addNode, removeNode, updateNode } from '@/modules/management-tree/state/actions';
import { makeLoadProjectsUseCase } from '@/modules/project/factories/make-load-projects-use-case';
import { ModuleModel } from '@/modules/project/models/module';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

import { TreeViewData, TreeViewItem } from '../../types/tree-view-item';
import { NodeType } from '../molecules/node-types';

const TreeView: React.FC = () => {
  const dispatch = useDispatch();
  const treeViewRef = useRef<DXTreeView>(null);

  const {
    space: { currentSpace },
    managementTree: { addedNode, removedNode, updatedNode },
    task: { createTaskIsOpenModal },
  } = useSelector((state: RootState) => state);

  const loadProjectsUseCase = useMemo(() => makeLoadProjectsUseCase(), []);
  const loadNodesUseCase = useMemo(() => makeLoadNodesUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchProjectParents = useCallback(
    async (projectId: string, modules: ModuleModel[]) => {
      const response = await loadNodesUseCase.execute({
        projectId,
      });

      if (response && response.length > 0) {
        return response.map((el) => ({
          ...el,
          parentId: projectId,
          projectId,
          modules,
          expanded: false,
        }));
      } else {
        return [];
      }
    },
    [loadNodesUseCase],
  );

  const fetchParents = useCallback(
    async (parentId: string, projectId: string, modules: ModuleModel[]) => {
      const response = await loadNodesUseCase.execute({
        parentId,
      });

      if (response && response.length > 0) {
        return response.map((el) => ({
          ...el,
          parentId,
          projectId,
          modules,
          expanded: false,
        }));
      } else {
        return [];
      }
    },
    [loadNodesUseCase],
  );

  const createChildren = useCallback(
    async (e: TreeViewItem) => {
      try {
        const { itemData } = e;

        if (itemData.nodeType == 'project') {
          return await fetchProjectParents(itemData.id, itemData.modules);
        } else {
          return await fetchParents(itemData.id, itemData.projectId, itemData.modules);
        }
      } catch (error) {
        return [];
      }
    },
    [fetchProjectParents, fetchParents],
  );

  const unselectAll = useCallback(() => {
    treeViewRef?.current?.instance.unselectAll();
  }, []);

  const saveCurrentNode = useCallback(
    (e: TreeViewItem) => {
      if (e.itemData.nodeType === 'project') {
        localStorageAdapter.setItem('currentProject', e.itemData);
        localStorageAdapter.setItem('currentNode', e.itemData);
      } else {
        localStorageAdapter.setItem('currentNode', e.itemData);
      }
    },
    [localStorageAdapter],
  );

  const onItemCollapsed = useCallback(
    (e: TreeViewItem) => {
      saveCurrentNode(e);
      unselectAll();
    },
    [saveCurrentNode, unselectAll],
  );
  const onItemClick = useCallback(
    (e: TreeViewItem) => {
      saveCurrentNode(e);
      unselectAll();
    },
    [saveCurrentNode, unselectAll],
  );
  const onItemExpanded = useCallback(
    (e: TreeViewItem) => {
      saveCurrentNode(e);
      unselectAll();
    },
    [saveCurrentNode, unselectAll],
  );

  const onItemContextMenu = useCallback(
    (e: TreeViewItem) => {
      saveCurrentNode(e);
      treeViewRef?.current?.instance.selectItem(e.itemData.id);
    },
    [saveCurrentNode],
  );

  //add node
  useEffect(() => {
    (async () => {
      if (addedNode) {
        const items = treeViewRef?.current?.instance.option('items') as TreeViewData[];
        if (addedNode.nodeType === 'project') {
          treeViewRef?.current?.instance.option('items', [JSON.parse(JSON.stringify(addedNode)), ...items]);
        } else {
          await treeViewRef?.current?.instance.expandItem(addedNode.parentId);
          const findItem = items.find((el) => el.id === addedNode.id);
          if (!findItem)
            treeViewRef?.current?.instance.option('items', [JSON.parse(JSON.stringify(addedNode)), ...items]);
        }
        dispatch(addNode(null));
      }
    })();
  }, [addedNode, treeViewRef, localStorageAdapter, dispatch]);

  //delete node
  useEffect(() => {
    if (removedNode) {
      const items = treeViewRef?.current?.instance.option('items') as TreeViewData[];
      treeViewRef?.current?.instance.option(
        'items',
        items.filter((el) => el.id !== removedNode),
      );

      dispatch(removeNode(null));
    }
  }, [removedNode, treeViewRef, localStorageAdapter, dispatch]);

  //update node
  useEffect(() => {
    if (updatedNode) {
      const items = treeViewRef?.current?.instance.option('items') as TreeViewData[];
      treeViewRef?.current?.instance.option(
        'items',
        items.map((item) =>
          item.id === updatedNode.id
            ? JSON.parse(JSON.stringify({ ...item, name: updatedNode.name, nodeType: updatedNode.nodeType }))
            : JSON.parse(JSON.stringify(item)),
        ),
      );
      treeViewRef?.current?.instance.repaint();
      dispatch(updateNode(null));
    }
  }, [updatedNode, treeViewRef, localStorageAdapter, dispatch]);

  useEffect(() => {
    async function fetchProjects(): Promise<void> {
      setIsLoading(true);
      try {
        const response = await loadProjectsUseCase.execute();
        if (response && response.length > 0) {
          localStorageAdapter.setItem('currentProject', response[0]);
          localStorageAdapter.setItem('currentNode', response[0]);
          const items = response.map((project) => ({
            id: project.id,
            name: project.name,
            modules: project.modules,
            nodeType: project.nodeType,
            parentId: '',
            expanded: false,
            projectId: project.id,
          }));
          treeViewRef?.current?.instance.option('items', JSON.parse(JSON.stringify(items)));
        } else {
          treeViewRef?.current?.instance.option('items', []);
        }
      } catch (err) {
        treeViewRef?.current?.instance.option('items', []);
      } finally {
        setIsLoading(false);
      }
    }

    if (currentSpace.id && treeViewRef?.current) {
      fetchProjects();
    }
  }, [currentSpace, treeViewRef, localStorageAdapter, loadProjectsUseCase]);

  return (
    <Container height="calc(100vh - 350px)">
      {isLoading && <Spinner />}
      <DXTreeView
        id="treeview"
        ref={treeViewRef}
        disabled={createTaskIsOpenModal}
        focusStateEnabled={false}
        dataStructure="plain"
        displayExpr="name"
        noDataText={!isLoading ? 'Você não possui nenhum projeto' : ''}
        rootValue=""
        itemRender={(e) => <NodeType data={e} />}
        createChildren={(e: any) => createChildren(e)}
        onItemCollapsed={(e: any) => onItemCollapsed(e)}
        onItemClick={(e: any) => onItemClick(e)}
        onItemExpanded={(e: any) => onItemExpanded(e)}
        onItemContextMenu={(e: any) => onItemContextMenu(e)}
      />
    </Container>
  );
};

export { TreeView };
