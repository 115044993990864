import { MotorcycleModel } from '../models/motorcycle';

export enum ActionTypes {
  SET_MOTORCYCLES = '@motorcycle/SET_MOTORCYCLES',
  SET_NEW_MOTORCYCLE = '@motorcycle/SET_NEW_MOTORCYCLE',
  REMOVE_MOTORCYCLE = '@motorcycle/REMOVE_MOTORCYCLE',
  UPDATE_MOTORCYCLE = '@motorcycle/UPDATE_MOTORCYCLE',

  ADD_EDIT_MOTORCYCLE_OPEN_MODAL = '@motorcycle/ADD_EDIT_MOTORCYCLE_OPEN_MODAL',
  ADD_EDIT_MOTORCYCLE_CLOSE_MODAL = '@motorcycle/ADD_EDIT_MOTORCYCLE_CLOSE_MODAL',
}

export type MotorcycleState = {
  motorcycles: MotorcycleModel[];

  addEditMotorcycleIsOpenModal: boolean;
};
