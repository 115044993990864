import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IUploadZip } from './contracts/upload-zip';

export class UploadZipUseCase implements IUploadZip {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IUploadZip.Result>) {}

  async execute(params: IUploadZip.Params): Promise<IUploadZip.Result | undefined> {
    const { formData } = params;

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
