import React, { useEffect, useState } from 'react';

import { InputForm } from '@/shared/presentation/components/molecules/input-form';

import { DepthInput } from '../atoms/depth-input';
import { GroupContainer } from './group-container';

type Props = {
  onChange: (depths: string[]) => void;
};

const Depth: React.FC<Props> = ({ onChange }: Props) => {
  const [qtyDepth, setQtyDepth] = useState(1);
  const [depths, setDepths] = useState<string[]>([]);

  useEffect(() => {
    onChange(depths);
  }, [depths, onChange]);

  return (
    <GroupContainer>
      <DepthInput
        onChange={(value) => {
          setQtyDepth(value);
          if (value < depths.length) {
            setDepths((prevState) => [...prevState.splice(-1)]);
          }
        }}
        defaultValue={qtyDepth}
      />

      {Array.from(Array(qtyDepth)).map((_, index) => (
        <InputForm
          key={index}
          id={`depth-${index}`}
          type="text"
          label={`Profundidade ${index + 1}`}
          onChange={(e) => {
            const prevState = depths;
            prevState[index] = e.target.value;
            setDepths(prevState);
          }}
        />
      ))}
    </GroupContainer>
  );
};

export { Depth };
