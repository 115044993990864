import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, VStack } from '@chakra-ui/react';

import TagBox from 'devextreme-react/tag-box';

import { RootState } from '@/main/config/redux';
import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { makeLoadUserPermissionUseCase } from '@/modules/project/factories/make-load-user-permission-use-case';
import { makeUpdateUserPermissionUseCase } from '@/modules/project/factories/make-update-user-permission-use-case';
import { assigmentUsersCloseModal } from '@/modules/project/state/actions';
import { ILoadUserPermission } from '@/modules/project/use-cases/contracts/load-user-permission';
import { IUpdateUserPermission } from '@/modules/project/use-cases/contracts/update-user-permission';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

type UpdatedUserPermission = {
  userId: string;
  permissionIds: string[];
};

type UserPermission = ILoadUserPermission.Result;

const AssigmentUsersModal: React.FC = () => {
  const dispatch = useDispatch();

  const { assigmentUsersIsOpenModal } = useSelector((state: RootState) => state.project);

  const loadUserPermissionUseCase = useMemo(() => makeLoadUserPermissionUseCase(), []);
  const updateUserPermissionUseCase = useMemo(() => makeUpdateUserPermissionUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [updatedUserPermission, setUpdatedUserPermission] = useState<UpdatedUserPermission[]>([]);
  const [userPermission, setUserPermission] = useState<UserPermission>([]);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<ManagementTreeModel | null>();

  const handlePressCancel = useCallback(() => {
    dispatch(assigmentUsersCloseModal());
  }, [dispatch]);

  const fetchUserPermission = useCallback(async () => {
    if (currentProject) {
      setIsLoading(true);
      try {
        const response = await loadUserPermissionUseCase.execute({
          projectId: currentProject.id,
        });

        if (response && response.length > 0) {
          const updateUserPermissionInitialData: UpdatedUserPermission[] = response.map((el) => ({
            userId: el.id,
            permissionIds: el.permissions.map((permission) => permission.id),
          }));

          setUpdatedUserPermission(updateUserPermissionInitialData);
          setUserPermission(response);
        }
      } catch (error) {
        //console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [loadUserPermissionUseCase, currentProject]);

  const onSelectionChanged = useCallback((userId: string, addedItems: UserPermission, removedItems: UserPermission) => {
    if (addedItems.length > 0) {
      const addedItemsIds = addedItems.map((el) => el.id);
      setUpdatedUserPermission((prevState) =>
        prevState.map((prev) =>
          prev.userId === userId
            ? {
                ...prev,
                permissionIds: [...new Set([...prev.permissionIds, ...addedItemsIds])],
              }
            : prev,
        ),
      );
    }
    if (removedItems.length > 0) {
      const removedItemsIds = removedItems.map((el) => el.id);

      setUpdatedUserPermission((prevState) =>
        prevState.map((prev) =>
          prev.userId === userId
            ? {
                ...prev,
                permissionIds: prev.permissionIds.filter((el) => !removedItemsIds.includes(el)),
              }
            : prev,
        ),
      );
    }
  }, []);

  const handlePressSave = useCallback(async () => {
    if (currentProject) {
      setIsLoadingSave(true);

      try {
        await updateUserPermissionUseCase.execute({
          projectId: currentProject.id,
          users: updatedUserPermission,
        });
        handlePressCancel();
      } catch (error) {
        //console.log(error);
      } finally {
        setIsLoadingSave(false);
      }
    }
  }, [updatedUserPermission, updateUserPermissionUseCase, handlePressCancel, currentProject]);

  useEffect(() => {
    if (assigmentUsersIsOpenModal) {
      const currentNode = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;
      setCurrentProject(currentNode);
    }
  }, [assigmentUsersIsOpenModal, localStorageAdapter]);

  useEffect(() => {
    if (currentProject?.nodeType === 'project') {
      fetchUserPermission();
    }
  }, [fetchUserPermission, currentProject]);

  return (
    <Modal
      title={`Alocação de usuários - ${currentProject?.name}`}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={assigmentUsersIsOpenModal}
      isLoading={isLoadingSave}
      size="xl"
    >
      {!isLoading ? (
        <Tabs variant="line" colorScheme="green" isFitted>
          <TabList>
            {currentProject?.modules.map((module) => (
              <Tab key={module.id} color="whiteAlpha.900">
                {module.name}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {currentProject?.modules.map((module) => (
              <TabPanel key={module.id}>
                <VStack spacing="5">
                  {userPermission.map((user) => (
                    <Flex
                      key={module.id + user.id}
                      flexDir="row"
                      alignItems="center"
                      justifyContent="space-between"
                      w="100%"
                    >
                      <Text color="whiteAlpha.900">{user.name}</Text>
                      <TagBox
                        width="60%"
                        multiline={false}
                        valueExpr="id"
                        displayExpr="name"
                        items={module.permissions.map((permission) => ({
                          id: permission.id,
                          name: permission.name,
                        }))}
                        onSelectionChanged={(e) => onSelectionChanged(user.id, e.addedItems, e.removedItems)}
                        showSelectionControls={true}
                        selectAllMode="allPages"
                        selectAllText="Selecionar tudo"
                        defaultValue={user.permissions
                          .filter((permission) => permission.moduleId === module.id)
                          .map((permission) => permission.id)}
                      />
                    </Flex>
                  ))}
                </VStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
    </Modal>
  );
};

export { AssigmentUsersModal };
