import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, useToast, VStack } from '@chakra-ui/react';

import { SelectBox } from 'devextreme-react';

import { RootState } from '@/main/config/redux';
import { makeExportNodeUseCase } from '@/modules/management-tree/factories/make-export-node-use-case';
import { exportNodeCloseModal } from '@/modules/management-tree/state/actions';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { Modal } from '@/shared/presentation/components/atoms/modal';

import { TreeViewData } from '../../types/tree-view-item';

const ITEMS = [
  {
    id: 'shp',
    label: 'Shapefile',
  },
  {
    id: 'geojson',
    label: 'GeoJSON',
  },
  {
    id: 'kml',
    label: 'KML(Google earth)',
  },
  {
    id: 'gpx',
    label: 'GPX',
  },
];

const ExportNodeModal: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { isOpenExportNodeModal } = useSelector((state: RootState) => state.managementTree);

  const exportNodeUseCase = useMemo(() => makeExportNodeUseCase(), []);
  const localStorageAdapater = useMemo(() => makeLocalStorageAdapter(), []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [extension, setExtension] = useState<string>(ITEMS[0].id);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const currentNode = localStorageAdapater.getItem('currentNode') as TreeViewData;

    try {
      const url = await exportNodeUseCase.execute({ extension, managementeTreeId: currentNode.id });
      if (url) {
        window.open(url, '_blank');
      }
    } catch (error) {
      toast({
        title: 'Ocorreu um erro gerar o arquivo',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast, localStorageAdapater, exportNodeUseCase, extension]);

  const handlePressCancel = useCallback(() => {
    dispatch(exportNodeCloseModal());
  }, [dispatch]);

  return (
    <Modal
      title={'Exportar nó'}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={isOpenExportNodeModal}
      isLoading={isLoading}
      saveButtonTitle="Exportar"
    >
      <VStack spacing="5" alignItems="flex-start" w="100%" h="100%">
        <Flex flexDir="column" w="100%">
          <FormLabel>Tipo do arquivo</FormLabel>
          <SelectBox
            items={ITEMS}
            valueExpr="id"
            displayExpr="label"
            stylingMode="filled"
            placeholder="Escolha o tipo do arquivo"
            defaultValue={ITEMS[0].id}
            onValueChanged={(e) => setExtension(e.value)}
          />
        </Flex>
      </VStack>
    </Modal>
  );
};

export { ExportNodeModal };
