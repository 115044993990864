import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IExportNode } from './contracts/export-node';

export class ExportNodeUseCase implements IExportNode {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IExportNode.Result>) {}

  async execute(params: IExportNode.Params): Promise<IExportNode.Result | undefined> {
    const { managementeTreeId, extension } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${managementeTreeId}/export/${extension}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
