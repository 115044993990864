import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

// import { Container } from './styles';

const Container: React.FC<FlexProps> = ({ children }: FlexProps) => {
  return (
    <Flex flex="1" flexDir="column" w="100%" p={'25'}>
      {children}
    </Flex>
  );
};

export { Container };
