import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ICreateTruck } from './contracts/create-truck';

export class CreateTruckUseCase implements ICreateTruck {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ICreateTruck.Result>) {}

  async execute(params: ICreateTruck.Params): Promise<ICreateTruck.Result | undefined> {
    const { name } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: { name },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse?.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
