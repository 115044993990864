import React from 'react';

import { Heading, HeadingProps } from '@chakra-ui/layout';

// import { Container } from './styles';

const FormHeading: React.FC<HeadingProps> = ({ children, ...rest }: HeadingProps) => {
  return (
    <Heading color="whiteAlpha.900" {...rest}>
      {children}
    </Heading>
  );
};

export { FormHeading };
