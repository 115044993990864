import produce from 'immer';
import { Reducer } from 'redux';

import { ManagementTreeState, ActionTypes } from './types';

const INITIAL_STATE: ManagementTreeState = {
  addedNode: null,
  removedNode: null,
  updatedNode: null,

  addOrEditNodeModal: {
    open: false,
    type: 'create',
  },
  isOpenRemoveNodeDialog: false,
  uploadZipIsOpenModal: false,

  isOpenExportNodeModal: false,
};

const managementTree: Reducer<ManagementTreeState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.ADD_NODE: {
        draft.addedNode = action.payload.node;
        break;
      }
      case ActionTypes.REMOVE_NODE: {
        draft.removedNode = action.payload.id;
        break;
      }
      case ActionTypes.UPDATE_NODE: {
        draft.updatedNode = action.payload.node;
        break;
      }
      case ActionTypes.ADD_EDIT_NODE_OPEN_MODAL: {
        draft.addOrEditNodeModal = {
          open: true,
          type: action.payload.type,
        };
        break;
      }
      case ActionTypes.ADD_EDIT_NODE_CLOSE_MODAL: {
        draft.addOrEditNodeModal = {
          open: false,
          type: 'create',
        };
        break;
      }
      case ActionTypes.REMOVE_NODE_OPEN_DIALOG: {
        draft.isOpenRemoveNodeDialog = true;
        break;
      }
      case ActionTypes.REMOVE_NODE_CLOSE_DIALOG: {
        draft.isOpenRemoveNodeDialog = false;
        break;
      }
      case ActionTypes.UPLOAD_ZIP_OPEN_MODAL: {
        draft.uploadZipIsOpenModal = true;
        break;
      }
      case ActionTypes.UPLOAD_ZIP_CLOSE_MODAL: {
        draft.uploadZipIsOpenModal = false;
        break;
      }
      case ActionTypes.EXPORT_NODE_OPEN_MODAL: {
        draft.isOpenExportNodeModal = true;
        break;
      }
      case ActionTypes.EXPORT_NODE_CLOSE_MODAL: {
        draft.isOpenExportNodeModal = false;
        break;
      }
      default:
        break;
    }
  });
};

export default managementTree;
