import React from 'react';

import { makeSignInUseCase } from '@/modules/sign-in/factories/make-sign-in-use-case';

import { SignUp } from '../presentation/components/page/sign-up';
import { makeCreateUserUseCase } from './make-create-user-use-case';
import { makeGetInviteUseCase } from './make-get-invite-use-case';
import { makeSignUpValidation } from './make-sign-up-validation';

export const makeSignUpPage: React.FC = () => {
  return (
    <SignUp
      validation={makeSignUpValidation()}
      createUserUseCase={makeCreateUserUseCase()}
      getInviteUseCase={makeGetInviteUseCase()}
      signInUseCase={makeSignInUseCase()}
    />
  );
};
