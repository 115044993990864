import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { makeCreateEquipmentUseCase } from '@/modules/equipment/factories/make-create-equipment-use-case';
import { makeEditEquipmentUseCase } from '@/modules/equipment/factories/make-edit-equipment-use-case';
import { EquipmentModel } from '@/modules/equipment/models/equipment';
import { addEditEquipmentCloseModal, setNewEquipment, updateEquipment } from '@/modules/equipment/state/actions';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type Props = {
  currentEquipment: EquipmentModel;
};

type InputProps = {
  name: string;
};

const CreateEditEquipmentModal: React.FC<Props> = ({ currentEquipment }: Props) => {
  const dispatch = useDispatch();

  const { addEditEquipmentIsOpenModal } = useSelector((state: RootState) => state.equipment);

  const createEquipmentUseCase = useMemo(() => makeCreateEquipmentUseCase(), []);

  const editEquipmentUseCase = useMemo(() => makeEditEquipmentUseCase(), []);

  const [formData, setFormData] = useState<InputProps>({
    name: '',
  });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveNewEquipment = useCallback(async () => {
    setIsLoading(true);
    try {
      const newEquipment = await createEquipmentUseCase.execute(formData);
      if (newEquipment) {
        dispatch(setNewEquipment(newEquipment));
      }
      dispatch(addEditEquipmentCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [formData, dispatch, createEquipmentUseCase]);

  const saveEditEquipment = useCallback(async () => {
    setIsLoading(true);
    try {
      const editedEquipment = await editEquipmentUseCase.execute({
        ...formData,
        id: currentEquipment.id,
      });
      if (editedEquipment) {
        dispatch(updateEquipment(editedEquipment));
      }
      dispatch(addEditEquipmentCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [formData, dispatch, editEquipmentUseCase, currentEquipment]);

  const handlePressSave = useCallback(async (): Promise<void> => {
    if (!currentEquipment.id) {
      await saveNewEquipment();
    } else {
      await saveEditEquipment();
    }
  }, [saveNewEquipment, saveEditEquipment, currentEquipment]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(addEditEquipmentCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (addEditEquipmentIsOpenModal) {
      setFormData({ name: currentEquipment.name });
      setIsLoading(false);
      setTextError('');
    }
  }, [addEditEquipmentIsOpenModal, currentEquipment]);

  return (
    <Modal
      title={!currentEquipment.id ? 'Cadastro de novo equipamento' : 'Edição de equipamento'}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addEditEquipmentIsOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-equipment"
        label="Novo equipamento"
        onChange={(e) => setFormData({ name: e.target.value })}
        defaultValue={formData.name}
        textError={textError}
        type="text"
      />
    </Modal>
  );
};

export { CreateEditEquipmentModal };
