import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, useToast, VStack } from '@chakra-ui/react';

import TagBox from 'devextreme-react/tag-box';

import { RootState } from '@/main/config/redux';
import { addNode } from '@/modules/management-tree/state/actions';
import { makeCreateProjectUseCase } from '@/modules/project/factories/make-create-project-use-case';
import { makeLoadModulesUseCase } from '@/modules/project/factories/make-load-modules-use-case';
import { ModuleModel } from '@/modules/project/models/module';
import { ProjectModel } from '@/modules/project/models/project';
import { addProjectCloseModal } from '@/modules/project/state/actions';
import { ICreateProject } from '@/modules/project/use-cases/contracts/create-project';
import { ILoadModules } from '@/modules/project/use-cases/contracts/load-modules';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type InputProps = {
  name: string;
  moduleIds: string[];
};

const CreateProjectModal: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const loadModulesUseCase = useMemo((): ILoadModules => makeLoadModulesUseCase(), []);

  const createProjectUseCase = useMemo((): ICreateProject => makeCreateProjectUseCase(), []);

  const { addProjectIsOpenModal } = useSelector((state: RootState) => state.project);

  const [formData, setFormData] = useState<InputProps>({
    name: '',
    moduleIds: [],
  });
  const [modules, setModules] = useState<Omit<ModuleModel, 'permissions'>[]>([]);
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const newProject = await createProjectUseCase.execute(formData);
      if (newProject) {
        dispatch(addNode({ ...newProject, expanded: false, parentId: '', projectId: '' }));
        dispatch(addProjectCloseModal());
      }
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setFormData({ name: '', moduleIds: [] });
      setIsLoading(false);
    }
  }, [formData, dispatch, createProjectUseCase]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '', moduleIds: [] });
    dispatch(addProjectCloseModal());
  }, [dispatch]);

  const onSelectionChanged = useCallback((addedItems: ProjectModel[], removedItems: ProjectModel[]) => {
    if (addedItems.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        moduleIds: [...prevState.moduleIds, addedItems[0].id],
      }));
    }

    if (removedItems.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        moduleIds: prevState.moduleIds.filter((id) => id !== removedItems[0].id),
      }));
    }
  }, []);

  const resetState = useCallback(() => {
    setFormData({ name: '', moduleIds: [] });
    setIsLoading(false);
    setTextError('');
  }, []);

  const fetchModules = useCallback(async () => {
    try {
      const response = await loadModulesUseCase.execute();
      if (response && response.length) {
        setModules(response);
      }
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar listar os modulos disponiveis. Tente novamente',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [loadModulesUseCase, toast]);

  useEffect(() => {
    if (addProjectIsOpenModal) {
      fetchModules();
      resetState();
    }
  }, [addProjectIsOpenModal, resetState, fetchModules]);

  return (
    <Modal
      title="Cadastro de novo projeto"
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addProjectIsOpenModal}
      isLoading={isLoading}
    >
      <VStack spacing="5" alignItems="flex-start" w="100%" h="100%">
        <InputForm
          id="new-project"
          label="Novo projeto"
          onChange={(e) => setFormData((prevState) => ({ ...prevState, name: e.target.value }))}
          textError={textError}
          type="text"
        />

        <Flex flexDir="column" w="100%">
          <FormLabel>Modulos</FormLabel>
          <TagBox
            dataSource={modules}
            displayExpr="name"
            valueExpr="id"
            placeholder="Selecione os módulos do projeto"
            onSelectionChanged={(e) => onSelectionChanged(e.addedItems, e.removedItems)}
          />
        </Flex>
      </VStack>
    </Modal>
  );
};

export { CreateProjectModal };
