import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import ContextMenu from 'devextreme-react/context-menu';

import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { DatasourceContextMenu } from '@/modules/management-tree/presentation/types/datasource-context-meny';
import { checkNodePermission } from '@/modules/management-tree/use-cases/check-node-permission';
import { makeGetFeaturesUseCase } from '@/modules/map-view/factories/make-get-features-use-case';
import { setPolygon } from '@/modules/map-view/state/actions';
import { NodeType } from '@/modules/project/models/project';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { NodeWrapper } from '../node-wrapper';
import { AddPolygonToMap, DeleteNode } from './context-menu-options';
type Props = {
  id: string;
  name: string;
  nodeType: NodeType;
};

const ICON = <i className="esri-icon esri-icon-polygon" style={{ color: '#FFF' }} />;

const PolygonNode: React.FC<Props> = ({ id, name, nodeType }: Props) => {
  const dispatch = useDispatch();

  const getFeaturesUseCase = useMemo(() => makeGetFeaturesUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const loadPolygon = useCallback(async () => {
    const currentNode = localStorageAdapter.getItem('currentNode') as ManagementTreeModel;

    const features = await getFeaturesUseCase.execute({ id: currentNode.id });
    if (features) {
      dispatch(setPolygon({ id: currentNode.id, name: currentNode.name, ...features }));
    }
  }, [getFeaturesUseCase, dispatch, localStorageAdapter]);

  const dataSource = useCallback(() => {
    const currentProject = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;

    const polygonToMap = AddPolygonToMap;
    polygonToMap.onClick = loadPolygon;

    const menus: DatasourceContextMenu[] = [polygonToMap];

    const { modules } = currentProject;

    if (checkNodePermission(modules, 'Criar nós')) {
      menus.push(DeleteNode);
    }

    return menus;
  }, [loadPolygon, localStorageAdapter]);

  return (
    <React.Fragment>
      <ContextMenu target={`.${nodeType}-${id}`} dataSource={dataSource()} />
      <NodeWrapper className={`${nodeType}-${id}`} icon={ICON} name={name} />
    </React.Fragment>
  );
};

const Component = memo(PolygonNode);
export { Component as PolygonNode };
