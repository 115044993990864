import produce from 'immer';
import { Reducer } from 'redux';

import { ProjectState, ActionTypes } from './types';

const INITIAL_STATE: ProjectState = {
  addProjectIsOpenModal: false,
  assigmentUsersIsOpenModal: false,
  listTaksIsOpenModal: false,
};

const project: Reducer<ProjectState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.ADD_PROJECT_OPEN_MODAL: {
        draft.addProjectIsOpenModal = true;
        break;
      }
      case ActionTypes.ADD_PROJECT_CLOSE_MODAL: {
        draft.addProjectIsOpenModal = false;
        break;
      }
      case ActionTypes.ASSIGMENT_USERS_OPEN_MODAL: {
        draft.assigmentUsersIsOpenModal = true;
        break;
      }
      case ActionTypes.ASSIGMENT_USERS_CLOSE_MODAL: {
        draft.assigmentUsersIsOpenModal = false;
        break;
      }
      case ActionTypes.LIST_TASKS_OPEN_MODAL: {
        draft.listTaksIsOpenModal = true;
        break;
      }
      case ActionTypes.LIST_TASKS_CLOSE_MODAL: {
        draft.listTaksIsOpenModal = false;
        break;
      }
      default:
    }
  });
};

export default project;
