import React from 'react';

import { ForgotPassword } from '../presentation/components/page/forgot-password';
import { makeForgotPasswordUseCase } from './make-forgot-password-use-case';
import { makeForgotPasswordValidation } from './make-forgot-password-validation';

export const makeForgotPasswordPage: React.FC = () => {
  return (
    <ForgotPassword validation={makeForgotPasswordValidation()} forgotPasswordUseCase={makeForgotPasswordUseCase()} />
  );
};
