import produce from 'immer';
import { Reducer } from 'redux';

import { TruckState, ActionTypes } from './types';

const INITIAL_STATE: TruckState = {
  trucks: [],

  addEditTruckIsOpenModal: false,
};

const truck: Reducer<TruckState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_TRUCKS: {
        draft.trucks = action.payload.trucks;
        break;
      }
      case ActionTypes.ADD_EDIT_TRUCK_OPEN_MODAL: {
        draft.addEditTruckIsOpenModal = true;
        break;
      }
      case ActionTypes.ADD_EDIT_TRUCK_CLOSE_MODAL: {
        draft.addEditTruckIsOpenModal = false;
        break;
      }
      case ActionTypes.SET_NEW_TRUCK: {
        draft.trucks.push(action.payload.truck);
        break;
      }
      case ActionTypes.REMOVE_TRUCK: {
        draft.trucks = draft.trucks.filter((truck) => truck.id !== action.payload.id);
        break;
      }
      case ActionTypes.UPDATE_TRUCK: {
        draft.trucks = draft.trucks.map((truck) =>
          truck.id === action.payload.truck.id ? { ...action.payload.truck } : truck,
        );
        break;
      }
      default:
    }
  });
};

export default truck;
