import React, { ReactChild, ReactChildren, useEffect } from 'react';

type Props = {
  children: ReactChild[] | ReactChildren[] | ReactChild | ReactChildren;
};

import 'devextreme-intl';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';

const DevExtreme: React.FC<Props> = ({ children }: Props) => {
  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  return <>{children}</>;
};

export { DevExtreme };
