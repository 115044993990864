import React, { ReactChild, ReactChildren } from 'react';

import { ChakraProvider as Chakra, ColorModeScript } from '@chakra-ui/react';

import theme from '../theme/chakra-theme';

type Props = {
  children: ReactChild[] | ReactChildren[] | ReactChild | ReactChildren;
};

const ChakraProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <ColorModeScript />
      <Chakra resetCSS theme={theme}>
        {children}
      </Chakra>
    </>
  );
};

export { ChakraProvider };
