import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ILoadUserPermission } from './contracts/load-user-permission';

export class LoadUserPermissionUseCase implements ILoadUserPermission {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ILoadUserPermission.Result>) {}

  async execute(params: ILoadUserPermission.Params): Promise<ILoadUserPermission.Result | undefined> {
    const { projectId } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${projectId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
