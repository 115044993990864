/* eslint-disable no-irregular-whitespace */
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  const html = `
  <div class="iubenda_legal_document" style="padding: 35px">
  <h1>Política de Privacidade do <strong>Argus - Amostragem</strong></h1>
  <p>Este Aplicativo recolhe alguns Dados Pessoais dos Usuários.</p>
  <br /> 
  <p>Este documento pode ser impresso para fins de consulta, através do comando imprimir nas configurações de seu navegador.</p>
  <h2 id="owner_of_the_data"> Proprietário e Controlador de Dados </h2>
  <p>Athenas Agricola - Av: Carlos Berchieri, 2271</p>
  <p><strong>E-mail de contato do Proprietário:</strong> felipe@athenasagricola.com.br</p>
  <h2 id="types_of_data"> Tipos de Dados coletados </h2>
  <p> Entre os tipos de Dados Pessoais que este Aplicativo coleta, por si mesmo ou através de terceiros, existem: Permissão de câmera; Permissão de localização precisa (ininterrupta); Permissão de localização aproximada (ininterrupta); Permissão de armazenamento; Permissão de câmera, sem salvar ou gravar; posição geográfica. </p>
  <p>Detalhes completos sobre cada tipo de Dados Pessoais coletados são fornecidos nas seções dedicadas desta política de privacidade ou por textos explicativos específicos exibidos antes da coleta de Dados.<br /> Os Dados Pessoais poderão ser fornecidos livremente pelo Usuário, ou, no caso dos Dados de Utilização, coletados automaticamente ao se utilizar este Aplicativo.<br /> A menos que especificado diferentemente todos os Dados solicitados por este Aplicativo são obrigatórios e a falta de fornecimento destes Dados poderá impossibilitar este Aplicativo de fornecer os seus Serviços. Nos casos em que este Aplicativo afirmar especificamente que alguns Dados não forem obrigatórios, os Usuários ficam livres para deixarem de comunicar estes Dados sem nenhuma consequência para a disponibilidade ou o funcionamento do Serviço.<br /> Os Usuários que tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios estão convidados a entrar em contato com o Proprietário.<br /> Quaisquer usos de cookies – ou de outras ferramentas de rastreamento – por este Aplicativo ou pelos proprietários de serviços terceiros utilizados por este Aplicativo serão para a finalidade de fornecer os Serviços solicitados pelo Usuário, além das demais finalidades descritas no presente documento e na Política de Cookies, se estiver disponível. </p>
  <p>Os Usuários ficam responsáveis por quaisquer Dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através deste Serviço (este Aplicativo) e confirmam que possuem a autorização dos terceiros para fornecerem os Dados para o Proprietário.</p>
  <h2 id="place_of_processing">Modo e local de processamento dos Dados</h2>
  <h3>Método de processamento</h3>
  <p>O Proprietário tomará as medidas de segurança adequadas para impedir o acesso não autorizado, divulgação, alteração ou destruição não autorizada dos Dados.<br /> O processamento dos Dados é realizado utilizando computadores e /ou ferramentas de TI habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados. Além do Proprietário, em alguns casos, os Dados podem ser acessados por certos tipos de pessoas encarregadas, envolvidas com a operação deste Serviço (este Aplicativo) (administração, vendas, marketing, administração legal do sistema) ou pessoas externas (como fornecedores terceirizados de serviços técnicos, carteiros, provedores de hospedagem, empresas de TI, agências de comunicação) nomeadas, quando necessário, como Processadores de Dados por parte do Proprietário. A lista atualizada destas partes pode ser solicitada ao Proprietário a qualquer momento.</p>
  <h3>Base jurídica para o processamento</h3>
  <p>O Proprietário poderá processar os Dados Pessoais relacionados ao Usuário se uma das hipóteses a seguir se aplicar:</p>
  <ul>
     <li>os Usuários tenham dado a sua anuência para uma ou mais finalidades específicas; Observação: De acordo com algumas legislações o Proprietário poderá ter a permissão para processar os Dados Pessoais ATÉ QUE O Usuário faça objeção a isto (“opt-out”), sem ter que se basear em anuência ou em quaisquer outras bases jurídicas a seguir. Isto contudo não se aplica sempre que o processamento de Dados Pessoais estiver sujeito à legislação europeia de proteção de dados;</li>
     <li>o fornecimento dos Dados for necessário para o cumprimento de um contrato com o Usuário e/ou quaisquer obrigações pré-contratuais do mesmo;</li>
     <li>o processamento for necessário para o cumprimento de uma obrigação jurídica à qual o Proprietário estiver sujeito;</li>
     <li>o processamento estiver relacionado a uma tarefa que for executada no interesse público ou no exercício de uma autorização oficial na qual o Proprietário estiver investido;</li>
     <li>o processamento for necessário para a finalidade de interesses legítimos perseguidos pelo Proprietário ou por um terceiro;</li>
  </ul>
  <p>Em qualquer caso, o Proprietário colaborará de bom grado para esclarecer qual a base jurídica que se aplica ao processamento, e em especial se o fornecimento de Dados for um requisito obrigatório por força de lei ou contratual, ou uma exigência necessária para celebrar um contrato.</p>
  <h3>Lugar</h3>
  <p>Os dados são processados ​​nas sedes de operação dos Proprietários, e em quaisquer outros lugares onde as partes envolvidas com o processamento estiverem localizadas.<br /> Dependendo da localização do Usuário as transferências de dados poderão envolver a transferência dos Dados do Usuário para outro país que não seja o seu. Para descobrirem mais sobre o local de processamento de tais Dados transferidos os Usuários poderão verificar a seção contendo os detalhes sobre o processamento de Dados Pessoais.</p>
  <p>Os Usuários também possuem o direito de serem informados sobre a base jurídica das transferências de Dados para países de fora da União Europeia ou para quaisquer organizações internacionais regidas pelo direito internacional público ou formadas por dois ou mais países, tal como a ONU, e sobre as medidas de segurança tomadas pelo Proprietário para proteger os seus Dados.<br /><br /> Se ocorrerem quaisquer tais transferências os Usuários poderão descobrir mais a respeito verificando as seções pertinentes deste documento ou perguntando ao Proprietário utilizando as informações fornecidas na seção de contatos.</p>
  <h3>Período de conservação</h3>
  <p>Os Dados Pessoais serão processados e armazenados pelo tempo que for necessário para as finalidades para as quais forem coletados.</p>
  <p>Portanto:</p>
  <ul>
     <li>Os Dados Pessoais coletados para as finalidades relacionadas com a execução de um contrato entre o Proprietário e o Usuário serão conservados até que tal contrato tenha sido completamente cumprido.</li>
     <li>Os Dados Pessoais coletados para as finalidades relacionadas com os legítimos interesses do Proprietário serão conservados pelo tempo que for necessário para cumprir tais finalidades. Os Usuários poderão obter informações específicas sobre os interesses legítimos perseguidos pelo Proprietário dentro das seções pertinentes deste documento ou entrando em contato com o Proprietário.</li>
  </ul>
  <p>O Proprietário poderá ter a permissão de conservar os Dados Pessoais por um prazo maior sempre que o Usuário tiver dado a sua autorização para tal processamento, enquanto tal autorização não tiver sido retirada. Além disso, o Proprietário poderá ficar obrigado a conservar os Dados Pessoais por um prazo maior em todas as ocasiões em que estiver obrigado a fazê-lo para o cumprimento de uma obrigação jurídica ou em cumprimento de um mandado de uma autoridade.<br /><br /> Assim que o prazo de conservação vencer os Dados Pessoais serão apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito à portabilidade dos dados não poderão ter o seu cumprimento exigido após o vencimento do prazo de conservação.</p>
  <h2 id="use_collected_data">As finalidades do processamento</h2>
  <p> Os Dados relativos ao Usuário são coletados para permitir que o Proprietário preste seu Serviço, cumpra suas obrigações legais, responda a solicitações de execução, proteja seus direitos e interesses (ou aqueles de seus Usuários ou terceiros), detecte qualquer atividade maliciosa ou fraudulenta, assim como: Permissões de dispositivos para acesso a Dados Pessoais e Interações baseadas na localização. </p>
  <p>Para obter informações específicas sobre os Dados Pessoais utilizados para cada finalidade, o Usuário poderá consultar a seção "Informações detalhadas sobre o processamento de Dados Pessoais".</p>
  <h2 id="mobile_permissions">Permissões de dispositivos para acesso a Dados Pessoais</h2>
  <p>Dependendo do dispositivo específico do Usuário, este Aplicativo pode solicitar certas permissões que permitem-no acessar os Dados do dispositivo do Usuário conforme descrito abaixo.</p>
  <p>Por padrão estas permissões devem ser concedidas pelo Usuário antes que as respectivas informações possam ser acessadas. Uma vez que a permissão tenha sido dada, esta pode ser revogada pelo Usuário a qualquer momento. Para poder revogar estas permissões os Usuários devem consultar as configurações do dispositivo ou entrar em contato com o Proprietário para receber suporte através dos dados para contato fornecidos no presente documento.<br />O procedimento exato para controlar as permissões de aplicativos poderá depender dos dispositivo e software do Usuário.</p>
  <p>Por favor observar que a revogação de tais permissões poderá afetar o funcionamento apropriado do este Aplicativo</p>
  <p>Se o Usuário conceder quaisquer das permissões relacionadas abaixo, estes Dados Pessoais respectivos poderão ser processados (isto é, acessados, modificados ou removidos) por este Aplicativo.</p>
  <h3>Permissão de armazenamento</h3>
  <p>Usada para acessar o armazenamento externo compartilhado, inclusive a leitura e o acréscimo de quaisquer itens.</p>
  <h3>Permissão de câmera</h3>
  <p>Usada para acessar a câmera ou capturar imagens e vídeo do dispositivo.</p>
  <h3>Permissão de câmera, sem salvar ou gravar</h3>
  <p>Usada para acessar a câmera ou capturar imagens e vídeos do dispositivo. <br/> Este Aplicativo não salvará, nem gravará o que for capturado pela câmera.</p>
  <h3>Permissão de localização aproximada (ininterrupta)</h3>
  <p>Usada para acessar a localização aproximada do dispositivo do Usuário. Este Aplicativo poderá coletar, usar e compartilhar os Dados de localização do Usuário para poder fornecer serviços com base na localização.</p>
  <h3>Permissão de localização precisa (ininterrupta)</h3>
  <p>Usada para acessar a localização precisa do dispositivo do Usuário. Este Aplicativo poderá coletar, usar e compartilhar os Dados de localização do Usuário para poder fornecer serviços com base na localização.</p>
  <div data-locale="pt-BR"></div>
  <h2 id="data_processing_detailed_info">Informações detalhadas sobre o processamento de Dados Pessoais</h2>
  <p>Os Dados Pessoais são recolhidos para os seguintes fins e utilizando os seguintes serviços:</p>
  <h3>Interações baseadas na localização</h3>
  <h4>Geolocation (este Aplicativo)</h4>
  <p>Este Aplicativo pode coletar, usar e compartilhar a localização de dados do usuário a fim de fornecer serviços baseados em localização.<br/> A maioria dos navegadores e dispositivos fornecem ferramentas para optar o não uso este recurso de padrão. Se a autorização explícita foi fornecida, os dados de localização do Usuário podem serem rastreado por este Aplicativo.</p>
  <p>Dados Pessoais processados: posição geográfica.</p>
  <h3>Permissões de dispositivos para acesso a Dados Pessoais</h3>
  <p>Este Aplicativo solicita determinadas permissões dos Usuário que lhe permitem acessar os Dados do dispositivo do Usuário conforme descritos abaixo.</p>
  <h4>Permissões de dispositivos para acesso a Dados Pessoais (este Aplicativo)</h4>
  <p>Este Aplicativo solicita determinadas permissões dos Usuário que lhe permitem acessar os Dados do dispositivo do Usuário conforme descritos en este documento.</p>
  <p>Dados Pessoais processados: Permissão de armazenamento; Permissão de câmera; Permissão de câmera, sem salvar ou gravar; Permissão de localização aproximada (ininterrupta); Permissão de localização precisa (ininterrupta).</p>
  <div data-locale="pt-BR"></div>
  <h2 id="rights_subjects">Os direitos dos Usuários</h2>
  <p>Os Usuários poderão exercer determinados direitos a respeito dos seus Dados processados pelo Proprietário.</p>
  <p>Em especial, os Usuários possuem os direitos a fazer o seguinte:</p>
  <ul>
     <li><strong>Retirar a sua anuência a qualquer momento.</strong> Os Usuários possuem o direito de retirar a sua anuência nos casos em que tenham dado a sua anuência anteriormente para o processamento dos seus Dados Pessoais.</li>
     <li><strong>Objetar o processamento dos seus Dados.</strong> Os Usuários possuem o direito de objetar o processamento dos seus Dados se o processamento for executado sobre outra base jurídica que não a anuência. São fornecidos detalhes adicionais na seção específica abaixo.</li>
     <li><strong>Acessar os seus Dados.</strong> Os Usuários possuem o direito de saber se os seus Dados estão sendo tratados pelo Proprietário, obter revelações sobre determinados aspectos do tratamento e conseguir uma cópia dos Dados que estiverem sendo tratados.</li>
     <li><strong>Verificar e pedir retificação.</strong> Os Usuários possuem o direito de verificar a exatidão dos seus Dados e de pedir que os mesmos sejam atualizados ou corrigidos.</li>
     <li><strong>Restringir o processamento dos seus Dados.</strong> Os Usuários possuem o direito de, sob determinadas circunstâncias, restringir o processamento dos seus Dados para qualquer outra finalidade que não seja o armazenamento dos mesmos.</li>
     <li><strong>Ter os seus Dados Pessoais apagados ou retirados de outra maneira.</strong> Os Usuários possuem o direito de, sob determinadas circunstâncias, obter a eliminação dos seus Dados do Proprietário.</li>
     <li><strong>Receber os seus Dados e ter os mesmos transferidos para outro controlador.</strong> Os Usuários possuem o direito de receber os seus Dados em um formato estruturado, utilizado comumente e apto a ser lido por máquinas e, se for viável tecnicamente, fazer com que os mesmos sejam transmitidos para outro controlador sem nenhum empecilho. Esta determinação se aplica condicionada a que os Dados sejam processados por meios automatizados e que o processamento esteja baseado na anuência do Usuário, em um contrato do qual o Usuário seja uma das partes ou por obrigações pré-contratuais do mesmo.</li>
     <li><strong>Registrar uma reclamação.</strong> Os Usuários possuem o direito de apresentar reclamação perante a sua autoridade de proteção de dados competente.</li>
  </ul>
  <h3>Detalhes sobre o direito de objetar ao processamento</h3>
  <p>Nos casos em que os Dados Pessoais forem processados por um interesse público, no exercício de uma autorização oficial na qual o Proprietário estiver investido ou para finalidades dos interesses legítimos perseguidos pelo Proprietário, os Usuários poderão objetar tal processamento através do fornecimento de um motivo relacionado com a sua situação em especial para justificar a objeção.</p>
  <p>Os Usuários devem saber, entretanto, que caso os seus Dados Pessoais sejam processados para finalidades de marketing direto eles podem objetar tal processamento a qualquer momento sem fornecer nenhuma justificativa. Os Usuários podem consultar as seções respectivas deste documento.</p>
  <h3>Como exercer estes direitos</h3>
  <p>Quaisquer pedidos para exercer os direitos dos Usuários podem ser direcionados ao Proprietário através dos dados para contato fornecidos neste documento. Estes pedidos podem ser exercidos sem nenhum custo e serão atendidos pelo Proprietário com a maior brevidade possível e em todos os casos em prazo inferior a um mês.</p>
  <h2 id="further_data_processing_info">Informações adicionais sobre a coleta e processamento de Dados</h2>
  <h3>Ação jurídica</h3>
  <p>Os Dados Pessoais dos Usuários podem ser utilizados para fins jurídicos pelo Proprietário em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste Serviço (este Aplicativo) ou dos Serviços relacionados.<br /> O Usuário declara estar ciente de que o Proprietário poderá ser obrigado a revelar os Dados Pessoais mediante solicitação das autoridades governamentais.</p>
  <h3>Informações adicionais sobre os Dados Pessoais do Usuário</h3>
  <p>Além das informações contidas nesta política de privacidade, este Aplicativo poderá fornecer ao Usuário informações adicionais e contextuais sobre os serviços específicos ou a coleta e processamento de Dados Pessoais mediante solicitação.</p>
  <h3>Logs do sistema e manutenção</h3>
  <p>Para fins de operação e manutenção, este Aplicativo e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este Aplicativo (logs do sistema) ou usar outros Dados Pessoais (tais como endereço IP) para esta finalidade.</p>
  <h3>As informações não contidas nesta política</h3>
  <p>Mais detalhes sobre a coleta ou processamento de Dados Pessoais podem ser solicitados ao Proprietário, a qualquer momento. Favor ver as informações de contato no início deste documento. </p>
  <h3>Como são tratados os pedidos de “Não me Rastreie”</h3>
  <p>Este Aplicativo não suporta pedidos de “Não Me Rastreie”.<br />Para determinar se qualquer um dos serviços de terceiros que utiliza honram solicitações de “Não Me Rastreie”, por favor leia as políticas de privacidade.</p>
  <h3>Mudanças nesta política de privacidade</h3>
  <p>O Proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento, através de notificação a seus Usuários nesta página e possivelmente dentro deste Serviço (este Aplicativo) e/ou – na medida em que for técnica e juridicamente viável – enviando um aviso para os Usuários através de quaisquer informações de contato disponíveis para o Proprietário. É altamente recomendável checar esta página regularmente, consultando a data da última modificação informada na parte inferior.<br /><br />Caso as mudanças afetem as atividades de processamento realizadas com base na anuência do Usuário, o Proprietário coletará nova anuência do Usuário, onde for exigida.</p>
  <h3 id="definitions_and_legal_references"> Definições e referências jurídicas </h3>
  <h4>Dados Pessoais (ou Dados)</h4>
  <p>Quaisquer informações que diretamente, indiretamente ou em relação com outras informações – incluindo um número de identificação pessoal – permitam a identificação ou identificabilidade de uma pessoa física.</p>
  <h4>Dados de Uso</h4>
  <p>As informações coletadas automaticamente através deste este Aplicativo (ou serviços de terceiros contratados neste Serviço (este Aplicativo)), que podem incluir: os endereços IP ou nomes de domínio dos computadores utilizados pelos Usuários que utilizam este Aplicativo, os endereços URI (Identificador Uniforme de Recurso), a data e hora do pedido, o método utilizado para submeter o pedido ao servidor, o tamanho do arquivo recebido em resposta, o código numérico que indica o status do servidor de resposta (resultado positivo, erro , etc.), o país de origem, as características do navegador e do sistema operacional utilizado pelo Usuário, os vários detalhes de tempo por visita (por exemplo, o tempo gasto em cada página dentro do aplicativo) e os detalhes sobre o caminho seguido dentro da aplicação, com especial referência à sequência de páginas visitadas e outros parâmetros sobre o sistema operacional do dispositivo e/ou ambiente de TI do Usuário.</p>
  <h4>Usuário</h4>
  <p>A pessoa que usa este Aplicativo que, a menos que especificado diferentemente, coincida com o Titular dos Dados.</p>
  <h4>Titular dos Dados</h4>
  <p>A pessoa física a quem os Dados Pessoais se referem.</p>
  <h4>Processador de Dados (ou supervisor de Dados)</h4>
  <p>A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade. </p>
  <h4>Controlador de Dados (ou Proprietário)</h4>
  <p>A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço (este Aplicativo). O Controlador de Dados, a menos que seja especificado de outra forma, é o Proprietário deste Serviço (este Aplicativo).</p>
  <h4>Este Aplicativo</h4>
  <p>O meio pelo qual os Dados Pessoais do Usuário são coletados e processados.</p>
  <h4>Serviço</h4>
  <p>O serviço fornecido por este Aplicativo conforme descrito nos termos relativos (se disponíveis) e neste site/aplicativo.</p>
  <h4>União Europeia (ou UE)</h4>
  <p>A menos que especificado diferentemente, todas as referências feitas neste documento à União Europeia incluem todos os atuais estados membros da União Europeia e do Espaço Econômico Europeu.</p>
  <h4>Informação jurídica</h4>
  <p>Esta declaração de privacidade foi preparada com base em determinações de múltiplas legislações, inclusive os Arts. 13/14 do Regulamento (EU) 2016/679 (GDPR - Regulamento Geral de Proteção de Dados).</p>
  <p>Esta política de privacidade se refere somente a este Aplicativo, se não afirmado diferentemente neste documento.</p>
  <p> Última atualização: 10 de maio de 2022 </p>
</div>
<!-- /iubenda_legal_document --> <script type="text/javascript"> window.tryFunc = function(fName,args){ if(typeof window[fName]==='function'){ window[fName](args); }else{ if(args){ /* default behaviour is link */ if(args.href){ /* default link is target='_blank' */ window.open(args.href) } } } return false; /* inhibit default behaviour */ } </script> <script type="text/javascript"> window.tryGaOptOut = function(args){ /* gaOptout should have been defined by host */ if(typeof(gaOptout)=='function'){ gaOptout(); alert('Opt-out OK'); /* give feedback to user that opt-out has been performed */ }else if(typeof(gaOptOut)=='function'){ gaOptOut(); alert('Opt-out OK'); /* give feedback to user that opt-out has been performed */ }else{ /* gaOptOut is not defined. Just open args.href in another window (default behaviour) */ if(args){ if(args.href){ window.open(args.href); } } } } </script>"
  `;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default PrivacyPolicy;
