import React from 'react';

import { Button as ChakraBtn, ButtonProps } from '@chakra-ui/button';

const ButtonSubmit: React.FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {
  return (
    <ChakraBtn
      variant="solid"
      size="md"
      mt="20px"
      color="whiteAlpha.900"
      fontWeight="bold"
      fontSize="md"
      bgColor="green.500"
      _hover={{
        bgColor: 'green.700',
      }}
      {...props}
    >
      {children}
    </ChakraBtn>
  );
};

export { ButtonSubmit };
