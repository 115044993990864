import React from 'react';

import { ResetPassword } from '../presentation/components/page/reset-password';
import { makeCheckPasswordResetUseCase } from './make-check-password-reset-use-case';
import { makeResetPasswordUseCase } from './make-reset-password-use-case';
import { makeResetPasswordValidation } from './make-reset-password-validation';

export const makeResetPasswordPage: React.FC = () => {
  return (
    <ResetPassword
      checkPasswordResetUseCase={makeCheckPasswordResetUseCase()}
      resetPasswordUseCase={makeResetPasswordUseCase()}
      validation={makeResetPasswordValidation()}
    />
  );
};
