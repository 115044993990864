import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { Container } from '@/modules/equipment-vehicle/presentantion/components/atoms/container';
import { ContenteSpinner } from '@/modules/equipment-vehicle/presentantion/components/atoms/content-spinner';
import { DividerHeader } from '@/modules/equipment-vehicle/presentantion/components/atoms/divider-header';
import { WrapperTable } from '@/modules/equipment-vehicle/presentantion/components/atoms/wrapper-table';
import { EmptyContentText } from '@/modules/equipment-vehicle/presentantion/components/molecules/empty-content-text';
import { HeaderTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/header-table';
import { RowTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/row-table';
import { Permission } from '@/modules/space/models/space-permission';
import { makeDeleteTruckUseCase } from '@/modules/truck/factories/make-delete-truck-use-case';
import { makeLoadTrucksUseCase } from '@/modules/truck/factories/make-load-trucks-use-case';
import { TruckModel } from '@/modules/truck/models/truck';
import { addEditTruckOpenModal, removeTruck, setTrucks } from '@/modules/truck/state/actions';
import { AlertDialog } from '@/shared/presentation/components/atoms/alert-dialog';
import { Can } from '@/shared/presentation/components/atoms/can';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

import { CreateEditTruckModal } from '../molecules/create-edit-truck-modal';

const Truck: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    space: { currentSpace },
    truck: { trucks },
  } = useSelector((state: RootState) => state);

  const loadTrucksUseCase = useMemo(() => makeLoadTrucksUseCase(), []);
  const deleteTrucktUseCase = useMemo(() => makeDeleteTruckUseCase(), []);

  const [currentTruck, setCurrentTruck] = useState<TruckModel>({
    id: '',
    name: '',
    slug: '',
    spaceId: '',
  });
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const fetchTrucks = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await loadTrucksUseCase.execute();

      if (data && data.length) {
        dispatch(setTrucks(data));
      } else {
        dispatch(setTrucks([]));
      }
    } catch (error) {
      toast({
        title: 'Falha ao carregar veiculos',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [loadTrucksUseCase, toast, dispatch]);

  const onClickAdd = useCallback(() => {
    setCurrentTruck({
      id: '',
      name: '',
      slug: '',
      spaceId: '',
    });
    dispatch(addEditTruckOpenModal());
  }, [dispatch]);

  const onConfirmDelete = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await deleteTrucktUseCase.execute({ id: currentTruck.id });
      dispatch(removeTruck(currentTruck.id));
      setAlertDialogIsOpen(false);
    } catch (error) {
      toast({
        title: 'Falha ao remover veiculo',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }, [deleteTrucktUseCase, dispatch, currentTruck.id, toast]);

  const onClickDelete = useCallback((truck: TruckModel) => {
    setCurrentTruck(truck);
    setAlertDialogIsOpen(true);
  }, []);

  const onClickEdit = useCallback(
    (truck: TruckModel) => {
      setCurrentTruck(truck);
      dispatch(addEditTruckOpenModal());
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentSpace?.id) {
      fetchTrucks();
    }
  }, [fetchTrucks, currentSpace.id]);

  return (
    <Container>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <CreateEditTruckModal currentTruck={currentTruck} />
        <AlertDialog
          cancelButtonText="Não"
          confirmButtonText="Sim"
          header="Atenção"
          message="Deseja realmente excluir este veiculo?"
          isLoading={isLoadingDelete}
          isOpen={alertDialogIsOpen}
          onCancel={() => {
            setAlertDialogIsOpen(false);
          }}
          onConfirm={onConfirmDelete}
        />
      </Can>
      <HeaderTable title="Veiculos" onClickAdd={onClickAdd} />
      <DividerHeader />
      <WrapperTable>
        {isLoading && (
          <ContenteSpinner>
            <Spinner />
          </ContenteSpinner>
        )}

        {!isLoading && trucks.length > 0
          ? trucks.map((truck) => (
              <RowTable
                key={truck.id}
                text={truck.name}
                onClickDelete={() => onClickDelete(truck)}
                onClickEdit={() => onClickEdit(truck)}
              />
            ))
          : null}

        {!isLoading && trucks.length === 0 ? <EmptyContentText text="Não existe veiculos cadastrados" /> : null}
      </WrapperTable>
    </Container>
  );
};

export { Truck };
