import { HttpClient } from '@/shared/adapters/http/contracts/http-client';

import { IGetUser } from './contracts/get-user';

export class GetUserUseCase implements IGetUser {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IGetUser.Result>) {}

  async execute(): Promise<IGetUser.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    return {
      body: httpResponse.body as any,
      statusCode: httpResponse.statusCode,
    };
  }
}
