import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ExpiredPasswordChangeRequest } from '../presentation/errors/expired-password-change-request';
import { IResetPassword } from './contracts/reset-password';

export class ResetPasswordUseCase implements IResetPassword {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IResetPassword.Result>) {}

  async execute(params: IResetPassword.Params): Promise<IResetPassword.Result | undefined> {
    const { resetPasswordId, password, passwordConfirmation, userId } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${userId}`,
      method: 'put',
      body: {
        password,
        passwordConfirmation,
        resetPasswordId,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
