import { loadModules } from 'esri-loader';

import {
  Arcgis,
  MakeMap,
  MakeViewMap,
  MakeWidgetFullScreen,
  DisplayGeoJSONOnMap,
  GeoJSONToURL,
  MakePopupTemplate,
  AddLabelClass,
} from './contracts/arcgis';

type WebMapModules = [typeof import('esri/WebMap')];
type MapViewpModules = [typeof import('esri/views/MapView')];
type FullscreenModules = [typeof import('esri/widgets/Fullscreen')];
type GeoJSONLayerModules = [typeof import('esri/layers/GeoJSONLayer')];

export class ArcgisAdapter implements Arcgis {
  private map: __esri.WebMap | undefined;
  private mapView: __esri.MapView | undefined;
  private highlight: any | undefined;

  async makeMap(properties: MakeMap.Params): Promise<MakeMap.Result> {
    const [Map] = (await loadModules(['esri/Map'])) as WebMapModules;

    const _map = new Map(properties);
    this.map = _map;

    return _map;
  }

  async makeViewMap(properties: MakeViewMap.Params): Promise<MakeViewMap.Result> {
    const [MapView] = (await loadModules(['esri/views/MapView'])) as MapViewpModules;

    const _mapView = new MapView(properties);
    this.mapView = _mapView;

    return _mapView;
  }

  async makeWidgetFullScreen(properties: MakeWidgetFullScreen.Params): Promise<MakeWidgetFullScreen.Result> {
    const [Fullscreen] = (await loadModules(['esri/widgets/Fullscreen'])) as FullscreenModules;

    const fullscreen = new Fullscreen(properties);

    this.mapView?.ui.add(fullscreen, 'top-right');
  }

  removeLayerById(id: string): void {
    this.map?.layers.forEach((lyr) => {
      if (lyr.id === id) {
        lyr.destroy();
      }
    });
  }

  geoJSONToURL(properties: GeoJSONToURL.Params): GeoJSONToURL.Result {
    const { geojson } = properties;

    const blob = new Blob([JSON.stringify(geojson)], {
      type: 'application/json',
    });

    const url = URL.createObjectURL(blob);

    return url;
  }

  async displayGeoJSONOnMap(properties: DisplayGeoJSONOnMap.Params): Promise<DisplayGeoJSONOnMap.Result> {
    const { geojson, indexLayer, ...rest } = properties;

    const [GeoJSONLayer] = (await loadModules(['esri/layers/GeoJSONLayer'])) as GeoJSONLayerModules;

    if (properties?.id) {
      this.removeLayerById(properties?.id);
    }

    const url = this.geoJSONToURL({ geojson });

    const layer = new GeoJSONLayer({
      ...rest,
      url,
    });

    this.map?.layers.add(layer, indexLayer);

    await this.zoomToLayer(layer);
  }

  async zoomToLayer(layer: __esri.GeoJSONLayer, duration = 3000): Promise<void> {
    const { extent } = (await layer.queryExtent()) as __esri.Extent;

    await this.mapView?.goTo(extent, { duration });
  }

  makePopupTemplate(properties: MakePopupTemplate.Params): MakePopupTemplate.Result {
    let content = '';

    properties.forEach((key) => {
      content += `<b>${key}:</b> {${key}} <br><br>`;
    });

    return {
      title: 'Tabela de atributos',
      content,
    };
  }

  getLayerById(id: string): __esri.Layer | undefined {
    const layer = this.map?.layers.find((lyr) => lyr.id === id);

    return layer;
  }

  highlightFeatureByAttribute(attribute: string, value: string): void {
    const where = `${attribute} = '${value}'`;

    const layer = this.getLayerById('point');

    if (layer) {
      this.mapView?.whenLayerView(layer).then((layerView) => {
        //@ts-ignore
        const query = layer.createQuery();
        query.where = where;
        //@ts-ignore
        layer.queryFeatures(query).then((result) => {
          if (this.highlight) {
            this.highlight.remove();
          }
          //@ts-ignore
          this.highlight = layerView.highlight(result.features);
        });
      });
    }
  }
  addLabelClass(properties: AddLabelClass.Params): AddLabelClass.Result {
    const { layerId, ...rest } = properties;

    const layer = this.getLayerById(layerId);

    if (layer) {
      layer.when().then(() => {
        //@ts-ignore
        layer.labelingInfo = [{ ...rest }];
      });
    }
  }
}
