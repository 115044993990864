import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IUpdateUserSpacePermission } from './contracts/update-user-space-permission';

export class UpdateUserUserSpacePermissionUseCase implements IUpdateUserSpacePermission {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IUpdateUserSpacePermission.Result>,
  ) {}

  async execute(params: IUpdateUserSpacePermission.Params): Promise<IUpdateUserSpacePermission.Result | undefined> {
    const { userId, permission } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${userId}`,
      method: 'put',
      body: {
        permission,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
