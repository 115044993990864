import turf from '@turf/turf';

export enum ActionTypes {
  SET_POLYGON = '@map-view/SET_POLYGON',
  SET_POINT = '@map-view/SET_POINT',
}

export type MapViewState = {
  polygon: (turf.FeatureCollection & { id: string; name: string }) | null;
  point: (turf.FeatureCollection & { id: string; name: string }) | null;
};
