import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IUpdateUserPermission } from './contracts/update-user-permission';

export class UpdateUserPermissionUseCase implements IUpdateUserPermission {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IUpdateUserPermission.Result>) {}

  async execute(params: IUpdateUserPermission.Params): Promise<IUpdateUserPermission.Result | undefined> {
    const { projectId, users } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${projectId}`,
      method: 'put',
      body: {
        users,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return undefined;
      default:
        throw new UnexpectedError();
    }
  }
}
