import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IDeleteProject } from './contracts/delete-project';

export class DeleteProjectUseCase implements IDeleteProject {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IDeleteProject.Result>) {}

  async execute(params: IDeleteProject.Params): Promise<IDeleteProject.Result | undefined> {
    const { projectId } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${projectId}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
