export enum ActionTypes {
  SET_CURRENT_PROJECT = '@project/SET_CURRENT_PROJECT',

  ADD_PROJECT_OPEN_MODAL = '@project/ADD_PROJECT_OPEN_MODAL',
  ADD_PROJECT_CLOSE_MODAL = '@project/ADD_PROJECT_CLOSE_MODAL',

  ASSIGMENT_USERS_OPEN_MODAL = '@project/ASSIGMENT_USERS_OPEN_MODAL',
  ASSIGMENT_USERS_CLOSE_MODAL = '@project/ASSIGMENT_USERS_CLOSE_MODAL',

  LIST_TASKS_OPEN_MODAL = '@project/LIST_TASKS_OPEN_MODAL',
  LIST_TASKS_CLOSE_MODAL = '@project/LIST_TASKS_CLOSE_MODAL',
}

export type ProjectState = {
  addProjectIsOpenModal: boolean;
  assigmentUsersIsOpenModal: boolean;
  listTaksIsOpenModal: boolean;
};
