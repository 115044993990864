import { action } from 'typesafe-actions';

import { MotorcycleModel } from '../models/motorcycle';
import { ActionTypes } from './types';

export const setMotorcycles = (motorcycles: MotorcycleModel[]) => action(ActionTypes.SET_MOTORCYCLES, { motorcycles });

export const addEditMotorcycleOpenModal = () => action(ActionTypes.ADD_EDIT_MOTORCYCLE_OPEN_MODAL);

export const addEditMotorcycleCloseModal = () => action(ActionTypes.ADD_EDIT_MOTORCYCLE_CLOSE_MODAL);

export const setNewMotorcycle = (motorcycle: MotorcycleModel) => action(ActionTypes.SET_NEW_MOTORCYCLE, { motorcycle });

export const removeMotorcycle = (id: string) => action(ActionTypes.REMOVE_MOTORCYCLE, { id });

export const updateMotorcycle = (motorcycle: MotorcycleModel) => action(ActionTypes.UPDATE_MOTORCYCLE, { motorcycle });
