import React, { memo, useCallback, useMemo } from 'react';
import { MdGroupWork } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, useToast } from '@chakra-ui/react';

import ContextMenu from 'devextreme-react/context-menu';

import { RootState } from '@/main/config/redux';
import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { DatasourceContextMenu } from '@/modules/management-tree/presentation/types/datasource-context-meny';
import { checkNodePermission } from '@/modules/management-tree/use-cases/check-node-permission';
import { makeCreateTilesUseCase } from '@/modules/project/factories/make-create-tiles-use-case';
import { NodeType } from '@/modules/project/models/project';
import { Permission } from '@/modules/space/models/space-permission';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { NodeWrapper } from '../node-wrapper';
import { AllocationUser, AddNode, CreateFullTiles, DeleteNode } from './context-menu-options';

type Props = {
  id: string;
  nodeType: NodeType;
  name: string;
};

const ICON = (
  <Icon
    as={MdGroupWork}
    __css={{
      path: {
        stroke: 'var(--chakra-colors-whiteAlpha-900)',
      },
    }}
    w="18px"
    h="18px"
  />
);

const ProjectNode: React.FC<Props> = ({ id, name, nodeType }: Props) => {
  const toast = useToast();

  const {
    currentSpace: { permission },
  } = useSelector((state: RootState) => state.space);

  const createTilesUseCase = useMemo(() => makeCreateTilesUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const createTiles = useCallback(async () => {
    const currentProject = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;
    await createTilesUseCase.execute({ projectId: currentProject.id });
    toast({
      title: 'Criação do mapa geral iniciada',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
  }, [createTilesUseCase, localStorageAdapter, toast]);

  const dataSource = useCallback(() => {
    const menus: DatasourceContextMenu[] = [];
    const currentProject = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;

    if (permission === Permission.ADMINISTRATOR || permission == Permission.OWNER) {
      const requestCreateTiles = CreateFullTiles;
      if (requestCreateTiles?.items && requestCreateTiles.items.length > 0) {
        requestCreateTiles.items[0].onClick = createTiles;
        menus.push(requestCreateTiles);
      }
      menus.push(AllocationUser);
    }

    const { modules } = currentProject;

    if (checkNodePermission(modules, 'Criar nós')) {
      menus.push(AddNode);
      menus.push(DeleteNode);
    }

    return menus;
  }, [permission, localStorageAdapter, createTiles]);

  return (
    <React.Fragment>
      <ContextMenu target={`.${nodeType}-${id}`} dataSource={dataSource()} />
      <NodeWrapper className={`${nodeType}-${id}`} icon={ICON} name={name} />
    </React.Fragment>
  );
};

const Component = memo(ProjectNode);
export { Component as ProjectNode };
