import React from 'react';
import { GrAdd } from 'react-icons/gr';
import { useDispatch } from 'react-redux';

import { Icon } from '@chakra-ui/react';

import { addProjectOpenModal } from '@/modules/project/state/actions';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';

const CreateProjectButton: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <ButtonSubmit onClick={() => dispatch(addProjectOpenModal())}>
      <Icon
        as={GrAdd}
        __css={{
          path: {
            stroke: 'var(--chakra-colors-whiteAlpha-900)',
          },
        }}
      />
    </ButtonSubmit>
  );
};

export { CreateProjectButton };
