import produce from 'immer';
import { Reducer } from 'redux';

import { AuthState, ActionTypes } from './types';

const INITIAL_STATE: AuthState = {
  token: '',
  user: {
    id: '',
    identity: '',
    name: '',
    identityType: '',
  },
  signed: false,
};

const auth: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.AUTH_SUCCESS: {
        draft.token = action.payload.data.token;
        draft.user = action.payload.data.user;
        draft.signed = true;
        break;
      }
      case ActionTypes.SIGN_OUT: {
        draft.token = '';
        draft.user = {
          id: '',
          identity: '',
          name: '',
          identityType: '',
        };
        draft.signed = false;
        break;
      }
      default:
    }
  });
};

export default auth;
