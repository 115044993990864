import { RootState, store } from '@/main/config/redux';
import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpRequest, HttpResponse } from '@/shared/presentation/contracts/http';

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(private readonly httpClient: HttpClient) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    const {
      auth,
      space: { currentSpace },
    } = store.getState() as RootState;

    if (auth?.token) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          Authorization: `Bearer ${auth.token}`,
        }),
      });
    }

    if (currentSpace.id) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          spaceId: currentSpace.id,
        }),
      });
    }

    const httpResponse = await this.httpClient.request(data);
    return httpResponse;
  }
}
