import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { Box } from '@chakra-ui/react';

import { CreateTask } from '@/modules/task/presentation/components/organisms/create-task';
import { makeMapVisualizationAdapter } from '@/shared/factories/make-map-visualization-adapter';

import { GeojsonPointLayer } from '../molecules/geojson-point-layer';
import { GeojsonPolygonLayer } from '../molecules/geojson-polygon-layer';

const MyMapView: React.FC = () => {
  const mapVisualizationAdapter = useMemo(() => makeMapVisualizationAdapter(), []);

  const displayMap = useCallback(async (): Promise<void> => {
    const map = await mapVisualizationAdapter.makeMap({ basemap: 'satellite' });

    const mapView = await mapVisualizationAdapter.makeViewMap({
      container: 'map-view',
      map: map,
      center: [-47.882778, -15.793889],
      zoom: 5,
    });

    await mapVisualizationAdapter.makeWidgetFullScreen({
      view: mapView,
    });
  }, [mapVisualizationAdapter]);

  useEffect(() => {
    displayMap();
  }, [displayMap]);

  return (
    <Box
      id="map-view"
      mt="1px"
      minH="calc(100vh - 191px)" //calc(100vh - 190px) 100px do header do dashboard e 90px da barra de navegação
      w="100%"
      position="relative"
    >
      <GeojsonPointLayer arcgisAdapter={mapVisualizationAdapter} />
      <GeojsonPolygonLayer arcgisAdapter={mapVisualizationAdapter} />
      <CreateTask arcgisAdapter={mapVisualizationAdapter} />
    </Box>
  );
};

const Component = memo(MyMapView);
export { Component as MapView };
