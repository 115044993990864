import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { makeCreateTruckUseCase } from '@/modules/truck/factories/make-create-truck-use-case';
import { makeEditTruckUseCase } from '@/modules/truck/factories/make-edit-truck-use-case';
import { TruckModel } from '@/modules/truck/models/truck';
import { addEditTruckCloseModal, setNewTruck, updateTruck } from '@/modules/truck/state/actions';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { TextError } from '@/shared/presentation/components/atoms/text-error';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type Props = {
  currentTruck: TruckModel;
};

type InputProps = {
  name: string;
};

const CreateEditTruckModal: React.FC<Props> = ({ currentTruck }: Props) => {
  const dispatch = useDispatch();

  const { addEditTruckIsOpenModal } = useSelector((state: RootState) => state.truck);

  const createTruckUseCase = useMemo(() => makeCreateTruckUseCase(), []);

  const editTruckUseCase = useMemo(() => makeEditTruckUseCase(), []);

  const [formData, setFormData] = useState<InputProps>({
    name: '',
  });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveNewTruck = useCallback(async () => {
    setIsLoading(true);
    try {
      const newTruck = await createTruckUseCase.execute(formData);
      if (newTruck) {
        dispatch(setNewTruck(newTruck));
      }
      dispatch(addEditTruckCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [createTruckUseCase, dispatch, formData]);

  const saveEditTruck = useCallback(async () => {
    setIsLoading(true);
    try {
      const editedTruck = await editTruckUseCase.execute({
        ...formData,
        id: currentTruck.id,
      });
      if (editedTruck) {
        dispatch(updateTruck(editedTruck));
      }
      dispatch(addEditTruckCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [currentTruck.id, dispatch, formData, editTruckUseCase]);

  const handlePressSave = useCallback(async (): Promise<void> => {
    if (!currentTruck.id) {
      await saveNewTruck();
    } else {
      await saveEditTruck();
    }
  }, [saveNewTruck, saveEditTruck, currentTruck]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(addEditTruckCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (addEditTruckIsOpenModal) {
      setFormData({ name: currentTruck.name });
      setIsLoading(false);
      setTextError('');
    }
  }, [addEditTruckIsOpenModal, currentTruck]);

  return (
    <Modal
      title={!currentTruck.id ? 'Cadastro de novo veiculo' : 'Edição de veiculo'}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addEditTruckIsOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-truck"
        label="Novo veiculo"
        onChange={(e) => setFormData((prevState) => ({ ...prevState, name: e.target.value }))}
        defaultValue={formData.name}
        type="text"
      />

      {textError && <TextError>{textError}</TextError>}
    </Modal>
  );
};

export { CreateEditTruckModal };
