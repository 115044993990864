import { TruckModel } from '../models/truck';

export enum ActionTypes {
  SET_TRUCKS = '@truck/SET_TRUCKS',
  SET_NEW_TRUCK = '@truck/SET_NEW_TRUCK',
  REMOVE_TRUCK = '@truck/REMOVE_TRUCK',
  UPDATE_TRUCK = '@truck/UPDATE_TRUCK',

  ADD_EDIT_TRUCK_OPEN_MODAL = '@truck/ADD_EDIT_TRUCK_OPEN_MODAL',
  ADD_EDIT_TRUCK_CLOSE_MODAL = '@truck/ADD_EDIT_TRUCK_CLOSE_MODAL',
}

export type TruckState = {
  trucks: TruckModel[];

  addEditTruckIsOpenModal: boolean;
};
