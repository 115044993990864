import { FieldValidation } from '../contracts/field-validation';
import { Validation } from '../contracts/validation';

export class ValidationComposite implements Validation {
  private constructor(private readonly validators: FieldValidation[]) {}

  static build(validators: FieldValidation[]): ValidationComposite {
    return new ValidationComposite(validators);
  }

  validate(fieldName: string, input: any): string {
    const validators = this.validators.filter((v) => v.field === fieldName);
    for (const validator of validators) {
      const error = validator.validate(input) as Error;
      if (error) {
        return error.message;
      }
    }

    return '';
  }
}
