import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { makeDeleteSpaceUseCase } from '@/modules/space/factories/make-delete-space-use-case';
import { removeSpaceCloseDialog } from '@/modules/space/state/actions';
import { AlertDialog } from '@/shared/presentation/components/atoms/alert-dialog';

const DeleteSpaceDialog: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { removeSpaceIsOpenDialog, currentSpace } = useSelector((state: RootState) => state.space);

  const deleteSpaceUseCase = useMemo(() => makeDeleteSpaceUseCase(), []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressConfirm = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      await deleteSpaceUseCase.execute({ spaceId: currentSpace.id });
      dispatch(removeSpaceCloseDialog(currentSpace.id));
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar apagar esse espaço. Tente novamente',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast, dispatch, deleteSpaceUseCase, currentSpace]);

  const handlePressCancel = useCallback(() => {
    dispatch(removeSpaceCloseDialog());
  }, [dispatch]);

  return (
    <AlertDialog
      isOpen={removeSpaceIsOpenDialog}
      onConfirm={handlePressConfirm}
      onCancel={handlePressCancel}
      cancelButtonText="Não"
      confirmButtonText="Sim"
      header="Deseja realmente remover esse espaço?"
      message="Se remover esse espaço, todos os dados vinculados a ele serão apagados"
      isLoading={isLoading}
    />
  );
};

export { DeleteSpaceDialog };
