import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useDispatch } from 'react-redux';

import { Icon } from '@chakra-ui/react';

import { addSpaceOpenModal } from '@/modules/space/state/actions';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';
import { Tooltip } from '@/shared/presentation/components/atoms/tooltip';

const CreateSpaceButton: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Tooltip label="Novo espaço" placement="right">
      <ButtonSubmit
        height="60px"
        width="60px"
        variant="solid"
        borderRadius="30%"
        fontSize="14"
        bgColor="blue.500"
        mr="5"
        ml="5"
        mb="19px"
        _hover={{
          borderRadius: '50%',
          transition: 'all 0.2s',
        }}
        onClick={() => dispatch(addSpaceOpenModal())}
      >
        <Icon as={IoIosAdd} w={8} h={8} color="whiteAlpha.900" />
      </ButtonSubmit>
    </Tooltip>
  );
};

export { CreateSpaceButton };
