import React from 'react';

import { Flex } from '@chakra-ui/react';

import { Permission } from '@/modules/space/models/space-permission';
import { Can } from '@/shared/presentation/components/atoms/can';

import { AddButton } from '../atoms/add-button';
import { HeaderTitleTable } from '../atoms/header-title-table';

type Props = {
  title: string;
  onClickAdd: () => void;
};

const HeaderTable: React.FC<Props> = ({ title, onClickAdd }: Props) => {
  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between" flexDir="row" p={2}>
      <HeaderTitleTable text={title} />
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <AddButton onClick={onClickAdd} />
      </Can>
    </Flex>
  );
};

export { HeaderTable };
