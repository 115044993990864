import React from 'react';

import { Grid } from '@chakra-ui/react';

import { Equipment } from '@/modules/equipment/presentation/compononents/organisms/equipment';
import { Motorcycle } from '@/modules/motorcycle/presentation/components/organisms/motorcycle';
import { Truck } from '@/modules/truck/presentation/components/organisms/truck';

const EquipmentVehicle: React.FC = () => {
  return (
    <Grid templateRows="repeat(2, 40%)" templateColumns="repeat(2, 1fr)" height="calc(100vh - 90px)" gap={4} w="100%">
      <Motorcycle />
      <Truck />
      <Equipment />
    </Grid>
  );
};

export { EquipmentVehicle };
