import React from 'react';
import { useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/layout';

import { RootState } from '@/main/config/redux';
import { Management } from '@/modules/management-tree/presentation/components/organisms/management';

import { DashboardHeader } from '../molecules/dashboard-header';

const Dashboard: React.FC = () => {
  const { currentSpace } = useSelector((state: RootState) => state.space);

  return currentSpace.id ? (
    <Flex flex="1" alignItems="flex-start" flexDir="column" minW="100%" maxW="100%">
      <DashboardHeader />
      <Flex minW="100%" maxW="100%">
        <Management />
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};

export { Dashboard };
