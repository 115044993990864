import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ICreateNode } from './contracts/create-node';

export class CreateNodeUseCase implements ICreateNode {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ICreateNode.Result>) {}

  async execute(params: ICreateNode.Params): Promise<ICreateNode.Result | undefined> {
    const { parentId, name, nodeType, currentNodeType } = params;

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        parentId,
        name,
        nodeType,
        currentNodeType,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
