import { action } from 'typesafe-actions';

import { ManagementTreeModel } from '../models/management-tree';
import { ActionTypes } from './types';

export const updateNode = (node: ManagementTreeModel | null) => action(ActionTypes.UPDATE_NODE, { node });
export const removeNode = (id: string | null) => action(ActionTypes.REMOVE_NODE, { id });
export const addNode = (node: ManagementTreeModel | null) => action(ActionTypes.ADD_NODE, { node });

export const addOrEditNodeOpenModal = (type: 'edit' | 'create') =>
  action(ActionTypes.ADD_EDIT_NODE_OPEN_MODAL, { type });
export const addOrEditNodeCloseModal = () => action(ActionTypes.ADD_EDIT_NODE_CLOSE_MODAL);

export const removeNodeOpenDialog = () => action(ActionTypes.REMOVE_NODE_OPEN_DIALOG);
export const removeNodeCloseDialog = () => action(ActionTypes.REMOVE_NODE_CLOSE_DIALOG);

export const uploadZipOpenModal = () => action(ActionTypes.UPLOAD_ZIP_OPEN_MODAL);
export const uploadZipCloseModal = () => action(ActionTypes.UPLOAD_ZIP_CLOSE_MODAL);

export const exportNodeOpenModal = () => action(ActionTypes.EXPORT_NODE_OPEN_MODAL);
export const exportNodeCloseModal = () => action(ActionTypes.EXPORT_NODE_CLOSE_MODAL);
