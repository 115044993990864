import React from 'react';

import { Flex } from '@chakra-ui/react';

import { DateBox } from 'devextreme-react';

import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

type Props = {
  onValueChange: (e: Date) => void;
  textError?: string;
};

const InputExpireDate: React.FC<Props> = ({ onValueChange, textError }: Props) => {
  return (
    <Flex flexDir="column" mb={1} alignItems="flex-start">
      <FormLabel>Data de expiração</FormLabel>
      <DateBox
        type="date"
        min={new Date()}
        defaultValue={new Date()}
        pickerType="calendar"
        width={300}
        onValueChange={(e) => onValueChange(new Date(e))}
      />
      <TextError>{textError}</TextError>
    </Flex>
  );
};

export { InputExpireDate };
