import { FieldValidation } from '../contracts/field-validation';
import { ArrayNotEmptyError } from '../errors/array-not-empy';

export class ArrayNotEmptyValidation implements FieldValidation {
  constructor(readonly field: string) {}

  validate(input: any): Error | null {
    return input[this.field].length > 0 ? null : new ArrayNotEmptyError();
  }
}
