import React, { useCallback, useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/toast';

import { makeSendInviteUseCaseUseCase } from '@/modules/invite/factories/make-send-invite-use-case';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type InputProps = {
  identity: string;
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const SendInviteModal: React.FC<Props> = ({ isOpen, closeModal }: Props) => {
  const toast = useToast();

  const [formData, setFormData] = useState<InputProps>({
    identity: '',
  });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetFormData = useCallback((): void => {
    setFormData({
      identity: '',
    });
  }, []);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const sendInviteUseCaseUseCase = makeSendInviteUseCaseUseCase();
      await sendInviteUseCaseUseCase.execute(formData);
      toast({
        position: 'top-right',
        duration: 3000,
        status: 'success',
        title: 'Convite enviado com sucesso',
      });
      closeModal();
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [formData, closeModal, toast]);

  const handlePressCancel = useCallback((): void => {
    resetFormData();
    closeModal();
  }, [closeModal, resetFormData]);

  useEffect(() => {
    if (isOpen) {
      resetFormData();
      setIsLoading(false);
      setTextError('');
    }
  }, [isOpen, resetFormData]);

  return (
    <Modal
      title="Enviar convite"
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={isOpen}
      isLoading={isLoading}
    >
      <InputForm
        id="identity"
        label="Email ou celular"
        onChange={(e) =>
          setFormData((prevState) => ({
            ...prevState,
            identity: e.target.value,
          }))
        }
        textError={textError}
        type="text"
      />
    </Modal>
  );
};

export { SendInviteModal };
