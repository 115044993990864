import { action } from 'typesafe-actions';

import { TruckModel } from '../models/truck';
import { ActionTypes } from './types';

export const setTrucks = (trucks: TruckModel[]) => action(ActionTypes.SET_TRUCKS, { trucks });

export const addEditTruckOpenModal = () => action(ActionTypes.ADD_EDIT_TRUCK_OPEN_MODAL);

export const addEditTruckCloseModal = () => action(ActionTypes.ADD_EDIT_TRUCK_CLOSE_MODAL);

export const setNewTruck = (truck: TruckModel) => action(ActionTypes.SET_NEW_TRUCK, { truck });

export const removeTruck = (id: string) => action(ActionTypes.REMOVE_TRUCK, { id });

export const updateTruck = (truck: TruckModel) => action(ActionTypes.UPDATE_TRUCK, { truck });
