import React from 'react';
import ReactDOM from 'react-dom';

import './main/config/reactotron';
import 'devextreme/dist/css/dx.material.dark-green.css';
import Router from '@/main/routes/router';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root'),
);
