import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RiDeleteBin7Fill } from 'react-icons/ri';

import { Box, Table, Thead, Tbody, Th, Td, Text, Tr, Icon, Button } from '@chakra-ui/react';

import { InputText } from '@/shared/presentation/components/atoms/input-text';

type FieldObject = {
  [key: string]: string;
};

type Props = {
  fields: string[];
  onChangeField: (fieldObject: FieldObject) => void;
};

const TableRenameFields: React.FC<Props> = ({ fields, onChangeField }: Props) => {
  const fieldsObject = useMemo((): FieldObject => {
    const obj: FieldObject = {};

    fields.forEach((field) => {
      obj[field] = field;
    });

    return obj;
  }, [fields]);

  const [newFields, setNewFields] = useState<FieldObject>(fieldsObject);

  const onChange = useCallback((key: string, value: string) => {
    setNewFields((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onClick = useCallback(
    (key: string) => {
      const copyFields = { ...newFields };
      delete copyFields[key];
      setNewFields(copyFields);
    },
    [newFields],
  );

  useEffect(() => {
    if (newFields !== fieldsObject) {
      onChangeField(newFields);
    }
  }, [onChangeField, fieldsObject, newFields]);

  return (
    <Box borderWidth={1} borderColor="white" borderRadius="8" padding="5">
      <Text fontWeight="bold" fontSize="18" color="whiteAlpha.800">
        Renomeie ou delete as colunas do shape abaixo
      </Text>

      <Table marginTop="2" colorScheme="whiteAlpha" variant="simple">
        <Thead>
          <Tr>
            <Th>Novo nome</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(newFields).map((key) => (
            <Tr key={key}>
              <Td>
                <InputText
                  name="new-name"
                  type="text"
                  value={newFields[key]}
                  onChange={(e) => onChange(key, e.target.value)}
                />
              </Td>
              <Td>
                <Button size="sm" fontSize="sm" colorScheme="red" onClick={() => onClick(key)}>
                  <Icon as={RiDeleteBin7Fill} fontSize="15" />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export { TableRenameFields };
