import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Flex, Stack, Image } from '@chakra-ui/react';

import { BackgroundImage } from '@/modules/sign-in/presentation/components/atoms/background-image';
import { setCurrentAuth } from '@/modules/sign-in/state/actions';
import { ISignIn } from '@/modules/sign-in/use-cases/contracts/sign-in';
import { ICreateUser } from '@/modules/sign-up/use-cases/contracts/create-user';
import { IGetInvite } from '@/modules/sign-up/use-cases/contracts/get-invite';
import { FormHeading } from '@/shared/presentation/components/atoms/form-heading';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';
import { Validation } from '@/shared/validation/contracts/validation';

import { Footer } from '../molecules/footer';

type Inputs = {
  name: string;
  identity: string;
  password: string;
  passwordConfirmation: string;
  inviteId: string;
};

type Props = {
  validation: Validation;
  createUserUseCase: ICreateUser;
  getInviteUseCase: IGetInvite;
  signInUseCase: ISignIn;
};

type Validate = {
  nameValidate: string;
  passwordValidate: string;
  passwordConfirmationValidate: string;
  identityValidate: string;
  isFormInvalid: boolean;
};

type FormErros = Omit<Validate, 'isFormInvalid'>;

type ParamsProps = {
  id: string;
};

const SignUp: React.FC<Props> = ({ validation, createUserUseCase, getInviteUseCase, signInUseCase }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: inviteId } = useParams<ParamsProps>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVerificationInvite, setIsVerificationInvite] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErros>({
    nameValidate: '',
    passwordValidate: '',
    passwordConfirmationValidate: '',
    identityValidate: '',
  });
  const [mainError, setMainError] = useState('');
  const [formData, setFormData] = useState<Inputs>({
    name: '',
    password: '',
    passwordConfirmation: '',
    identity: '',
    inviteId: '',
  });

  const validateForm = useCallback((): Validate => {
    const nameValidate = validation.validate('name', formData);
    const passwordValidate = validation.validate('password', formData);
    const passwordConfirmationValidate = validation.validate('passwordConfirmation', formData);
    const identityValidate = validation.validate('identity', formData);

    let isFormInvalid = true;

    if (!nameValidate && !passwordValidate && !passwordConfirmationValidate && !identityValidate) {
      isFormInvalid = false;
    }

    return {
      isFormInvalid,
      nameValidate,
      passwordValidate,
      passwordConfirmationValidate,
      identityValidate,
    };
  }, [formData, validation]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const validate = validateForm();

    if (isLoading || validate.isFormInvalid) {
      setFormErrors({ ...validate });
      return;
    }
    try {
      setIsLoading(true);
      const user = await createUserUseCase.execute({
        inviteId: formData.inviteId,
        name: formData.name,
        password: formData.password,
        passwordConfirmation: formData.passwordConfirmation,
        identity: formData.identity,
      });

      if (user) {
        const auth = await signInUseCase.execute({
          identity: formData.identity,
          password: formData.password,
        });
        if (auth) {
          dispatch(setCurrentAuth(auth));
          history.replace('/');
        }
      }
    } catch (error: any) {
      setMainError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, validateForm, dispatch, formData, history, createUserUseCase, signInUseCase]);

  const resetState = useCallback((): void => {
    setIsLoading(false);
    setFormErrors({
      nameValidate: '',
      passwordValidate: '',
      passwordConfirmationValidate: '',
      identityValidate: '',
    });
    setMainError('');
    setFormData({
      name: '',
      password: '',
      passwordConfirmation: '',
      identity: '',
      inviteId: inviteId,
    });
  }, [inviteId]);

  useEffect(() => resetState(), [resetState]);

  useEffect(() => {
    (async () => {
      if (inviteId) {
        setIsVerificationInvite(true);

        try {
          const invite = await getInviteUseCase.execute(inviteId);

          if (invite) {
            setFormData((prevState) => ({
              ...prevState,
              identity: invite.identity,
            }));
          }
        } catch (error) {
          //convite invalido
        } finally {
          setIsVerificationInvite(false);
        }
      }
    })();
  }, [inviteId, history, getInviteUseCase]);

  return (
    <Flex minH={'100vh'} minW={'100vw'} direction={{ base: 'column', md: 'row' }}>
      {isVerificationInvite ? (
        <Spinner />
      ) : formData.identity ? (
        <>
          <Flex flex={1}>
            <BackgroundImage />
          </Flex>
          <Flex p="8" flex={1} align={'center'} justify={'center'} backgroundColor="gray.900">
            <Stack spacing={4} w={'full'} maxW={'md'} bgColor="gray.800" padding={8} borderRadius="xl">
              <FormHeading fontSize={'2xl'}>Crie sua conta</FormHeading>

              <InputForm
                textError={formErrors.nameValidate}
                id="name"
                label="Nome"
                type="text"
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />

              <InputForm
                textError={formErrors.identityValidate}
                id="identity"
                label="Email/Celular"
                type="text"
                defaultValue={formData.identity}
                isDisabled={true}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    identity: e.target.value,
                  }))
                }
              />

              <InputForm
                textError={formErrors.passwordValidate}
                id="password"
                label="Senha"
                type="password"
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
              />

              <InputForm
                textError={formErrors.passwordConfirmationValidate}
                id="password-confirmation"
                label="Repita a senha"
                type="password"
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    passwordConfirmation: e.target.value,
                  }))
                }
              />

              <Footer isLoading={isLoading} handleSubmit={handleSubmit} mainError={mainError} />
            </Stack>
          </Flex>
        </>
      ) : (
        <Flex h="100vh" w="100vw" alignItems="center" justifyContent="center">
          <Image src="../page_not_found.svg" objectFit="fill" />
        </Flex>
      )}
    </Flex>
  );
};

export { SignUp };
