import { action } from 'typesafe-actions';

import turf from '@turf/turf';

import { ActionTypes } from './types';

export const setPolygon = (polygon: turf.FeatureCollection & { id: string; name: string }) =>
  action(ActionTypes.SET_POLYGON, { polygon });

export const setPoint = (point: turf.FeatureCollection & { id: string; name: string }) =>
  action(ActionTypes.SET_POINT, { point });
