import produce from 'immer';
import { Reducer } from 'redux';

import { MotorcycleState, ActionTypes } from './types';

const INITIAL_STATE: MotorcycleState = {
  motorcycles: [],

  addEditMotorcycleIsOpenModal: false,
};

const motorcycle: Reducer<MotorcycleState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_MOTORCYCLES: {
        draft.motorcycles = action.payload.motorcycles;
        break;
      }
      case ActionTypes.ADD_EDIT_MOTORCYCLE_OPEN_MODAL: {
        draft.addEditMotorcycleIsOpenModal = true;
        break;
      }
      case ActionTypes.ADD_EDIT_MOTORCYCLE_CLOSE_MODAL: {
        draft.addEditMotorcycleIsOpenModal = false;
        break;
      }
      case ActionTypes.SET_NEW_MOTORCYCLE: {
        draft.motorcycles.push(action.payload.motorcycle);
        break;
      }
      case ActionTypes.REMOVE_MOTORCYCLE: {
        draft.motorcycles = draft.motorcycles.filter((motorcycle) => motorcycle.id !== action.payload.id);
        break;
      }
      case ActionTypes.UPDATE_MOTORCYCLE: {
        draft.motorcycles = draft.motorcycles.map((motorcycle) =>
          motorcycle.id === action.payload.motorcycle.id ? { ...action.payload.motorcycle } : motorcycle,
        );
        break;
      }
      default:
    }
  });
};

export default motorcycle;
