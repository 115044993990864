import React, { useState } from 'react';

import { InputProps, InputRightElement } from '@chakra-ui/input';

import { ContainerInput } from '../atoms/container-input';
import { InputText } from '../atoms/input-text';
import { RightIconPasswordInput } from '../atoms/right-icon-password-input';

const InputPassword: React.FC<InputProps> = (props: InputProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <ContainerInput>
      <InputText type={passwordVisible ? 'text' : 'password'} {...props} />
      <InputRightElement>
        <RightIconPasswordInput
          passwordVisible={passwordVisible}
          onClick={() => setPasswordVisible((prevState) => !prevState)}
        />
      </InputRightElement>
    </ContainerInput>
  );
};

export { InputPassword };
