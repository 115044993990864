import React, { memo } from 'react';

import { Divider, Flex } from '@chakra-ui/layout';

import { CreateSpaceButton } from '@/modules/space/presentation/components/atoms/create-space-button';
import { SpaceSwitcher } from '@/modules/space/presentation/components/molecules/space-switcher';

const LeftBar: React.FC = () => {
  return (
    <Flex
      flex="1"
      alignItems="flex-start"
      justifyContent="flex-start"
      w="110px"
      maxW="110px"
      minHeight="100%"
      flexDir="column"
      m={0}
      p={0}
      bgColor="gray.800"
    >
      <CreateSpaceButton />
      <Divider borderColor="whiteAlpha.900" />
      <SpaceSwitcher />
    </Flex>
  );
};

const Component = memo(LeftBar);
export { Component as LeftBar };
