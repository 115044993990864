import React, { memo, useCallback, useMemo } from 'react';
import { CgMenuGridO } from 'react-icons/cg';
import { useDispatch } from 'react-redux';

import { Icon } from '@chakra-ui/react';

import ContextMenu from 'devextreme-react/context-menu';

import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { DatasourceContextMenu } from '@/modules/management-tree/presentation/types/datasource-context-meny';
import { checkNodePermission } from '@/modules/management-tree/use-cases/check-node-permission';
import { makeGetFeaturesUseCase } from '@/modules/map-view/factories/make-get-features-use-case';
import { setPoint } from '@/modules/map-view/state/actions';
import { NodeType } from '@/modules/project/models/project';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { NodeWrapper } from '../node-wrapper';
import { AddPointToMap, DeleteNode, ExportNodePoint } from './context-menu-options';

type Props = {
  id: string;
  name: string;
  nodeType: NodeType;
};

const ICON = (
  <Icon
    as={CgMenuGridO}
    __css={{
      path: {
        stroke: 'var(--chakra-colors-whiteAlpha-900)',
      },
    }}
    w="18px"
    h="18px"
  />
);

const PointNode: React.FC<Props> = ({ id, name, nodeType }: Props) => {
  const dispatch = useDispatch();

  const getFeaturesUseCase = useMemo(() => makeGetFeaturesUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const loadPoint = useCallback(async () => {
    const currentNode = localStorageAdapter.getItem('currentNode') as ManagementTreeModel;
    const features = await getFeaturesUseCase.execute({ id: currentNode.id });
    if (features) {
      dispatch(setPoint({ id: currentNode.id, name: currentNode.name, ...features }));
    }
  }, [getFeaturesUseCase, dispatch, localStorageAdapter]);

  const dataSource = useCallback(() => {
    const currentNode = localStorageAdapter.getItem('currentNode') as ManagementTreeModel;
    const pointToMap = AddPointToMap;
    pointToMap.onClick = loadPoint;

    const menus: DatasourceContextMenu[] = [pointToMap];

    const { modules } = currentNode;

    if (checkNodePermission(modules, 'Criar nós')) {
      menus.push(DeleteNode);
      menus.push(ExportNodePoint);
    }

    return menus;
  }, [loadPoint, localStorageAdapter]);

  return (
    <React.Fragment>
      <ContextMenu target={`.${nodeType}-${id}`} dataSource={dataSource()} />
      <NodeWrapper className={`${nodeType}-${id}`} icon={ICON} name={name} />
    </React.Fragment>
  );
};

const Component = memo(PointNode);
export { Component as PointNode };
