import produce from 'immer';
import { Reducer } from 'redux';

import { TaskState, ActionTypes } from './types';

const INITIAL_STATE: TaskState = {
  createTaskIsOpenModal: false,
};

const task: Reducer<TaskState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.CREATE_TASK_CLOSE_MODAL: {
        draft.createTaskIsOpenModal = false;
        break;
      }
      case ActionTypes.CREATE_TASK_OPEN_MODAL: {
        draft.createTaskIsOpenModal = true;
        break;
      }
      default:
        break;
    }
  });
};

export default task;
