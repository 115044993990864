import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';

import { Flex, Stack, useToast } from '@chakra-ui/react';

import { IForgotPassword } from '@/modules/forgot-passowrd/use-cases/contracts/forgot-password';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';
import { FormHeading } from '@/shared/presentation/components/atoms/form-heading';
import { MyLink } from '@/shared/presentation/components/atoms/link';
import { TextError } from '@/shared/presentation/components/atoms/text-error';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';
import { Validation } from '@/shared/validation/contracts/validation';

type Props = {
  validation: Validation;
  forgotPasswordUseCase: IForgotPassword;
};

type Inputs = {
  identity: string;
};

type Validate = {
  identityValidate: string;
  isFormInvalid: boolean;
};

export const ForgotPassword: React.FC<Props> = ({ forgotPasswordUseCase, validation }: Props) => {
  const history = useHistory();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mainError, setMainError] = useState<string>('');
  const [identityError, setIdentityError] = useState<string>('');

  const [formData, setFormData] = useState<Inputs>({ identity: '' });

  const validateForm = useCallback((): Validate => {
    const identityValidate = validation.validate('password', formData);

    return {
      isFormInvalid: !!identityValidate,
      identityValidate,
    };
  }, [formData, validation]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const { isFormInvalid, identityValidate } = validateForm();

    try {
      if (isLoading || isFormInvalid) {
        setIdentityError(identityValidate);
        return;
      }
      setIsLoading(true);
      await forgotPasswordUseCase.execute({
        identity: formData.identity,
      });

      toast({
        title: 'As instruções para redefinição de senha foram enviadas para seu email/celular.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      history.replace('/sign-in');
    } catch (error: any) {
      setMainError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [history, isLoading, formData, validateForm, forgotPasswordUseCase, toast]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.900'} flexDir="column">
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={'gray.800'}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
        flexDir="column"
      >
        <FormHeading fontSize="lg">Esqueceu sua senha</FormHeading>

        <InputForm
          textError={identityError}
          id="identity"
          label="E-mail/Celular"
          type="text"
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              identity: e.target.value,
            }))
          }
        />

        <ButtonSubmit isLoading={isLoading} disabled={isLoading} onClick={handleSubmit}>
          Solicitar redefinição
        </ButtonSubmit>

        <MyLink to="/sign-in">Voltar</MyLink>
        <TextError>{mainError}</TextError>
      </Stack>
    </Flex>
  );
};
