import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdDelete } from 'react-icons/md';

import { Button, Flex, HStack, Text, useToast, VStack } from '@chakra-ui/react';

import SelectBox from 'devextreme-react/select-box';

import { makeDeleteUserSpacePermissionUseCase } from '@/modules/space/factories/make-delete-user-space-permission-use-case';
import { makeLoadSpacePermissionsUseCase } from '@/modules/space/factories/make-load-space-permissions-use-case';
import { makeLoadUsersUseCase } from '@/modules/space/factories/make-load-users-use-case';
import { makeUpdateUserSpacePermissionUseCase } from '@/modules/space/factories/make-update-user-space-permission-use-case';
import { SpacePermission } from '@/modules/space/models/space-permission';
import { ILoadUsers } from '@/modules/space/use-cases/contracts/load-users';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

type Permission = {
  id: string;
  label: string;
};

const permissionAlias = {
  OWNER: 'DONO',
  ADMINISTRATOR: 'ADMINISTRADOR',
  USER: 'COLABORADOR',
};

const UsersSpaceModal: React.FC<Props> = ({ isOpen, closeModal }: Props) => {
  const toast = useToast();

  const updateUserSpacePermissionUseCase = useMemo(() => {
    return makeUpdateUserSpacePermissionUseCase();
  }, []);

  const loadUsersUseCase = useMemo(() => {
    return makeLoadUsersUseCase();
  }, []);

  const loadSpacePermissionsUseCase = useMemo(() => {
    return makeLoadSpacePermissionsUseCase();
  }, []);

  const deleteUserSpacePermissionUseCase = useMemo(() => makeDeleteUserSpacePermissionUseCase(), []);

  const [users, setUsers] = useState<ILoadUsers.Result>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const [isLoadingLoad, setIsLoadingLoad] = useState<boolean>(false);

  const onValueChanged = useCallback(
    async (permission: SpacePermission, userId: string): Promise<void> => {
      await updateUserSpacePermissionUseCase.execute({ permission, userId });
    },
    [updateUserSpacePermissionUseCase],
  );

  const removeUser = useCallback(
    async (userId: string) => {
      try {
        await deleteUserSpacePermissionUseCase.execute({ userId });
        setUsers((prevState) => prevState.filter((user) => user.id !== userId));
      } catch (e) {
        //console.log(e);
      }
    },
    [deleteUserSpacePermissionUseCase],
  );

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setIsLoadingLoad(true);
        try {
          const responseUsers = await loadUsersUseCase.execute();

          if (responseUsers && responseUsers.length > 0) {
            setUsers(responseUsers);
          }

          const responsePermissions = await loadSpacePermissionsUseCase.execute();

          if (responsePermissions && responsePermissions.length > 0) {
            setPermissions(
              responsePermissions.map((permission) => ({
                id: permission,
                //@ts-ignore
                label: permissionAlias[permission],
              })),
            );
          }
        } catch (error) {
          toast({
            title: 'Ocorreu um erro ao tentar listar usuario/permissões',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } finally {
          setIsLoadingLoad(false);
        }
      }
    })();
  }, [isOpen, toast, loadSpacePermissionsUseCase, loadUsersUseCase]);

  return (
    <Modal
      title="Permissões dos usuários"
      handlePressCancel={closeModal}
      visible={isOpen}
      isLoading={false}
      size="lg"
      buttonsVisible={false}
    >
      {!isLoadingLoad ? (
        <VStack spacing={'4'} alignItems={'flex-start'}>
          {users.map((user) => (
            <Flex w="100%" key={user.id} alignItems="center" justifyContent="space-between">
              <Text fontWeight="semi-bold" color="whiteAlpha.900">
                {user.name}
              </Text>
              <HStack spacing={2}>
                <SelectBox
                  items={permissions}
                  defaultValue={user.permission}
                  valueExpr="id"
                  displayExpr="label"
                  stylingMode="filled"
                  onValueChanged={(e) => onValueChanged(e.value, user.id)}
                />
                <Button
                  bg="transparent"
                  onClick={() => removeUser(user.id)}
                  _hover={{
                    bgColor: 'whiteAlpha.200',
                  }}
                >
                  <MdDelete color="#fff" size={20} />
                </Button>
              </HStack>
            </Flex>
          ))}
        </VStack>
      ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
    </Modal>
  );
};

export { UsersSpaceModal };
