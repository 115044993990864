import React, { memo } from 'react';

import { TreeViewData } from '../../../types/tree-view-item';
import { GenericNode } from './generic-node';
import { PointNode } from './point-node';
import { PolygonNode } from './polygon-node';
import { ProjectNode } from './project-node';
import { SamplingNode } from './sampling-node';
import { YearNode } from './year-node';

type Props = {
  data: TreeViewData;
};

const NodeType: React.FC<Props> = ({ data }: Props) => {
  switch (data.nodeType) {
    case 'project':
      return <ProjectNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    case 'YEAR':
      return <YearNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    case 'GENERIC':
      return <GenericNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    case 'SAMPLING':
      return <SamplingNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    case 'POLYGON':
      return <PolygonNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    case 'POINT':
      return <PointNode id={data.id} name={data.name} nodeType={data.nodeType} />;
    default:
      return null;
  }
};

const Component = memo(NodeType);
export { Component as NodeType };
