import { action } from 'typesafe-actions';

import { SpaceModel } from '../models/space';
import { ActionTypes } from './types';

export const setSpaces = (spaces: SpaceModel[]) => action(ActionTypes.SET_SPACES, { spaces });

export const setCurrentSpace = (space: SpaceModel) => action(ActionTypes.SET_CURRENT_SPACE, { space });

export const addSpaceOpenModal = () => action(ActionTypes.ADD_SPACE_OPEN_MODAL);

export const addSpaceCloseModal = () => action(ActionTypes.ADD_SPACE_CLOSE_MODAL);

export const addNewSpace = (newSpace: SpaceModel) => action(ActionTypes.ADD_NEW_SPACE, { newSpace });

export const removeSpaceOpenDialog = () => action(ActionTypes.REMOVE_SPACE_OPEN_DIALOG);
export const removeSpaceCloseDialog = (spaceId?: string) => action(ActionTypes.REMOVE_SPACE_CLOSE_DIALOG, { spaceId });

export const updateSpaceOpenModal = () => action(ActionTypes.UPDATE_SPACE_OPEN_MODAL);
export const updateSpaceCloseModal = (space?: { spaceId: string; name: string; slug: string }) =>
  action(ActionTypes.UPDATE_SPACE_CLOSE_MODAL, { space });
