import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

// import { Container } from './styles';

const ContenteSpinner: React.FC<FlexProps> = ({ children }: FlexProps) => {
  return (
    <Flex w="100%" alignItems="center" justifyContent="center">
      {children}
    </Flex>
  );
};

export { ContenteSpinner };
