import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { EquipmentState } from '@/modules/equipment/state/types';
import { ManagementTreeState } from '@/modules/management-tree/state/types';
import { MapViewState } from '@/modules/map-view/state/types';
import { MotorcycleState } from '@/modules/motorcycle/state/types';
import { ProjectState } from '@/modules/project/state/types';
import { AuthState } from '@/modules/sign-in/state/types';
import { SpaceState } from '@/modules/space/state/types';
import { TaskState } from '@/modules/task/state/types';
import { TruckState } from '@/modules/truck/state/types';

import createStore from './create-store';
import persistReducers from './persist-reducers';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

export interface RootState {
  auth: AuthState;
  space: SpaceState;
  project: ProjectState;
  managementTree: ManagementTreeState;
  mapView: MapViewState;
  equipment: EquipmentState;
  truck: TruckState;
  motorcycle: MotorcycleState;
  task: TaskState;
}

const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
