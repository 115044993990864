import React from 'react';

import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';

type Props = {
  onChange: (value: number) => void;
};

import { FormLabel } from '@/shared/presentation/components/atoms/form-label';

const MaxDistanceInput: React.FC<Props> = ({ onChange }: Props) => {
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <FormLabel>Distancia máxima do ponto (metros)</FormLabel>
      <NumberInput
        defaultValue={10}
        min={10}
        max={200}
        focusBorderColor="green.500"
        color="whiteAlpha.900"
        w={90}
        onChange={(valueAsString: string, valueAsNumber: number) => onChange(valueAsNumber)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
};

export { MaxDistanceInput };
