import React, { useLayoutEffect, useState } from 'react';

import { Image } from '@chakra-ui/image';

const BackgroundImage: React.FC = () => {
  const [urlImage, setUrlImage] = useState('');

  useLayoutEffect(() => {
    const images = [
      '01.jpg',
      '02.jpg',
      '03.jpg',
      '04.jpg',
      '05.jpg',
      '06.jpg',
      '07.jpg',
      '08.jpg',
      '09.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
    ];

    const numeroImagem = Math.floor(Math.random() * images.length);
    const url = `https://storage.googleapis.com/imagens-argus-amostragem/Imagens%20Argus/${images[numeroImagem]}`;
    setUrlImage(url);
  }, []);

  return <Image alt={'Login Image'} objectFit={'cover'} src={urlImage} />;
};

export { BackgroundImage };
