import React, { ReactChild, ReactChildren } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

type Props = {
  children: ReactChild[] | ReactChildren[] | ReactChild | ReactChildren;
};

import { store, persistor } from '../../config/redux';

const ReduxProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

export { ReduxProvider };
