import React, { ReactChild, ReactChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';

type Props = {
  checkRole: string[];
  typeCheck: 'space' | 'project';
  children: ReactChild[] | ReactChildren[] | ReactChild | ReactChildren;
};

const Can: React.FC<Props> = ({ children, checkRole, typeCheck }: Props) => {
  const { currentSpace } = useSelector((state: RootState) => state.space);

  const isChecked = useMemo(() => {
    if (typeCheck === 'space') {
      const check = checkRole.includes(currentSpace.permission);

      if (check) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [currentSpace, typeCheck, checkRole]);

  return isChecked ? <>{children}</> : null;
};

export { Can };
