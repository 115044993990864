import React from 'react';

import { SelectBox as DXSelectBox } from 'devextreme-react';

type Props = {
  items?: {
    id: string;
    label: string;
  }[];

  onValueChanged: (e: string) => void;
  width?: number;
  defaultValue?: string;
};

const SelectBox: React.FC<Props> = ({ onValueChanged, items = [], width = 300, defaultValue }: Props) => {
  return (
    <DXSelectBox
      stylingMode="filled"
      items={items}
      valueExpr="id"
      displayExpr="label"
      placeholder=""
      width={width}
      defaultValue={defaultValue}
      onValueChanged={(e) => onValueChanged(String(e.value))}
    />
  );
};

export { SelectBox };
