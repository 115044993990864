import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IDeleteTruck } from './contracts/delete-truck';

export class DeleteTruckUseCase implements IDeleteTruck {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IDeleteTruck.Result>) {}

  async execute(params: IDeleteTruck.Params): Promise<IDeleteTruck.Result | undefined> {
    const { id } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      default:
        throw new UnexpectedError();
    }
  }
}
