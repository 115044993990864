import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetUserUseCase } from '@/modules/sign-in/factories/make-get-user-use-case';
import { setCurrentAuth, signOut } from '@/modules/sign-in/state/actions';
import { IGetUser } from '@/modules/sign-in/use-cases/contracts/get-user';
import { CheckAuth } from '@/shared/presentation/components/atoms/check-auth';

import { RootState } from '../redux';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { signed } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserUseCase = useMemo(() => makeGetUserUseCase(), []);

  const checkAuthError = useCallback(() => {
    setIsLoading(false);
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    async function checkAuth() {
      setIsLoading(true);
      try {
        let response: IGetUser.Result | null;
        try {
          response = await getUserUseCase.execute();
        } catch (error) {
          response = null;
        }

        if (response) {
          const { statusCode } = response;
          if (statusCode !== 200) {
            checkAuthError();
          }
        } else {
          checkAuthError();
        }
      } catch (error) {
        checkAuthError();
      } finally {
        setIsLoading(false);
      }
    }
    if (signed) checkAuth();
  }, [signed, checkAuthError, getUserUseCase]);

  return isLoading ? <CheckAuth /> : <>{children}</>;
};

export default AuthProvider;
