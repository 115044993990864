import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';

type Props = LinkProps & ChakraLinkProps;

const MyLink: React.FC<Props> = ({ children, ...rest }: Props) => {
  return (
    <ChakraLink as={Link} color={'whiteAlpha.700'} {...rest}>
      {children}
    </ChakraLink>
  );
};

export { MyLink };
