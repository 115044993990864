import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './css/list-tasks.css';

import { Flex, Tag } from '@chakra-ui/react';

import DataGrid, { Column, Editing, Texts } from 'devextreme-react/data-grid';

import { RootState } from '@/main/config/redux';
import { TreeViewData } from '@/modules/management-tree/presentation/types/tree-view-item';
import { makeDeleteUserTaskUseCase } from '@/modules/project/factories/make-delete-user-task-use-case';
import { makeLoadTasksUseCase } from '@/modules/project/factories/make-load-tasks-use-case';
import { listTasksCloseModal } from '@/modules/project/state/actions';
import { ILoadTasks } from '@/modules/project/use-cases/contracts/load-tasks';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

type Priority = {
  [key: ILoadTasks.Priority]: string;
};

export const PRIORITY_LABEL: Priority = {
  LOW: 'BAIXA',
  MEDIUM: 'MÉDIA',
  HIGH: 'ALTA',
};

export const PRIORITY_COLOR: Priority = {
  LOW: 'blue.500',
  MEDIUM: 'orange.500',
  HIGH: 'red.500',
};

type Status = {
  [key: ILoadTasks.StatusUserTask]: string;
};

export const STATUS_LABEL: Status = {
  SENDING: 'ENVIADO',
  DOWNLOADED: 'BAIXADO',
  IN_PROGRESS: 'EM ANDAMENTO',
  DONE: 'FINALIZADO',
  CANCELED: 'CANCELADO',
};

const ListTasks: React.FC = () => {
  const dispatch = useDispatch();

  const { listTaksIsOpenModal } = useSelector((state: RootState) => state.project);

  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);
  const loadTasksUseCase = useMemo(() => makeLoadTasksUseCase(), []);
  const deleteUserTaskUseCase = useMemo(() => makeDeleteUserTaskUseCase(), []);

  const [tasks, setTasks] = useState<ILoadTasks.Result[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressCancel = useCallback(() => {
    dispatch(listTasksCloseModal());
  }, [dispatch]);

  const handleDeleteRow = useCallback(
    async (e: { key: string; type: string }[]) => {
      if (e.length > 0) {
        const [userId, taskId] = e[0].key.split('|');
        await deleteUserTaskUseCase.execute({ userId, taskId });
      }
    },
    [deleteUserTaskUseCase],
  );

  useEffect(() => {
    async function fetchTasks() {
      setIsLoading(true);
      try {
        const currentProject = localStorageAdapter.getItem('currentProject') as TreeViewData;
        const response = await loadTasksUseCase.execute({ projectId: currentProject.id });
        if (response && response.length > 0) {
          setTasks(response);
        } else {
          setTasks([]);
        }
      } catch (error) {
        setTasks([]);
      } finally {
        setIsLoading(false);
      }
    }

    if (listTaksIsOpenModal) {
      fetchTasks();
    }
  }, [listTaksIsOpenModal, loadTasksUseCase, localStorageAdapter]);

  return (
    <Modal
      handlePressCancel={handlePressCancel}
      title="Lista de tarefas"
      visible={listTaksIsOpenModal}
      size="3xl"
      buttonsVisible={false}
    >
      {isLoading ? (
        <Flex w="100%" h={250} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <DataGrid
          dataSource={tasks}
          keyExpr="id"
          allowColumnReordering={true}
          showBorders={true}
          columnAutoWidth={true}
          onContentReady={() => setIsLoading(false)}
        >
          <Editing
            mode="cell"
            allowUpdating={false}
            allowAdding={false}
            allowDeleting={true}
            onChangesChange={(e) => handleDeleteRow(e)}
          >
            <Texts confirmDeleteMessage={'Deseja realmente remover essa tarefa do usuário?'} />
          </Editing>

          <Column dataField="name" dataType="string" caption="Tarefa" />
          <Column dataField="samplingType" dataType="string" caption="Tp. Amostragem" />
          <Column
            dataField="status"
            dataType="string"
            caption="Status"
            cellRender={(e) => <Tag>{STATUS_LABEL[e.data.status]}</Tag>}
          />
          <Column
            dataField="priority"
            dataType="string"
            caption="Prioridade"
            cellRender={(e) => <Tag bg={PRIORITY_COLOR[e.data.priority]}>{PRIORITY_LABEL[e.data.priority]}</Tag>}
          />
          <Column dataField="userName" dataType="string" groupIndex={0} caption="Usuário" />
        </DataGrid>
      )}
    </Modal>
  );
};

export { ListTasks };
