import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { makeCreateSpaceUseCase } from '@/modules/space/factories/make-create-space-use-case';
import { makeUpdateSpaceUseCase } from '@/modules/space/factories/make-update-space-use-case';
import { updateSpaceCloseModal } from '@/modules/space/state/actions';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type InputProps = {
  name: string;
};

const UpdateSpaceModal: React.FC = () => {
  const dispatch = useDispatch();

  const { updateSpaceIsOpenModal, currentSpace } = useSelector((state: RootState) => state.space);

  const updateSpaceUseCase = useMemo(() => makeUpdateSpaceUseCase(), []);

  const [formData, setFormData] = useState<InputProps>({ name: '' });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const newSpace = await updateSpaceUseCase.execute({ spaceId: currentSpace.id, name: formData.name });
      if (newSpace) {
        dispatch(updateSpaceCloseModal({ spaceId: newSpace.id, name: newSpace.name, slug: newSpace.slug }));
      }
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setFormData({ name: '' });
      setIsLoading(false);
    }
  }, [formData, dispatch, currentSpace, updateSpaceUseCase]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(updateSpaceCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (updateSpaceIsOpenModal) {
      setFormData({ name: currentSpace.name });
      setIsLoading(false);
      setTextError('');
    }
  }, [updateSpaceIsOpenModal, currentSpace]);

  return (
    <Modal
      title="Editar espaço"
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={updateSpaceIsOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-space"
        label="Novo espaço"
        onChange={(e) => setFormData({ name: e.target.value })}
        textError={textError}
        type="text"
        defaultValue={formData.name}
      />
    </Modal>
  );
};

export { UpdateSpaceModal };
