import React from 'react';

import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';
import { MyLink } from '@/shared/presentation/components/atoms/link';
import { TextError } from '@/shared/presentation/components/atoms/text-error';
import { WrapperFooterForm } from '@/shared/presentation/components/atoms/wrapper-footer-form';

type Props = {
  isLoading: boolean;
  mainError: string;
  handleSubmit: () => void;
};

const Footer: React.FC<Props> = ({ isLoading, handleSubmit, mainError }: Props) => {
  return (
    <WrapperFooterForm>
      <MyLink to="/">Já possuo uma conta</MyLink>
      <ButtonSubmit isLoading={isLoading} disabled={isLoading} onClick={handleSubmit}>
        Criar conta
      </ButtonSubmit>
      <TextError>{mainError}</TextError>
    </WrapperFooterForm>
  );
};

export { Footer };
