import { AuthModel } from '../models/auth';

export enum ActionTypes {
  AUTH_SUCCESS = '@auth/sign-in/AUTH_SUCCESS',
  SIGN_OUT = '@auth/sign-out/SIGN_OUT',
  REDUX_PERSIST = 'persist/REHYDRATE',

  SET_IS_LOADING = '@auth/SET_IS_LOADING',
}

export type AuthState = AuthModel & {
  signed: boolean;
};
