import { action } from 'typesafe-actions';

import { EquipmentModel } from '../models/equipment';
import { ActionTypes } from './types';

export const setEquipments = (equipments: EquipmentModel[]) => action(ActionTypes.SET_EQUIPMENTS, { equipments });

export const addEditEquipmentOpenModal = () => action(ActionTypes.ADD_EDIT_EQUIPMENT_OPEN_MODAL);

export const addEditEquipmentCloseModal = () => action(ActionTypes.ADD_EDIT_EQUIPMENT_CLOSE_MODAL);

export const setNewEquipment = (equipment: EquipmentModel) => action(ActionTypes.SET_NEW_EQUIPMENT, { equipment });

export const removeEquipment = (id: string) => action(ActionTypes.REMOVE_EQUIPMENT, { id });

export const updateEquipment = (equipment: EquipmentModel) => action(ActionTypes.UPDATE_EQUIPMENT, { equipment });
