import { EquipmentModel } from '../models/equipment';

export enum ActionTypes {
  SET_EQUIPMENTS = '@equipment/SET_EQUIPMENTS',
  SET_NEW_EQUIPMENT = '@equipment/SET_NEW_EQUIPMENT',
  REMOVE_EQUIPMENT = '@equipment/REMOVE_EQUIPMENT',
  UPDATE_EQUIPMENT = '@equipment/UPDATE_EQUIPMENT',

  ADD_EDIT_EQUIPMENT_OPEN_MODAL = '@equipment/ADD_EDIT_EQUIPMENT_OPEN_MODAL',
  ADD_EDIT_EQUIPMENT_CLOSE_MODAL = '@equipment/ADD_EDIT_EQUIPMENT_CLOSE_MODAL',
}

export type EquipmentState = {
  equipments: EquipmentModel[];

  addEditEquipmentIsOpenModal: boolean;
};
