import React, { ReactChild, ReactChildren } from 'react';

import { Flex } from '@chakra-ui/react';

type Props = {
  children: ReactChild[] | ReactChildren[] | ReactChild | ReactChildren;
};

const RowTableContainer: React.FC<Props> = ({ children }: Props) => {
  return (
    <Flex
      flex="1"
      boxShadow="2xl"
      p="2"
      m="2"
      rounded="md"
      bg="gray.800"
      pl={10}
      pr={10}
      justifyContent="space-between"
      alignItems="center"
      flexDir="row"
    >
      {children}
    </Flex>
  );
};

export default RowTableContainer;
