import React from 'react';

import { Flex } from '@chakra-ui/react';

import { FormLabel } from '@/shared/presentation/components/atoms/form-label';

import { SelectBox } from '../atoms/select-box';

const ITEMS = [
  {
    id: 'LOW',
    label: 'BAIXA',
  },
  {
    id: 'MEDIUM',
    label: 'MÉDIA',
  },
  {
    id: 'HIGH',
    label: 'ALTA',
  },
];

type Props = {
  onValueChanged: (e: string) => void;
};

const Priority: React.FC<Props> = ({ onValueChanged }: Props) => {
  return (
    <Flex flexDir="column" mb={1} alignItems="flex-start">
      <FormLabel>Prioridade</FormLabel>
      <SelectBox items={ITEMS} onValueChanged={onValueChanged} defaultValue={ITEMS[1].id} />
    </Flex>
  );
};

export { Priority };
