export function getNodeType(geomType: number): 'POINT' | 'POLYGON' | undefined {
  switch (geomType) {
    case 1:
      return 'POINT';
    case 3:
      return 'POLYGON';
    case 6:
      return 'POLYGON';
    case 1001:
      return 'POINT';
    case 1003:
      return 'POLYGON';
    default:
      return undefined;
  }
}
