import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { SelectBox } from '../atoms/select-box';

type Props = {
  onChange: (value: string) => void;
  textError?: string;
};

type Item = {
  id: string;
  label: string;
};

const PolygonLabel: React.FC<Props> = ({ onChange, textError }: Props) => {
  const { polygon } = useSelector((state: RootState) => state.mapView);

  const items = useMemo(() => {
    const arrItems: Item[] = [];

    if (polygon) {
      //@ts-ignore
      return Object.keys(polygon.features[0].properties).map((key) => ({ id: key, label: key }));
    }

    return arrItems;
  }, [polygon]);

  return (
    <Flex flexDir="column" mb={1} alignItems="flex-start">
      <FormLabel>Label do poligono</FormLabel>
      <SelectBox onValueChanged={onChange} items={items} />
      <TextError>{textError}</TextError>
    </Flex>
  );
};

export { PolygonLabel };
