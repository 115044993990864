/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { store } from '@/main/config/redux';
import {
  addOrEditNodeOpenModal,
  exportNodeOpenModal,
  removeNodeOpenDialog,
  uploadZipOpenModal,
} from '@/modules/management-tree/state/actions';
import { assigmentUsersOpenModal, listTasksOpenModal } from '@/modules/project/state/actions';
import { createTaskOpenModal } from '@/modules/task/state/actions';

import { DatasourceContextMenu } from '../../../types/datasource-context-meny';

export const AllocationUser: DatasourceContextMenu = {
  text: 'Alocação de usuários',
  onClick: () => store.dispatch(assigmentUsersOpenModal()),
  icon: 'group',
};

export const AddNode: DatasourceContextMenu = {
  text: 'Criar novo nó',
  onClick: () => store.dispatch(addOrEditNodeOpenModal('create')),
  icon: 'add',
};

export const EditNode: DatasourceContextMenu = {
  text: 'Editar nó',
  onClick: () => store.dispatch(addOrEditNodeOpenModal('edit')),
  icon: 'edit',
};

export const DeleteNode: DatasourceContextMenu = {
  text: 'Remover nó',
  onClick: () => store.dispatch(removeNodeOpenDialog()),
  icon: 'trash',
};

export const UploadFile: DatasourceContextMenu = {
  text: 'Subir geometria',
  onClick: () => store.dispatch(uploadZipOpenModal()),
  icon: 'upload',
};

export const AddPointToMap: DatasourceContextMenu = {
  text: 'Adicionar pontos ao mapa',
  icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg>',
};

export const AddPolygonToMap: DatasourceContextMenu = {
  text: 'Adicionar poligonos ao mapa',
  icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 117.66v346.32c0 11.32 11.43 19.06 21.94 14.86L160 416V32L20.12 87.95A32.006 32.006 0 0 0 0 117.66zM192 416l192 64V96L192 32v384zM554.06 33.16L416 96v384l139.88-55.95A31.996 31.996 0 0 0 576 394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z"></path></svg>',
};

export const ReleaseForSampling: DatasourceContextMenu = {
  text: 'Liberar para amostragem',
  onClick: () => store.dispatch(createTaskOpenModal()),
  icon: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M17 17h2v4c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V3c0-1.1.9-1.99 2-1.99L17 1c1.1 0 2 .9 2 2v4h-2V6H7v12h10v-1zm5-5l-4-4v3h-5v2h5v3l4-4z"></path></svg>',
};

export const CreateFullTiles: DatasourceContextMenu = {
  text: 'Aplicativo',
  items: [
    { text: 'Criar mapa geral' },
    { text: 'Lista de tarefas', onClick: () => store.dispatch(listTasksOpenModal()) },
  ],
  icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>`,
};

export const ExportNodePoint: DatasourceContextMenu = {
  text: 'Exportar layer',
  onClick: () => store.dispatch(exportNodeOpenModal()),
  icon: 'download',
};
