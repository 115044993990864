import React, { useCallback, useMemo, useState } from 'react';

import { Container, Divider } from '@chakra-ui/react';

import { makeCreateTaskValidation } from '@/modules/task/factories/make-create-task-validation';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { DefinitionTask } from '../molecules/definition-task';
import { Depth } from '../molecules/depth';
import { GroupContainer } from '../molecules/group-container';
import { PointLabel } from '../molecules/point-label';
import { PolygonLabel } from '../molecules/polygon-label';
import { SettingTask } from '../molecules/setting-task';
import { UserList } from '../molecules/user-list';

type FormDataProps = {
  name: string;
  samplingTypeId: string;

  requestEquipment: boolean;
  requestTruck: boolean;
  requestMotorcycle: boolean;

  maxDistance: number;

  labelPoint: string;
  labelPolygon: string;

  priority: string;

  expirationDate: Date;

  users: string[];
};

type Props = {
  onChangeTab: (index: number) => void;
  onChage: (formData: FormDataProps, depths: string[]) => void;
};

type Error = {
  [key: string]: string;
};

const INITAL_FORM_DATA: FormDataProps = {
  name: '',
  expirationDate: new Date(),
  labelPoint: '',
  labelPolygon: '',
  maxDistance: 10,
  priority: 'MEDIUM',
  requestEquipment: true,
  requestMotorcycle: true,
  requestTruck: true,
  samplingTypeId: '',
  users: [],
};

const Tab0: React.FC<Props> = ({ onChangeTab, onChage }: Props) => {
  const taskValidation = useMemo(() => makeCreateTaskValidation(), []);

  const [formData, setFormData] = useState<FormDataProps>(INITAL_FORM_DATA);
  const [depths, setDepths] = useState<string[]>([]);
  const [error, setError] = useState<Error>({});

  const validateForm = useCallback(() => {
    const errors: Error = {};
    for (let i = 0; i < Object.keys(formData).length; ++i) {
      const key = Object.keys(formData)[i];
      const validate = taskValidation.validate(key, formData);
      if (validate) {
        errors[key] = validate;
      }
    }
    setError(errors);
    return Object.keys(errors).length > 0;
  }, [formData, taskValidation]);

  const handleSubmit = useCallback(() => {
    const validation = validateForm();

    if (!validation) {
      onChangeTab(1);
      onChage(formData, depths);
    }
  }, [depths, formData, onChangeTab, onChage, validateForm]);

  return (
    <Container overflowWrap="break-word" maxW="350px">
      <DefinitionTask
        onChange={(key, value) => setFormData((prevState) => ({ ...prevState, [key]: value }))}
        error={error}
      />

      <Divider />

      <UserList onChange={(e) => setFormData((prevState) => ({ ...prevState, users: e }))} textError={error['users']} />

      <Divider />

      <SettingTask onChange={(key, value) => setFormData((prevState) => ({ ...prevState, [key]: value }))} />

      <Divider />

      <GroupContainer title="Identificação">
        <PointLabel
          onChange={(e) => setFormData((prevState) => ({ ...prevState, labelPoint: e }))}
          textError={error['labelPoint']}
        />
        <PolygonLabel
          onChange={(e) => setFormData((prevState) => ({ ...prevState, labelPolygon: e }))}
          textError={error['labelPolygon']}
        />
      </GroupContainer>

      <Divider />

      <Depth onChange={(e) => setDepths(e)} />

      <Divider />

      <GroupContainer>
        {Object.keys(error).length > 0 && (
          <TextError>Existem erros nos campos preenchidos. Verifique e tente novamente</TextError>
        )}
        <ButtonSubmit w="100%" onClick={handleSubmit} mt={0}>
          Definir analises
        </ButtonSubmit>
      </GroupContainer>
    </Container>
  );
};

export { Tab0 };
