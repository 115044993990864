import { LocalStorage } from './contracts/local-storage';

export class LocalStorageAdapter implements LocalStorage {
  getItem(key: string): any | null {
    const data = localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
