import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { Container } from '@/modules/equipment-vehicle/presentantion/components/atoms/container';
import { ContenteSpinner } from '@/modules/equipment-vehicle/presentantion/components/atoms/content-spinner';
import { DividerHeader } from '@/modules/equipment-vehicle/presentantion/components/atoms/divider-header';
import { WrapperTable } from '@/modules/equipment-vehicle/presentantion/components/atoms/wrapper-table';
import { EmptyContentText } from '@/modules/equipment-vehicle/presentantion/components/molecules/empty-content-text';
import { HeaderTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/header-table';
import { RowTable } from '@/modules/equipment-vehicle/presentantion/components/molecules/row-table';
import { makeDeleteEquipmentUseCase } from '@/modules/equipment/factories/make-delete-equipment-use-case';
import { makeLoadEquipmentsUseCase } from '@/modules/equipment/factories/make-load-equipments-use-case';
import { EquipmentModel } from '@/modules/equipment/models/equipment';
import { addEditEquipmentOpenModal, removeEquipment, setEquipments } from '@/modules/equipment/state/actions';
import { Permission } from '@/modules/space/models/space-permission';
import { AlertDialog } from '@/shared/presentation/components/atoms/alert-dialog';
import { Can } from '@/shared/presentation/components/atoms/can';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';

import { CreateEditEquipmentModal } from '../molecules/create-edit-equipment-modal';

const Equipment: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    space: { currentSpace },
    equipment: { equipments },
  } = useSelector((state: RootState) => state);

  const loadEquipmentsUseCase = useMemo(() => makeLoadEquipmentsUseCase(), []);
  const deleteEquipmentUseCase = useMemo(() => makeDeleteEquipmentUseCase(), []);

  const [currentEquipment, setCurrentEquipment] = useState<EquipmentModel>({
    id: '',
    name: '',
    slug: '',
    spaceId: '',
  });
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const fetchEquipments = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await loadEquipmentsUseCase.execute();

      if (data && data.length) {
        dispatch(setEquipments(data));
      } else {
        dispatch(setEquipments([]));
      }
    } catch (error) {
      toast({
        title: 'Falha ao carregar equipamentos',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [loadEquipmentsUseCase, toast, dispatch]);

  const onClickAdd = useCallback(() => {
    setCurrentEquipment({
      id: '',
      name: '',
      slug: '',
      spaceId: '',
    });
    dispatch(addEditEquipmentOpenModal());
  }, [dispatch]);

  const onConfirmDelete = useCallback(async () => {
    try {
      setIsLoadingDelete(true);
      await deleteEquipmentUseCase.execute({ id: currentEquipment.id });

      dispatch(removeEquipment(currentEquipment.id));
      setAlertDialogIsOpen(false);
    } catch (error) {
      toast({
        title: 'Falha ao remover equipamento',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoadingDelete(false);
    }
  }, [deleteEquipmentUseCase, dispatch, toast, currentEquipment]);

  const onClickDelete = useCallback((equipment: EquipmentModel) => {
    setCurrentEquipment(equipment);
    setAlertDialogIsOpen(true);
  }, []);

  const onClickEdit = useCallback(
    (equipment: EquipmentModel) => {
      setCurrentEquipment(equipment);
      dispatch(addEditEquipmentOpenModal());
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentSpace?.id) {
      fetchEquipments();
    }
  }, [fetchEquipments, currentSpace.id]);

  return (
    <Container>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <CreateEditEquipmentModal currentEquipment={currentEquipment} />
        <AlertDialog
          cancelButtonText="Não"
          confirmButtonText="Sim"
          header="Atenção"
          message="Deseja realmente excluir este equipamento?"
          isLoading={isLoadingDelete}
          isOpen={alertDialogIsOpen}
          onCancel={() => {
            setAlertDialogIsOpen(false);
          }}
          onConfirm={onConfirmDelete}
        />
      </Can>
      <HeaderTable title="Equipamentos" onClickAdd={onClickAdd} />
      <DividerHeader />
      <WrapperTable>
        {isLoading && (
          <ContenteSpinner>
            <Spinner />
          </ContenteSpinner>
        )}

        {!isLoading && equipments.length > 0
          ? equipments.map((equipment) => (
              <RowTable
                key={equipment.id}
                text={equipment.name}
                onClickDelete={() => onClickDelete(equipment)}
                onClickEdit={() => onClickEdit(equipment)}
              />
            ))
          : null}

        {!isLoading && equipments.length === 0 ? <EmptyContentText text="Não existe equipamentos cadastrados" /> : null}
      </WrapperTable>
    </Container>
  );
};

export { Equipment };
