import React, { useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { Flex, Icon, IconButton } from '@chakra-ui/react';

import { createTaskCloseModal } from '@/modules/task/state/actions';

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const handlePressClose = useCallback(() => {
    dispatch(createTaskCloseModal());
  }, [dispatch]);

  return (
    <Flex pr={1} w="100%" mb={-35} alignItems="center" justifyContent="flex-end" _hover={{ background: 'transparent' }}>
      <IconButton
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        aria-label="close"
        icon={<Icon as={MdClose} color="white" size="20px" />}
        onClick={handlePressClose}
      />
    </Flex>
  );
};

export { Header };
