import React from 'react';

import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';

type Props = {
  label: string;
  defaultIsChecked: boolean;
  onChange: (e: boolean) => void;
};

const Checkbox: React.FC<Props> = ({ defaultIsChecked, label, onChange }) => {
  return (
    <ChakraCheckbox
      colorScheme="green"
      color="whiteAlpha.900"
      defaultIsChecked={defaultIsChecked}
      onChange={(e) => onChange(e.target.checked)}
    >
      {label}
    </ChakraCheckbox>
  );
};

export { Checkbox };
