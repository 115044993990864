import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, AvatarBadge } from '@chakra-ui/avatar';

import { RootState } from '@/main/config/redux';
import { makeLoadSpaceUseCase } from '@/modules/space/factories/make-load-space-use-case';
import { SpaceModel } from '@/modules/space/models/space';
import { Permission } from '@/modules/space/models/space-permission';
import { setCurrentSpace } from '@/modules/space/state/actions';
import { Can } from '@/shared/presentation/components/atoms/can';

import ContextMenu from './context-menu';

type Props = {
  space: SpaceModel;
};

const SpaceButton: React.FC<Props> = ({ space }: Props) => {
  const dispatch = useDispatch();

  const { currentSpace } = useSelector((state: RootState) => state.space);

  const handlePressCustomer = useCallback(async () => {
    dispatch(setCurrentSpace(space));

    const loadSpaceUseCase = makeLoadSpaceUseCase();

    try {
      await loadSpaceUseCase.execute({ spaceId: space.id });
    } catch (error) {
      //console.log('error', error);
    }
  }, [space, dispatch]);

  return (
    <React.Fragment>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <ContextMenu spaceId={space.id} />
      </Can>

      <Avatar
        className={`space-button-${space.id}`}
        height="60px"
        width="60px"
        src={`https://avatars.dicebear.com/api/initials/${space.slug}.svg?b=%2304d361&r=44&fontSize=44`}
        bg="green.500"
        ml="5"
        mr="5"
        mt="4"
        color="whiteAlpha.900"
        borderRadius="30%"
        _hover={{
          borderRadius: '50%',
          transition: 'all 0.2s',
          cursor: 'pointer',
        }}
        onClick={handlePressCustomer}
      >
        {space.id === currentSpace?.id && <AvatarBadge boxSize="1.25em" bg="whiteAlpha.900" />}
      </Avatar>
    </React.Fragment>
  );
};

export { SpaceButton };
