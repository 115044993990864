import React from 'react';

import { Text } from '@chakra-ui/react';

type Props = {
  text: string;
};

const HeaderTitleTable: React.FC<Props> = ({ text }: Props) => {
  return (
    <Text fontSize={22} color="whiteAlpha.900">
      {text}
    </Text>
  );
};

export { HeaderTitleTable };
