import React, { useState } from 'react';

import { Divider, Flex, TabPanel } from '@chakra-ui/react';

import { Arcgis } from '@/shared/adapters/map-visualization/contracts/arcgis';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';

import { GroupContainer } from '../molecules/group-container';
import { TypeAnalysisTable } from '../molecules/type-analysis-table';

type TypeAnalysis = {
  id: string;
  [key: string]: string | string[];
};

type Props = {
  onChangeTab: (index: number) => void;
  depths: string[];
  label: string;
  arcgisAdapter: Arcgis;
  onSubmit: (typeAnalysis: TypeAnalysis[]) => void;
  loading: boolean;
};

const Tab1: React.FC<Props> = ({ depths, label, onChangeTab, arcgisAdapter, onSubmit, loading = false }: Props) => {
  const [typeAnalysis, setTypeAnalysis] = useState<TypeAnalysis[]>([]);

  return (
    <>
      <GroupContainer title="Definição dos tipos de analise">
        <TypeAnalysisTable
          depths={depths}
          label={label}
          arcgisAdapter={arcgisAdapter}
          onChageTypeAnalysis={(e) => setTypeAnalysis(e)}
        />
      </GroupContainer>

      <Divider />

      <GroupContainer>
        <Flex flexDir="row" w="100%" alignItems="center" justifyContent="space-between">
          <ButtonSubmit w="30%" onClick={() => onChangeTab(0)} mt={0}>
            Voltar
          </ButtonSubmit>
          <ButtonSubmit w="30%" onClick={() => onSubmit(typeAnalysis)} mt={0} isLoading={loading} isDisabled={loading}>
            Liberar tarefa
          </ButtonSubmit>
        </Flex>
      </GroupContainer>
    </>
  );
};

export { Tab1 };
