import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';

import { setCurrentAuth } from '@/modules/sign-in/state/actions';
import { ISignIn } from '@/modules/sign-in/use-cases/contracts/sign-in';
import { FormHeading } from '@/shared/presentation/components/atoms/form-heading';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';
import { Validation } from '@/shared/validation/contracts/validation';

import { BackgroundImage } from '../atoms/background-image';
import { Footer } from '../molecules/footer';

type Inputs = {
  identity: string;
  password: string;
};

type Props = {
  validation: Validation;
  signInUseCase: ISignIn;
};

type FormError = {
  [key: string]: string;
};

const SignIn: React.FC<Props> = ({ signInUseCase, validation }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormError>({});
  const [mainError, setMainError] = useState('');
  const [formData, setFormData] = useState<Inputs>({ identity: '', password: '' });

  const validateForm = useCallback((): boolean => {
    const errors: FormError = {};
    for (let i = 0; i < Object.keys(formData).length; ++i) {
      const key = Object.keys(formData)[i];
      const validate = validation.validate(key, formData);
      if (validate) {
        errors[key] = validate;
      }
    }
    setFormError(errors);
    return Object.keys(errors).length > 0;
  }, [formData, validation]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const isFormInvalid = validateForm();

    try {
      if (isLoading || isFormInvalid) {
        return;
      }
      setIsLoading(true);
      const auth = await signInUseCase.execute({
        identity: formData.identity,
        password: formData.password,
      });

      if (auth) {
        dispatch(setCurrentAuth(auth));
        history.replace('/');
      }
    } catch (error: any) {
      setMainError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [signInUseCase, history, isLoading, formData, validateForm, dispatch]);

  const resetState = useCallback((): void => {
    setIsLoading(false);
    setFormError({});
    setMainError('');
    setFormData({ identity: '', password: '' });
  }, []);

  useEffect(() => resetState(), [resetState]);

  return (
    <Flex minH={'100vh'} minW={'100vw'} direction={{ base: 'column', md: 'row' }}>
      <Flex p="8" flex={1} align={'center'} justify={'center'} backgroundColor="gray.900">
        <Stack spacing={4} w={'full'} maxW={'md'} bgColor="gray.800" padding={8} borderRadius="xl">
          <FormHeading fontSize={'2xl'}>Acesse sua conta</FormHeading>

          <InputForm
            textError={formError['identity']}
            id="identity"
            label="E-mail/Celular"
            type="text"
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                identity: e.target.value,
              }))
            }
          />

          <InputForm
            textError={formError['password']}
            id="password"
            label="Senha"
            type="password"
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />

          <Footer isLoading={isLoading} handleSubmit={handleSubmit} mainError={mainError} />
        </Stack>
      </Flex>
      <Flex flex={1}>
        <BackgroundImage />
      </Flex>
    </Flex>
  );
};

export { SignIn };
