import produce from 'immer';
import { Reducer } from 'redux';

import { MapViewState, ActionTypes } from './types';

const INITIAL_STATE: MapViewState = {
  polygon: null,
  point: null,
};

const mapView: Reducer<MapViewState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_POLYGON: {
        draft.polygon = action.payload.polygon;
        break;
      }
      case ActionTypes.SET_POINT: {
        draft.point = action.payload.point;
        break;
      }
      default:
        break;
    }
  });
};

export default mapView;
