import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import DXContextMenu from 'devextreme-react/context-menu';

import { updateSpaceOpenModal, removeSpaceOpenDialog } from '@/modules/space/state/actions';

type Props = {
  spaceId: string;
};

const ContextMenu: React.FC<Props> = ({ spaceId }: Props) => {
  const dispatch = useDispatch();
  const dataSource = useMemo(() => {
    return [
      {
        text: 'Remover espaço',
        icon: 'trash',
        onClick: () => {
          dispatch(removeSpaceOpenDialog());
        },
        closeMenuOnClick: true,
      },
      {
        text: 'Editar espaço',
        icon: 'edit',
        onClick: () => {
          dispatch(updateSpaceOpenModal());
        },
        closeMenuOnClick: true,
      },
    ];
  }, [dispatch]);

  return <DXContextMenu target={`.space-button-${spaceId}`} dataSource={dataSource} />;
};

export default ContextMenu;
