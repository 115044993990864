import React from 'react';

import { VStack, Text, Container } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
  title?: string;
};

const GroupContainer: React.FC<Props> = ({ children, title }: Props) => {
  return (
    <>
      {title && (
        <Container mt="1" w="100%" alignItems="center" justifyContent="center">
          <Text color="whiteAlpha.900" fontSize="16" fontWeight="bold" textAlign="center">
            {title}
          </Text>
        </Container>
      )}
      <VStack spacing={2} alignItems="flex-start" justifyContent="flex-start" p={5}>
        {children}
      </VStack>
    </>
  );
};

export { GroupContainer };
