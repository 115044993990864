import React from 'react';

import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps, Flex } from '@chakra-ui/react';

const Tooltip: React.FC<ChakraTooltipProps> = ({ children, label, placement }: ChakraTooltipProps) => (
  <ChakraTooltip label={label} placement={placement}>
    <Flex>{children}</Flex>
  </ChakraTooltip>
);

export { Tooltip };
