import React from 'react';

import { Text } from '@chakra-ui/react';

import { Permission } from '@/modules/space/models/space-permission';
import { Can } from '@/shared/presentation/components/atoms/can';

import { DeleteButton } from '../atoms/delete-button';
import { EditButton } from '../atoms/edit-button';
import RowTableContainer from '../atoms/row-table-container';

type Props = {
  text: string;
  onClickDelete: () => void;
  onClickEdit: () => void;
};

const RowTable: React.FC<Props> = ({ text, onClickDelete, onClickEdit }: Props) => {
  return (
    <RowTableContainer>
      <Text fontSize="xl" color="whiteAlpha.800">
        {text}
      </Text>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <div>
          <EditButton onClick={onClickEdit} />
          <DeleteButton onClick={onClickDelete} />
        </div>
      </Can>
    </RowTableContainer>
  );
};

export { RowTable };
