import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ILoadSpacePermissions } from './contracts/load-space-permissions';

export class LoadSpacePermissionsUseCase implements ILoadSpacePermissions {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ILoadSpacePermissions.Result>) {}

  async execute(): Promise<ILoadSpacePermissions.Result | undefined> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
