import { FieldValidation } from '../contracts/field-validation';
import { AfterTodayError } from '../errors/after-today';

export class AfterTodayValidation implements FieldValidation {
  constructor(readonly field: string) {}

  validate(input: any): Error | null {
    return input[this.field] > new Date() ? null : new AfterTodayError();
  }
}
