import { ValidationBuilder as Builder } from '@/shared/builders/validation-builder';
import { ValidationComposite } from '@/shared/validation/composites/validation';

export const makeSignUpValidation = (): ValidationComposite =>
  ValidationComposite.build([
    ...Builder.field('name').required().build(),
    ...Builder.field('identity').required().build(),
    ...Builder.field('inviteId').required().build(),
    ...Builder.field('password').required().sameAs('passwordConfirmation').build(),
  ]);
