import React, { memo, useCallback, useMemo } from 'react';
import { FaBookmark } from 'react-icons/fa';

import { Icon } from '@chakra-ui/react';

import ContextMenu from 'devextreme-react/context-menu';

import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { checkNodePermission } from '@/modules/management-tree/use-cases/check-node-permission';
import { NodeType } from '@/modules/project/models/project';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { DatasourceContextMenu } from '../../../types/datasource-context-meny';
import { NodeWrapper } from '../node-wrapper';
import { AddNode, DeleteNode, EditNode } from './context-menu-options';

type Props = {
  id: string;
  name: string;
  nodeType: NodeType;
};

const ICON = (
  <Icon
    as={FaBookmark}
    __css={{
      path: {
        stroke: 'var(--chakra-colors-whiteAlpha-900)',
      },
    }}
    w="18px"
    h="18px"
  />
);

const GenericNode: React.FC<Props> = ({ id, name, nodeType }: Props) => {
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const dataSource = useCallback(() => {
    const menus: DatasourceContextMenu[] = [];
    const currentProject = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;
    const { modules } = currentProject;

    if (checkNodePermission(modules, 'Criar nós')) {
      menus.push(AddNode);
      menus.push(EditNode);
      menus.push(DeleteNode);
    }

    return menus;
  }, [localStorageAdapter]);

  return (
    <React.Fragment>
      <ContextMenu target={`.${nodeType}-${id}`} dataSource={dataSource()} />
      <NodeWrapper className={`${nodeType}-${id}`} icon={ICON} name={name} />
    </React.Fragment>
  );
};
const Component = memo(GenericNode);
export { Component as GenericNode };
