import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    green: {
      500: '#04D361',
      600: '#1B873F',
    },
    gray: {
      900: '#121214',
      800: '#202024',
      200: '#C4C4CC',
    },

    red: {
      500: '#D73628',
    },
    orange: {
      500: '#EB8A1D',
    },
    blue: {
      500: '#4863F7',
    },
  },
  shadows: {
    outline: '0',
  },
});
