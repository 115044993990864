import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Main } from '@/modules/main/presentation/components/templates/main';

import { RootState } from '../config/redux';

type Props = RouteProps & {
  component: React.FC;
  isPrivate?: boolean;
  path: string;
};

export const RouteWrapper: React.FC<Props> = ({ component: Component, isPrivate, path, ...rest }: Props) => {
  const { signed } = useSelector((state: RootState) => state.auth);

  if (path === '/forgot-password' || path == '/reset-password' || path === '/privacy-policy') {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/sign-in" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/" />;
  }

  const Layout = Main;

  return (
    <Route
      {...rest}
      render={(props) =>
        signed ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
