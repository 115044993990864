import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { UserOrPasswordInvalidError } from '../presentation/errors/user-or-password-invalid';
import { ISignIn } from './contracts/sign-in';

export class SignInUseCase implements ISignIn {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ISignIn.Result>) {}

  async execute(params: ISignIn.Params): Promise<ISignIn.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.forbidden:
        throw new UserOrPasswordInvalidError();
      case HttpStatusCode.badRequest:
        throw new UserOrPasswordInvalidError();
      default:
        throw new UnexpectedError();
    }
  }
}
