import { combineReducers } from 'redux';

import equipment from '@/modules/equipment/state/reducer';
import managementTree from '@/modules/management-tree/state/reducer';
import mapView from '@/modules/map-view/state/reducer';
import motorcycle from '@/modules/motorcycle/state/reducer';
import project from '@/modules/project/state/reducer';
import auth from '@/modules/sign-in/state/reducer';
import space from '@/modules/space/state/reducer';
import task from '@/modules/task/state/reducer';
import truck from '@/modules/truck/state/reducer';

export default combineReducers({
  auth,
  space,
  project,
  managementTree,
  mapView,
  equipment,
  truck,
  motorcycle,
  task,
});
