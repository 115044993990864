import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VStack } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { makeEditSaveShapefileUseCase } from '@/modules/management-tree/factories/make-edit-save-shapefile-use-case';
import { addNode, uploadZipCloseModal } from '@/modules/management-tree/state/actions';
import { getNodeType } from '@/modules/management-tree/use-cases/get-node-type';
import { setPoint, setPolygon } from '@/modules/map-view/state/actions';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { TableRenameFields } from '../molecules/table-rename-fields';
import { UploadZip } from '../molecules/upload-zip';

type Response = {
  count: number;
  fields: string[];
  geomType: number;
  uuid: string;
  fileName: string;
};

type NewFields = {
  [key: string]: string;
};

const UploadZipModal: React.FC = () => {
  const dispatch = useDispatch();

  const { uploadZipIsOpenModal } = useSelector((state: RootState) => state.managementTree);

  const editSaveShapefileUseCase = useMemo(() => makeEditSaveShapefileUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [response, setResponse] = useState<Response>({
    count: 0,
    fields: [],
    fileName: '',
    uuid: '',
    geomType: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [newFields, setNewFields] = useState<NewFields>({});
  const [error, setError] = useState<string>('');

  const handlePressSave = useCallback(async () => {
    try {
      setLoading(true);
      const currentNode = localStorageAdapter.getItem('currentNode');
      const saved = await editSaveShapefileUseCase.execute({
        fields: newFields,
        fileName: response.fileName,
        geomType: response.geomType,
        uuid: response.uuid,
        parentId: currentNode.id,
      });
      dispatch(uploadZipCloseModal());

      if (saved) {
        const nodeType = getNodeType(response.geomType);
        dispatch(
          addNode({
            id: saved.id,
            expanded: false,
            name: saved.name,
            parentId: currentNode.id,
            nodeType: nodeType === 'POINT' ? 'POINT' : 'POLYGON',
            projectId: currentNode.projectId,
            modules: currentNode.modules,
          }),
        );
        if (nodeType === 'POLYGON') {
          dispatch(setPolygon({ id: saved.id, name: saved.name, ...saved.featureCollection }));
        } else {
          dispatch(setPoint({ id: saved.id, name: saved.name, ...saved.featureCollection }));
        }
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [response, localStorageAdapter, editSaveShapefileUseCase, newFields, dispatch]);

  const handlePressCancel = useCallback(() => {
    dispatch(uploadZipCloseModal());
  }, [dispatch]);

  const onComplete = useCallback((response: Response) => {
    setResponse(response);
  }, []);

  useEffect(() => {
    if (uploadZipIsOpenModal) {
      setResponse({
        count: 0,
        fields: [],
        fileName: '',
        geomType: 0,
        uuid: '',
      });
      setError('');
    }
  }, [uploadZipIsOpenModal]);

  return (
    <Modal
      title="Subir shapefile"
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={uploadZipIsOpenModal}
      isLoading={loading}
      size="xl"
    >
      <VStack spacing={4} alignItems="stretch">
        <UploadZip onComplete={onComplete} />
        {response.fields.length > 0 && (
          <TableRenameFields fields={response.fields} onChangeField={(e) => setNewFields(e)} />
        )}
        <TextError>{error}</TextError>
      </VStack>
    </Modal>
  );
};

export { UploadZipModal };
