import React, { memo } from 'react';

import { Flex, VStack } from '@chakra-ui/react';

import { Resizable } from 're-resizable';

import { MapView } from '@/modules/map-view/presentation/components/organisms/map-view';
import { CreateProjectButton } from '@/modules/project/presentation/components/atoms/create-project-button';
import { AssigmentUsersModal } from '@/modules/project/presentation/components/molecules/assignment-users-modal';
import { CreateProjectModal } from '@/modules/project/presentation/components/molecules/create-project-modal';
import { ListTasks } from '@/modules/project/presentation/components/molecules/list-tasks';
import { Permission } from '@/modules/space/models/space-permission';
import { Can } from '@/shared/presentation/components/atoms/can';

import { CreateOrEditNodeModal } from './create-edit-node-modal';
import { DeleteNodeDialog } from './delete-node-dialog';
import { ExportNodeModal } from './export-node-modal';
import { TreeView } from './tree-view';
import { UploadZipModal } from './upload-zip-modal';

const Management: React.FC = () => {
  return (
    <>
      <AssigmentUsersModal />
      <CreateOrEditNodeModal />
      <DeleteNodeDialog />
      <UploadZipModal />
      <ListTasks />
      <ExportNodeModal />

      <div
        style={{
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Resizable
          style={{
            display: 'flex',
            borderRight: 'solid 1px #ddd',
          }}
          defaultSize={{
            width: '20%',
            height: '100%',
          }}
          maxWidth="100%"
          minWidth="10%"
          minHeight="100%"
        >
          <VStack spacing="5" m="5" alignItems="flex-start">
            <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
              <CreateProjectModal />
              <Flex flexDir="row">
                <CreateProjectButton />
              </Flex>
            </Can>

            <TreeView />
          </VStack>
        </Resizable>

        <MapView />
      </div>
    </>
  );
};

const Component = memo(Management);
export { Component as Management };
