import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import { makeGetSamplingTypesUseCase } from '@/modules/map-view/factories/make-get-sampling-types-use-case';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

import { SelectBox } from '../atoms/select-box';

type Props = {
  onChange: (samplingTypeId: string) => void;
  textError?: string;
};

const SamplingType: React.FC<Props> = ({ onChange, textError }: Props) => {
  const getSamplingTypesUseCase = useMemo(() => makeGetSamplingTypesUseCase(), []);

  const [items, setItems] = useState<{ id: string; label: string }[]>([]);

  const fetchSamplingTypes = useCallback(async () => {
    const samplingTypes = await getSamplingTypesUseCase.execute();

    try {
      if (samplingTypes && samplingTypes.length > 0) {
        setItems(samplingTypes.map((samplingType) => ({ id: samplingType.id, label: samplingType.name })));
      } else {
        setItems([]);
      }
    } catch (error) {
      setItems([]);
    }
  }, [getSamplingTypesUseCase]);

  useEffect(() => {
    fetchSamplingTypes();
  }, [fetchSamplingTypes]);

  return (
    <Flex flexDir="column" mb={1} alignItems="flex-start">
      <FormLabel>Tipo de Amostragem</FormLabel>
      <SelectBox onValueChanged={onChange} items={items} />
      <TextError>{textError}</TextError>
    </Flex>
  );
};

export { SamplingType };
