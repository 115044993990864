import { useEffect, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { Arcgis } from '@/shared/adapters/map-visualization/contracts/arcgis';

type Props = {
  arcgisAdapter: Arcgis;
};

const GeojsonPolygonLayer: React.FC<Props> = ({ arcgisAdapter }: Props) => {
  const { polygon } = useSelector((state: RootState) => state.mapView);

  const loadModulesArcgis = useCallback(
    async (feature) => {
      const popupTemplate = arcgisAdapter.makePopupTemplate(Object.keys(feature.features[0].properties));

      await arcgisAdapter.displayGeoJSONOnMap({
        geojson: feature,
        id: 'polygon',
        indexLayer: 0,
        popupTemplate,
      });
    },
    [arcgisAdapter],
  );

  useEffect(() => {
    if (polygon) {
      loadModulesArcgis(polygon);
    }
  }, [loadModulesArcgis, polygon]);

  return null;
};

const Component = memo(GeojsonPolygonLayer);
export { Component as GeojsonPolygonLayer };
