import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ICreateProject } from './contracts/create-project';

export class CreateProjectUseCase implements ICreateProject {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ICreateProject.Result>) {}

  async execute(params: ICreateProject.Params): Promise<ICreateProject.Result | undefined> {
    const { name, moduleIds } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: {
        name,
        moduleIds,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
