import React, { useCallback, useState } from 'react';
import { MdPersonAdd, MdGroup } from 'react-icons/md';
import { useSelector } from 'react-redux';

import Icon from '@chakra-ui/icon';
import { Flex, Heading } from '@chakra-ui/layout';

import { RootState } from '@/main/config/redux';
import { SendInviteModal } from '@/modules/invite/presentation/components/molecules/send-invite-modal';
import { Permission } from '@/modules/space/models/space-permission';
import { UsersSpaceModal } from '@/modules/space/presentation/components/molecules/users-space-modal';
import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';
import { Can } from '@/shared/presentation/components/atoms/can';

const DashboardHeader: React.FC = () => {
  const { currentSpace } = useSelector((state: RootState) => state.space);

  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>(false);
  const [isOpenUsersModal, setIsOpenUsersModal] = useState<boolean>(false);

  const openInviteModal = useCallback(() => {
    setIsOpenInviteModal(true);
  }, []);

  const closeInviteModal = useCallback(() => {
    setIsOpenInviteModal(false);
  }, []);

  const openUsersModal = useCallback(() => {
    setIsOpenUsersModal(true);
  }, []);

  const closeUsersModal = useCallback(() => {
    setIsOpenUsersModal(false);
  }, []);

  return (
    <Flex
      flexDir="row"
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      paddingLeft="7"
      paddingRight="7"
      borderBottomWidth="1px"
      borderBottomColor="whiteAlpha.300"
      h="100px"
    >
      <Heading color="whiteAlpha.900">{currentSpace.name}</Heading>
      <Can checkRole={[Permission.OWNER, Permission.ADMINISTRATOR]} typeCheck="space">
        <SendInviteModal isOpen={isOpenInviteModal} closeModal={closeInviteModal} />

        <UsersSpaceModal isOpen={isOpenUsersModal} closeModal={closeUsersModal} />

        <Flex flexDir="row" alignItems="center" justifyContent="center">
          <ButtonSubmit
            isLoading={false}
            disabled={false}
            onClick={openUsersModal}
            leftIcon={<Icon as={MdGroup} />}
            marginRight="4"
            mt="0px"
          >
            Membros
          </ButtonSubmit>

          <ButtonSubmit
            isLoading={false}
            disabled={false}
            onClick={openInviteModal}
            leftIcon={<Icon as={MdPersonAdd} />}
            mt="0px"
          >
            Convidar
          </ButtonSubmit>
        </Flex>
      </Can>
    </Flex>
  );
};

export { DashboardHeader };
