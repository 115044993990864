import React, { ReactChild, ReactChildren } from 'react';

import { HStack, Text } from '@chakra-ui/react';

type Props = {
  icon: ReactChild | ReactChildren;
  name: string;
  className: string;
};
const NodeWrapper: React.FC<Props> = ({ icon, name, className }: Props) => {
  return (
    <HStack className={className} alignItems="center" justifyContent="flex-start" spacing="3">
      {icon}
      <Text fontWeight="semibold">{name}</Text>
    </HStack>
  );
};

export { NodeWrapper };
