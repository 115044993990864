import React, { memo, useCallback, useMemo } from 'react';
import { RiLayoutGridFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import { Icon } from '@chakra-ui/react';

import ContextMenu from 'devextreme-react/context-menu';

import { RootState } from '@/main/config/redux';
import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { checkNodePermission } from '@/modules/management-tree/use-cases/check-node-permission';
import { NodeType } from '@/modules/project/models/project';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';

import { DatasourceContextMenu } from '../../../types/datasource-context-meny';
import { NodeWrapper } from '../node-wrapper';
import { DeleteNode, ReleaseForSampling, UploadFile } from './context-menu-options';
type Props = {
  id: string;
  name: string;
  nodeType: NodeType;
};

const ICON = (
  <Icon
    as={RiLayoutGridFill}
    __css={{
      path: {
        stroke: 'var(--chakra-colors-whiteAlpha-900)',
      },
    }}
    w="18px"
    h="18px"
  />
);

const SamplingNode: React.FC<Props> = ({ id, name, nodeType }: Props) => {
  const { point, polygon } = useSelector((state: RootState) => state.mapView);

  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const dataSource = useCallback(() => {
    const menus: DatasourceContextMenu[] = [];
    const currentProject = localStorageAdapter.getItem('currentProject') as ManagementTreeModel;

    const { modules } = currentProject;

    if (checkNodePermission(modules, 'Subir geometrias')) {
      menus.push(UploadFile);
    }
    if (checkNodePermission(modules, 'Criar nós')) {
      menus.push(DeleteNode);
    }

    const pointAndPolygon = !!point && !!polygon;
    if (pointAndPolygon && checkNodePermission(modules, 'Liberar para amostragem')) {
      menus.push(ReleaseForSampling);
    }

    return menus;
  }, [localStorageAdapter, point, polygon]);

  return (
    <React.Fragment>
      <ContextMenu target={`.${nodeType}-${id}`} dataSource={dataSource()} />
      <NodeWrapper className={`${nodeType}-${id}`} icon={ICON} name={name} />
    </React.Fragment>
  );
};
const Component = memo(SamplingNode);
export { Component as SamplingNode };
