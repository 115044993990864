import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { makeCreateMotorcycleUseCase } from '@/modules/motorcycle/factories/make-create-motorcycle-use-case';
import { makeEditMotorcycleUseCase } from '@/modules/motorcycle/factories/make-edit-motorcycle-use-case';
import { MotorcycleModel } from '@/modules/motorcycle/models/motorcycle';
import { addEditMotorcycleCloseModal, setNewMotorcycle, updateMotorcycle } from '@/modules/motorcycle/state/actions';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { TextError } from '@/shared/presentation/components/atoms/text-error';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type Props = {
  currentMotorcycle: MotorcycleModel;
};

type InputProps = {
  name: string;
};

const CreateEditMotorcycleModal: React.FC<Props> = ({ currentMotorcycle }: Props) => {
  const dispatch = useDispatch();

  const { addEditMotorcycleIsOpenModal } = useSelector((state: RootState) => state.motorcycle);

  const createMotorcycleUseCase = useMemo(() => makeCreateMotorcycleUseCase(), []);

  const editMotorcycleUseCase = useMemo(() => makeEditMotorcycleUseCase(), []);

  const [formData, setFormData] = useState<InputProps>({
    name: '',
  });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveNewMotorcycle = useCallback(async () => {
    setIsLoading(true);
    try {
      const newMotorcycle = await createMotorcycleUseCase.execute(formData);
      if (newMotorcycle) {
        dispatch(setNewMotorcycle(newMotorcycle));
      }
      dispatch(addEditMotorcycleCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [createMotorcycleUseCase, dispatch, formData]);

  const saveEditMotorcycle = useCallback(async () => {
    setIsLoading(true);
    try {
      const editedMotorcycle = await editMotorcycleUseCase.execute({
        ...formData,
        id: currentMotorcycle.id,
      });
      if (editedMotorcycle) {
        dispatch(updateMotorcycle(editedMotorcycle));
      }
      dispatch(addEditMotorcycleCloseModal());
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [currentMotorcycle.id, dispatch, formData, editMotorcycleUseCase]);

  const handlePressSave = useCallback(async (): Promise<void> => {
    if (!currentMotorcycle.id) {
      await saveNewMotorcycle();
    } else {
      await saveEditMotorcycle();
    }
  }, [saveNewMotorcycle, saveEditMotorcycle, currentMotorcycle]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(addEditMotorcycleCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (addEditMotorcycleIsOpenModal) {
      setFormData({ name: currentMotorcycle.name });
      setIsLoading(false);
      setTextError('');
    }
  }, [addEditMotorcycleIsOpenModal, currentMotorcycle]);

  return (
    <Modal
      title={!currentMotorcycle.id ? 'Cadastro de novo quadriciclo' : 'Edição de quadriciclo'}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addEditMotorcycleIsOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-motorcycle"
        label="Novo quadriciclo"
        onChange={(e) => setFormData((prevState) => ({ ...prevState, name: e.target.value }))}
        defaultValue={formData.name}
        type="text"
      />

      {textError && <TextError>{textError}</TextError>}
    </Modal>
  );
};

export { CreateEditMotorcycleModal };
