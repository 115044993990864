import React, { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { signOut } from '@/modules/sign-in/state/actions';
import { MyLink } from '@/shared/presentation/components/atoms/link';

const Links = [
  {
    name: 'Dashboard',
    href: '/',
  },
  {
    name: 'Equipamentos e veiculos',
    href: '/equipment-vehicle',
  },
];

const NavLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    color="whiteAlpha.700"
    _hover={{
      textDecoration: 'none',
      bg: 'whiteAlpha.200',
    }}
    href={href}
  >
    {children}
  </Link>
);

const NavBar: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const handlePressSignout = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <Flex
      minW="100%"
      maxW="100%"
      backgroundColor="gray.800"
      minH="90px"
      maxH="90px"
      alignItems={'center'}
      justifyContent={'space-between'}
      pl="5"
      pr="5"
      borderBottom="1px solid #000"
    >
      <HStack spacing={8} alignItems={'center'}>
        <Box>
          <MyLink
            _hover={{
              textDecoration: 'none',
            }}
            to="/"
            fontSize="28"
            color="whiteAlpha.900"
          >
            Argus
          </MyLink>
        </Box>
        <HStack as={'nav'} spacing={4}>
          {Links.map((link) => (
            <NavLink key={link.name} href={link.href}>
              {link.name}
            </NavLink>
          ))}
        </HStack>
      </HStack>
      <Flex alignItems={'center'}>
        <VStack mr="20px" align="flex-end">
          <Text color="whiteAlpha.900">{user.name}</Text>
          <Text color="whiteAlpha.500">{user.identity}</Text>
        </VStack>
        <Menu>
          <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
            <Avatar size={'md'} src={`https://avatars.dicebear.com/api/gridy/${user.name}.svg`} />
          </MenuButton>
          <MenuList>
            <MenuItem>Editar perfil</MenuItem>
            <MenuDivider />
            <MenuItem onClick={handlePressSignout}>Sair</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export { NavBar };
