import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, VStack } from '@chakra-ui/react';

import SelectBox from 'devextreme-react/select-box';

import { RootState } from '@/main/config/redux';
import { makeCreateNodeUseCase } from '@/modules/management-tree/factories/make-create-node-use-case';
import { makeEditNodeUseCase } from '@/modules/management-tree/factories/make-edit-node-use-case';
import { makeLoadNodeTypesUseCase } from '@/modules/management-tree/factories/make-load-node-types-use-case';
import { ManagementTreeModel } from '@/modules/management-tree/models/management-tree';
import { addOrEditNodeCloseModal, updateNode, addNode } from '@/modules/management-tree/state/actions';
import { ICreateNode } from '@/modules/management-tree/use-cases/contracts/create-node';
import { IEditNode } from '@/modules/management-tree/use-cases/contracts/edit-node';
import { ILoadNodeTypes } from '@/modules/management-tree/use-cases/contracts/load-node-types';
import { NodeType } from '@/modules/project/models/project';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { FormLabel } from '@/shared/presentation/components/atoms/form-label';
import { Modal } from '@/shared/presentation/components/atoms/modal';
import { InputForm } from '@/shared/presentation/components/molecules/input-form';

type InputProps = {
  name: string;
  nodeType: NodeType;
};

type NodeTypeLabel = {
  id: string;
  label: string;
};

const aliasNodeType = {
  YEAR: 'Ano',
  GENERIC: 'Genérico',
  SAMPLING: 'Amostragem',
};

const CreateOrEditNodeModal: React.FC = () => {
  const dispatch = useDispatch();

  const { addOrEditNodeModal } = useSelector((state: RootState) => state.managementTree);

  const loadNodeTypesUseCase = useMemo((): ILoadNodeTypes => makeLoadNodeTypesUseCase(), []);
  const editNodeUseCase = useMemo((): IEditNode => makeEditNodeUseCase(), []);
  const createNodeUseCase = useMemo((): ICreateNode => makeCreateNodeUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);

  const [formData, setFormData] = useState<InputProps>({
    name: '',
    nodeType: 'GENERIC',
  });

  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nodeTypes, setNodeTypes] = useState<NodeTypeLabel[]>([]);

  const createNode = useCallback(
    async (currentNode: ManagementTreeModel): Promise<void> => {
      const newNode = await createNodeUseCase.execute({
        name: formData.name,
        nodeType: formData.nodeType,
        parentId: currentNode.id,
        currentNodeType: currentNode.nodeType,
      });

      if (newNode) {
        dispatch(
          addNode({
            ...newNode,
            modules: currentNode.modules,
            projectId: currentNode.projectId,
            parentId: currentNode.id,
          }),
        );
      }
    },
    [formData, createNodeUseCase, dispatch],
  );

  const editNode = useCallback(
    async (currentNode: ManagementTreeModel): Promise<void> => {
      const newNode = await editNodeUseCase.execute({
        name: formData.name,
        nodeType: formData.nodeType,
        id: currentNode.id,
      });

      if (newNode) {
        dispatch(
          updateNode({
            ...newNode,
            modules: currentNode.modules,
            projectId: currentNode.projectId,
            parentId: currentNode.id,
          }),
        );
      }
    },
    [formData, editNodeUseCase, dispatch],
  );

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const currentNode = localStorageAdapter.getItem('currentNode') as ManagementTreeModel;
    try {
      if (addOrEditNodeModal.type == 'create') {
        await createNode(currentNode);
      } else {
        await editNode(currentNode);
      }
      dispatch(addOrEditNodeCloseModal());
    } catch (error: any) {
      setTextError(error.message + 'dentro do nó ' + currentNode.name);
    } finally {
      setIsLoading(false);
    }
  }, [addOrEditNodeModal, createNode, editNode, dispatch, localStorageAdapter]);

  const handlePressCancel = useCallback((): void => {
    dispatch(addOrEditNodeCloseModal());
  }, [dispatch]);

  const fetchNodeTypes = useCallback(async () => {
    const response = await loadNodeTypesUseCase.execute();

    if (response && response.length > 0) {
      const types = response.map((item) => ({
        id: item,
        //@ts-ignore
        label: aliasNodeType[item],
      }));
      setNodeTypes(types);
    }
  }, [loadNodeTypesUseCase]);

  const onValueChanged = useCallback((value: NodeType) => {
    if (value === 'SAMPLING') {
      setFormData({ name: 'AMOSTRAGEM', nodeType: value });
    } else {
      setFormData((prevState) => ({ ...prevState, nodeType: value }));
    }
  }, []);

  useEffect(() => {
    if (addOrEditNodeModal.open) {
      setFormData({ name: '', nodeType: 'GENERIC' });
      fetchNodeTypes();
    }
  }, [addOrEditNodeModal.open, fetchNodeTypes]);

  useEffect(() => {
    if (addOrEditNodeModal.type === 'edit' && addOrEditNodeModal.open) {
      const currentNode = localStorageAdapter.getItem('currentNode');
      setFormData({ name: currentNode.name, nodeType: currentNode.nodeType });
    }
  }, [addOrEditNodeModal, localStorageAdapter]);

  return (
    <Modal
      title={addOrEditNodeModal.type === 'edit' ? 'Edição de nó' : 'Cadastro de novo nó'}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={addOrEditNodeModal.open}
      isLoading={isLoading}
    >
      <VStack spacing="5" alignItems="flex-start" w="100%" h="100%">
        <Flex flexDir="column" w="100%">
          <FormLabel>Tipo do nó</FormLabel>
          <SelectBox
            items={nodeTypes}
            valueExpr="id"
            displayExpr="label"
            stylingMode="filled"
            placeholder="Escolha o tipo de nó"
            defaultValue={formData.nodeType}
            onValueChanged={(e) => onValueChanged(e.value)}
          />
        </Flex>

        {formData.nodeType === 'GENERIC' || formData.nodeType === 'YEAR' ? (
          <InputForm
            id="new-node"
            label="Nome do nó"
            defaultValue={formData.name}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }))
            }
            textError={textError}
            type="text"
          />
        ) : null}
      </VStack>
    </Modal>
  );
};

export { CreateOrEditNodeModal };
