import produce from 'immer';
import { Reducer } from 'redux';

import { ActionTypes as AuthActionTypes } from '@/modules/sign-in/state/types';
import { ActionTypes as AuthTypes } from '@/modules/sign-in/state/types';

import { SpaceState, ActionTypes } from './types';

const INITIAL_STATE: SpaceState = {
  spaces: [],
  currentSpace: {
    id: '',
    name: '',
    slug: '',
    createdAt: '',
    updatedAt: '',

    permission: 'USER',
  },

  removeSpaceIsOpenDialog: false,
  updateSpaceIsOpenModal: false,
  addSpaceIsOpenModal: false,
};

const space: Reducer<SpaceState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_SPACES: {
        draft.spaces = action.payload.spaces;
        break;
      }
      case AuthActionTypes.SIGN_OUT: {
        draft.currentSpace = {
          id: '',
          name: '',
          slug: '',
          createdAt: '',
          updatedAt: '',
          permission: 'USER',
        };
        break;
      }
      case ActionTypes.SET_CURRENT_SPACE: {
        draft.currentSpace = action.payload.space;
        break;
      }
      case ActionTypes.ADD_SPACE_OPEN_MODAL: {
        draft.addSpaceIsOpenModal = true;
        break;
      }
      case ActionTypes.UPDATE_SPACE_CLOSE_MODAL: {
        draft.updateSpaceIsOpenModal = false;
        if (action.payload.space) {
          draft.spaces = draft.spaces.map((space) =>
            space.id === action.payload.space.id
              ? {
                  ...space,
                  name: action.payload.space.name,
                  slug: action.payload.space.slug,
                }
              : space,
          );
          draft.currentSpace = {
            ...draft.currentSpace,
            name: action.payload.space.name,
            slug: action.payload.space.slug,
          };
        }
        break;
      }
      case ActionTypes.UPDATE_SPACE_OPEN_MODAL: {
        draft.updateSpaceIsOpenModal = true;

        break;
      }
      case ActionTypes.ADD_SPACE_CLOSE_MODAL: {
        draft.addSpaceIsOpenModal = false;
        break;
      }
      case ActionTypes.ADD_NEW_SPACE: {
        draft.spaces = [...draft.spaces, action.payload.newSpace];
        draft.addSpaceIsOpenModal = false;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.spaces = [];
        draft.currentSpace = {
          id: '',
          name: '',
          slug: '',
          createdAt: '',
          updatedAt: '',
          permission: 'USER',
        };
        break;
      }
      case ActionTypes.REMOVE_SPACE_OPEN_DIALOG: {
        draft.removeSpaceIsOpenDialog = true;
        break;
      }
      case ActionTypes.REMOVE_SPACE_CLOSE_DIALOG: {
        draft.removeSpaceIsOpenDialog = false;
        if (action.payload.spaceId) {
          draft.spaces = draft.spaces.filter((space) => space.id !== action.payload.spaceId);
          draft.currentSpace = {
            id: '',
            name: '',
            slug: '',
            createdAt: '',
            updatedAt: '',

            permission: 'USER',
          };
        }
        break;
      }
      default:
    }
  });
};

export default space;
