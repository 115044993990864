import React, { useCallback, useMemo, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import FileUploader from 'devextreme-react/file-uploader';
import { NativeEventInfo } from 'devextreme/events';
import dxFileUploader from 'devextreme/ui/file_uploader';

import { makeUploadZipUseCase } from '@/modules/management-tree/factories/make-upload-zip-use-case';
import { Spinner } from '@/shared/presentation/components/atoms/spinner';
import { TextError } from '@/shared/presentation/components/atoms/text-error';

export type ValueChangedEvent = NativeEventInfo<dxFileUploader> & {
  readonly value?: File[];
  readonly previousValue?: File[];
};

type Response = {
  count: number;
  fields: string[];
  geomType: number;
  uuid: string;
  fileName: string;
};

type Props = {
  onComplete: (response: Response) => void;
};

const UploadZip: React.FC<Props> = ({ onComplete }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const uploadUseCase = useMemo(() => makeUploadZipUseCase(), []);

  const onValueChanged = useCallback(
    async (e: ValueChangedEvent) => {
      if (e.value) {
        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', e.value[0]);

        try {
          const response = await uploadUseCase.execute({ formData });

          if (response?.geomType) {
            //setResponse(response);
            onComplete(response);
            e.component.option('readyToUploadMessage', 'Upload feito com sucesso');
          } else {
            e.component.option('uploadFailedMessage', 'Erro ao fazer upload de arquivo');
          }
        } catch (error: any) {
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [uploadUseCase, onComplete],
  );

  return (
    <Box borderWidth={1} borderColor="white" borderRadius="8" p="5" alignItems="center" justifyContent="center">
      <Text color="whiteAlpha.900" fontSize="lg">
        O arquivo deve ser um .zip contendo .shp, .dbf, .prj e .shx
      </Text>

      <FileUploader
        selectButtonText="Selecione o arquivo"
        labelText="Ou arraste aqui"
        accept=".zip"
        uploadMode="useForm"
        //disabled={response?.geomType !== null}
        onValueChanged={onValueChanged}
      />

      {error && <TextError>{error}</TextError>}

      {isLoading && (
        <Flex justify="center">
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};

export { UploadZip };
