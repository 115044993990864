import React, { useRef } from 'react';

import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  header?: string;
  message: string;
  isLoading: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
};

const AlertDialog: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
  message,
  header,
  isLoading = false,
  cancelButtonText,
  confirmButtonText,
}: Props) => {
  const cancelRef = useRef<any>();

  return (
    <ChakraAlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onCancel}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent backgroundColor="gray.800">
          {header && (
            <AlertDialogHeader color="whiteAlpha.900" fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
          )}

          <AlertDialogBody
            color="whiteAlpha.900"
            borderBottomColor="gray.300"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderTopColor="gray.300"
            borderTopWidth="1px"
            borderTopStyle="solid"
            p={5}
          >
            {message}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onCancel}>{cancelButtonText}</Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3} isLoading={isLoading} isDisabled={isLoading}>
              {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};

export { AlertDialog };
