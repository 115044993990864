import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { RouteWrapper } from '@/main/proxies/route';
import { makeDashboardPage } from '@/modules/dashboard/factories/make-dashboard-page';
import { EquipmentVehicle } from '@/modules/equipment-vehicle/presentantion/components/page/equipment-vehicle';
import { makeForgotPasswordPage } from '@/modules/forgot-passowrd/factories/make-forgot-password-page';
import { makeResetPasswordPage } from '@/modules/reset-password/factories/make-reset-password-page';
import { makeSignInPage } from '@/modules/sign-in/factories/make-sign-in-page';
import { makeSignUpPage } from '@/modules/sign-up/factories/make-sign-up-page';
import PrivacyPolicy from '@/shared/presentation/components/pages/privacy-policy';

import AuthProvider from '../config/providers/auth';
import { ChakraProvider } from '../config/providers/chakra';
import { DevExtreme } from '../config/providers/dev-extreme';
import { ReduxProvider } from '../config/providers/redux';

const Router: React.FC = () => {
  return (
    <ReduxProvider>
      <DevExtreme>
        <ChakraProvider>
          <AuthProvider>
            <BrowserRouter>
              <Switch>
                <RouteWrapper path="/sign-in" exact component={makeSignInPage} />
                <RouteWrapper path="/forgot-password" exact component={makeForgotPasswordPage} />
                <RouteWrapper path="/reset-password/:resetPasswordId" exact component={makeResetPasswordPage} />
                <RouteWrapper path="/sign-up/:id" exact component={makeSignUpPage} />
                <RouteWrapper path="/" exact component={makeDashboardPage} isPrivate />

                <RouteWrapper path="/equipment-vehicle" exact component={EquipmentVehicle} isPrivate />
                <RouteWrapper path="/privacy-policy" exact component={PrivacyPolicy} isPrivate />
              </Switch>
            </BrowserRouter>
          </AuthProvider>
        </ChakraProvider>
      </DevExtreme>
    </ReduxProvider>
  );
};

export default Router;
