import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IEditSaveShapefile } from './contracts/edit-save-shapefile';

export class EditSaveShapefileUseCase implements IEditSaveShapefile {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IEditSaveShapefile.Result>) {}

  async execute(params: IEditSaveShapefile.Params): Promise<IEditSaveShapefile.Result | undefined> {
    const { fields, fileName, geomType, parentId, uuid } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${uuid}`,
      method: 'put',
      body: {
        fields,
        fileName,
        geomType,
        parentId,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new ApplicationError(httpResponse.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
