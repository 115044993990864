import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { makeDeleteNodeUseCase } from '@/modules/management-tree/factories/make-delete-node-use-case';
import { removeNode, removeNodeCloseDialog } from '@/modules/management-tree/state/actions';
import { makeDeleteProjectUseCase } from '@/modules/project/factories/make-delete-project-use-case';
import { makeLocalStorageAdapter } from '@/shared/factories/make-local-storage-adapter';
import { AlertDialog } from '@/shared/presentation/components/atoms/alert-dialog';

import { TreeViewData } from '../../types/tree-view-item';

const DeleteNodeDialog: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { isOpenRemoveNodeDialog } = useSelector((state: RootState) => state.managementTree);

  const deleteNodeUseCase = useMemo(() => makeDeleteNodeUseCase(), []);
  const localStorageAdapter = useMemo(() => makeLocalStorageAdapter(), []);
  const deleteProjectUseCase = useMemo(() => makeDeleteProjectUseCase(), []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressConfirm = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const currentNode = localStorageAdapter.getItem('currentNode') as TreeViewData;

    try {
      if (currentNode.nodeType === 'project') {
        await deleteProjectUseCase.execute({ projectId: currentNode.id });
      } else {
        await deleteNodeUseCase.execute({ id: currentNode.id });
      }
      dispatch(removeNode(currentNode.id));
      dispatch(removeNodeCloseDialog());
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar apagar esse nó. Tente novamente',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }, [localStorageAdapter, deleteNodeUseCase, toast, dispatch, deleteProjectUseCase]);

  const handlePressCancel = useCallback(() => {
    dispatch(removeNodeCloseDialog());
  }, [dispatch]);

  return (
    <AlertDialog
      isOpen={isOpenRemoveNodeDialog}
      onConfirm={handlePressConfirm}
      onCancel={handlePressCancel}
      cancelButtonText="Não"
      confirmButtonText="Sim"
      header="Deseja realmente remover esse nó?"
      message="Se remover esse nó, todos os outros nós e dados vinculados a ele serão apagados"
      isLoading={isLoading}
    />
  );
};

export { DeleteNodeDialog };
