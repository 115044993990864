import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ILoadMotorcycles } from './contracts/load-motorcycles';

export class LoadMotorcyclesUseCase implements ILoadMotorcycles {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ILoadMotorcycles.Result>) {}

  async execute(): Promise<ILoadMotorcycles.Result | undefined> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.noContent:
        return [];
      default:
        throw new UnexpectedError();
    }
  }
}
