import { ManagementTreeModel } from '../models/management-tree';

export enum ActionTypes {
  UPDATE_NODE = '@management-tree/UPDATE_NODE',
  REMOVE_NODE = '@management-tree/REMOVE_NODE',
  ADD_NODE = '@management-tree/ADD_NODE',

  SET_CURRENT_NODE = '@management-tree/SET_CURRENT_NODE',
  SET_CURRENT_SAMPLING = '@management-tree/SET_CURRENT_SAMPLING',

  ADD_EDIT_NODE_OPEN_MODAL = '@management-tree/ADD_EDIT_NODE_OPEN_MODAL',
  ADD_EDIT_NODE_CLOSE_MODAL = '@management-tree/ADD_EDIT_NODE_CLOSE_MODAL',

  REMOVE_NODE_OPEN_DIALOG = '@management-tree/REMOVE_NODE_OPEN_DIALOG',
  REMOVE_NODE_CLOSE_DIALOG = '@management-tree/REMOVE_NODE_CLOSE_DIALOG',

  SET_CURRENT_PARENT_ID = '@management-tree/SET_CURRENT_PARENT_ID',

  UPLOAD_ZIP_OPEN_MODAL = '@management-tree/UPLOAD_ZIP_OPEN_MODAL',
  UPLOAD_ZIP_CLOSE_MODAL = '@management-tree/UPLOAD_ZIP_CLOSE_MODAL',

  EXPORT_NODE_OPEN_MODAL = '@management-tree/EXPORT_NODE_OPEN_MODAL',
  EXPORT_NODE_CLOSE_MODAL = '@management-tree/EXPORT_NODE_CLOSE_MODAL',
}

export type ManagementTreeState = {
  addedNode: ManagementTreeModel | null;
  removedNode: string | null;
  updatedNode: ManagementTreeModel | null;

  isOpenRemoveNodeDialog: boolean;

  addOrEditNodeModal: {
    open: boolean;
    type: 'edit' | 'create';
  };

  uploadZipIsOpenModal: boolean;
  isOpenExportNodeModal: boolean;
};
