import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ILoadNodes } from './contracts/load-modules';

export class LoadNodesUseCase implements ILoadNodes {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ILoadNodes.Result>) {}

  async execute(params: ILoadNodes.Params): Promise<ILoadNodes.Result | undefined> {
    const { parentId, projectId } = params;

    let url: string;

    if (parentId) {
      url = `${this.url}?parentId=${parentId}`;
    } else {
      url = `${this.url}?projectId=${projectId}`;
    }

    const httpResponse = await this.httpClient.request({
      url,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
