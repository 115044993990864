import { action } from 'typesafe-actions';

import { ProjectModel } from '../models/project';
import { ActionTypes } from './types';

export const setCurrentProject = (project: ProjectModel) => action(ActionTypes.SET_CURRENT_PROJECT, { project });

export const addProjectOpenModal = () => action(ActionTypes.ADD_PROJECT_OPEN_MODAL);
export const addProjectCloseModal = () => action(ActionTypes.ADD_PROJECT_CLOSE_MODAL);

export const assigmentUsersOpenModal = () => action(ActionTypes.ASSIGMENT_USERS_OPEN_MODAL);
export const assigmentUsersCloseModal = () => action(ActionTypes.ASSIGMENT_USERS_CLOSE_MODAL);

export const listTasksOpenModal = () => action(ActionTypes.LIST_TASKS_OPEN_MODAL);
export const listTasksCloseModal = () => action(ActionTypes.LIST_TASKS_CLOSE_MODAL);
