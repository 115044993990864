import React from 'react';

import { Checkbox } from '../atoms/checkbox';
import { GroupContainer } from './group-container';
import { MaxDistanceInput } from './max-distance-input';

type Props = {
  onChange: (key: string, value: any) => void;
};

const SettingTask: React.FC<Props> = ({ onChange }: Props) => {
  return (
    <GroupContainer title="Configurações da tarefa">
      <Checkbox defaultIsChecked label="Solicitar equipamento?" onChange={(e) => onChange('requestEquipment', e)} />
      <Checkbox defaultIsChecked label="Solicitar camionete/caminhão?" onChange={(e) => onChange('requestTruck', e)} />
      <Checkbox
        defaultIsChecked
        label="Solicitar quadriciclo/moto?"
        onChange={(e) => onChange('requestMotorcycle', e)}
      />
      <MaxDistanceInput onChange={(value) => onChange('maxDistance', value)} />
    </GroupContainer>
  );
};

export { SettingTask };
