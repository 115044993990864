import React from 'react';

import { Flex } from '@chakra-ui/react';

import { Spinner } from './spinner';

// import { Container } from './styles';

const CheckAuth: React.FC = () => {
  return (
    <Flex minW="100vw" minH="100vh" alignItems="center" justifyContent="center" backgroundColor="gray.900">
      <Spinner />
    </Flex>
  );
};

export { CheckAuth };
