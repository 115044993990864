import { FieldValidation } from '../contracts/field-validation';
import { RequiredFieldError } from '../errors/required-field';

export class RequiredFieldValidation implements FieldValidation {
  constructor(readonly field: string) {}

  validate(input: any): Error | null {
    return input[this.field] ? null : new RequiredFieldError();
  }
}
