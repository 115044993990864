import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';

import { RootState } from '@/main/config/redux';
import { makeLoadSpacesUseCase } from '@/modules/space/factories/make-load-spaces-use-case';
import { setSpaces } from '@/modules/space/state/actions';
import { ILoadSpaces } from '@/modules/space/use-cases/contracts/load-spaces';
import { Tooltip } from '@/shared/presentation/components/atoms/tooltip';

import { DeleteSpaceDialog } from '../atoms/delete-space-dialog';
import { SpaceButton } from '../atoms/space-button';
import { CreateSpaceModal } from '../molecules/create-space-modal';
import { UpdateSpaceModal } from './update-space-modal';

const SpaceSwitcher: React.FC = () => {
  const dispatch = useDispatch();

  const loadSpacesUseCase = useMemo((): ILoadSpaces => makeLoadSpacesUseCase(), []);

  const { spaces } = useSelector((state: RootState) => state.space);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function loadCustomers() {
      try {
        setIsLoading(true);

        const data = await loadSpacesUseCase.execute();

        if (data) {
          dispatch(setSpaces(data));
        }
      } catch (error) {
        //console.tron.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    loadCustomers();
  }, [dispatch, loadSpacesUseCase]);

  return (
    <>
      <CreateSpaceModal />
      <DeleteSpaceDialog />
      <UpdateSpaceModal />
      <Flex
        flex="1"
        flexDir="column"
        overflowY="auto"
        paddingTop="5"
        paddingBottom="5"
        maxHeight="100%"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#DDD',
            borderRadius: '24px',
          },
        }}
      >
        {!isLoading
          ? spaces.map((space) => (
              <Tooltip key={space.id + space.slug} label={space.name} placement="right">
                <SpaceButton space={space} />
              </Tooltip>
            ))
          : [...Array(5).keys()].map((i) => (
              <Skeleton key={i} height="60px" width="60px" ml="5" mr="5" mb="5" borderRadius="30%" />
            ))}
      </Flex>
    </>
  );
};

export { SpaceSwitcher };
