import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IForgotPassword } from './contracts/forgot-password';

export class ForgotPasswordUseCase implements IForgotPassword {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<IForgotPassword.Result>) {}

  async execute(params: IForgotPassword.Params): Promise<IForgotPassword.Result | undefined> {
    const { identity } = params;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: {
        identity,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.badRequest:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  }
}
