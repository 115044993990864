import React from 'react';
import { MdAdd } from 'react-icons/md';

import { Icon } from '@chakra-ui/react';

import { ButtonSubmit } from '@/shared/presentation/components/atoms/button-submit';

type Props = {
  onClick: () => void;
};

const AddButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <ButtonSubmit mt={0} onClick={onClick}>
      <Icon
        as={MdAdd}
        color="whiteAlpha.900"
        __css={{
          path: {
            stroke: 'var(--chakra-colors-whiteAlpha-900)',
          },
        }}
        w="18px"
        h="18px"
      />
    </ButtonSubmit>
  );
};

export { AddButton };
